import Page from "./page";
import { useTranslation } from "react-i18next";
import Main from "../../assets/software/pos/main.png";
import qrcode from "../../assets/software/qrcodeBlue.png";
import Pic1 from '../../assets/software/pos/1.png';
import Pic2 from '../../assets/software/pos/2.png';
import Pic3 from '../../assets/software/pos/3.png';
import Pic4 from '../../assets/software/pos/4.png';
import Pic5 from '../../assets/software/pos/5.png';
import Pic6 from '../../assets/software/pos/6.png';
import logo1 from '../../assets/software/pos/logo1.png';
import logo2 from '../../assets/software/pos/logo2.png';
import logo3 from '../../assets/software/pos/logo3.png';
import logo4 from '../../assets/software/pos/logo4.png';
import logo5 from '../../assets/software/pos/logo5.png';
import logo6 from '../../assets/software/pos/logo6.png';
function Pos() {
  let { t } = useTranslation();
  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("businessType.posTitle"),
      infos: [
        t("businessType.posInfo1"),
        t("businessType.posInfo2"),
        t("businessType.posInfo3"),
        t("businessType.posInfo4"),
      ],
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Pic2,
      logo: logo2,
      title: t("businessType.payTitle"),
      infos: [
        t("businessType.payInfo1"),
        t("businessType.payInfo2"),
        t("businessType.payInfo3")
      ],
    },
    {
      pic: Pic3,
      logo: logo3,
      title: t("businessType.stockTitle"),
      infos: [
        t("businessType.stockInfo1"),
        t("businessType.stockInfo2"),
        t("businessType.stockInfo3"),
        t("businessType.stockInfo4"),
      ],
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Pic4,
      logo: logo4,
      title: t("businessType.saleTitle"),
      infos: [
        t("businessType.saleInfo1"),
        t("businessType.saleInfo2"),
        t("businessType.saleInfo3"),
        t("businessType.saleInfo4"),
      ],
    },
    {
      pic: Pic5,
      logo: logo5,
      title: t("businessType.staffTitle"),
      infos: [
        t("businessType.staffInfo1"),
        t("businessType.staffInfo2"),
        t("businessType.staffInfo3"),
        t("businessType.staffInfo4"),
      ],
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Pic6,
      logo: logo6,
      title: t("businessType.crmTitle"),
      infos: [
        t("businessType.crmInfo1"),
        t("businessType.crmInfo2"),
        t("businessType.crmInfo3"),
        t("businessType.crmInfo4"),
      ],
    },
  ]
  return (
    <Page
      {...{
        title: t("businessType.title1"),
        content: t("businessType.info1"),
        pic: Main,
        bgColor: "rgba(211, 242, 226, 1)",
        linearGradient: 'linear-gradient(156.74deg, rgba(166, 227, 188, 0.4) 0%, rgba(197, 230, 205, 0.01) 100%)',
        downInfo: {
          bgColor: "#31b077",
          qrBgColor: '#e3faec',
          qrcode: qrcode,
          urlName: 'toposify_pos'
        },
        bodyDatas: datas,
        triangleBg: 'linear-gradient(161.37deg, rgba(166, 227, 188, 1) 0%, rgba(211, 242, 226, 0) 100%)'
      }}
    />
  );
}

export default Pos;
