import { createSlice } from '@reduxjs/toolkit'

export const featureSlice = createSlice({
  name: 'feature',
  initialState: {
    value: {
      index: localStorage.getItem('feartureIndex') || 0
    },
  },
  reducers: {
    changeIndex: (state, action) => {
      const { index } = action.payload
      state.value = {
        index: index
      }
    },
  },
})

export const { changeIndex } = featureSlice.actions

export default featureSlice.reducer