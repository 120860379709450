import Overview from "./Overview";
import Body from "../../components/contentBody";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import Pic1 from "../../assets/partners/1.png";
import Pic2 from "../../assets/partners/2.png";
import logo1 from "../../assets/partners/logo1.png";
import logo2 from "../../assets/partners/logo2.png";

import DownFooterWeb from "../../components/down";
import qrcode from "../../assets/software/qrcodeBlue.png";

const TextInfo = styled(Box)(() => ({
  fontWeight: 700,
  color: "rgba(57, 175, 115, 1)"
}));

function Page(props) {
  let { t } = useTranslation();
  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("partners.title1"),
      infos: [
        t("partners.group111"),
        t("partners.group121"),
        t("partners.group131"),
      ],
      bgcolor: "rgb(245,244,245)",
    },
    {
      pic: Pic2,
      logo: logo2,
      title: t("partners.title2"),
      infos: [
        t("partners.group211"),
        t("partners.group221"),
        t("partners.group231"),
      ],
    },
  ];
  const downInfo = {
    bgColor: "#31b077",
    qrBgColor: "#e3faec",
    qrcode: qrcode,
    urlName: "toposify_oprate",
    showMac: false,
  };
  return (
    <>
      <Overview />
      <Box
        sx={{
          margin: {xs: '8px 0', md: '60px 0'},
          display: "flex",
          justifyContent: "center",
          flexDirection: 'column',
          alignItems: "center",
          width: '100%'
        }}
      >
        <TextInfo sx={{ fontSize: {xs: 18, md: 48}, paddingBottom: {xs: 0, md: 12} }}>{t("partners.info3")}</TextInfo>
        <Box
          sx={{
            margin: {
              xs: "-36px auto",
              md: "60px 0"
            },
            display: "flex",
            justifyContent: "center",
            backgroundColor: {
              xs: props.bgColor,
              md: "unset"
            },
          }}
        >
          <DownFooterWeb {...downInfo} />
        </Box>
      </Box>
      <Body {...{ bodyDatas: datas }} />
    </>
  );
}
export default Page;
