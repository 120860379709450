import { Box } from "@mui/material";
import HeaderAppBar from "./headerAppBar";
import HeaderMenu from './headerMenu';
import { styled } from "@mui/material/styles";
const BoxContainer = styled(Box)(({theme}) => ({
  
}));
function Hearder() {
  return (
    <BoxContainer component="section">
      <section>
        <HeaderAppBar />
        <HeaderMenu />
      </section>
    </BoxContainer>
  );
}
export default Hearder;
