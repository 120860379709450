import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import MainLogo from "../assets/logo/main-logo.png";
import { styled } from "@mui/material/styles";
import { Box, Link, Drawer, Divider } from "@mui/material";
import HeaderMenuMini from "./headerMenuMini";
import HeaderAppBarMini from "./headerAppBarMini";

function HearderMini() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, left: open });
  };

  const skin = useSelector((state) => state.skin.value);

  const MList = function () {
    return (
      <Box sx={{ width: "60vw", bgcolor: skin.menuBg, height: "100%" }}>
        <Link
          href="#/"
          underline="none"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px auto 8px auto",
          }}
        >
          <img
            src={MainLogo}
            alt="Toposfy"
            style={{ width: 148, height: 48 }}
          />
        </Link>
        <HeaderAppBarMini />
        <Divider />
        <HeaderMenuMini />
      </Box>
    );
  };
  const anchor = "left";
  const BoxContainer = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    [theme.breakpoints.down("mg")]: {
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.up("mg")]: {
      display: "none",
    },
  }));

  return (
    <BoxContainer>
      <React.Fragment>
        <MenuIcon
          onClick={toggleDrawer(true)}
          sx={{ color: skin.color, marginRight: 3 }}
        />
        <Drawer
          anchor="left"
          open={state[anchor]}
          onClose={toggleDrawer(false)}
        >
          {MList(anchor)}
        </Drawer>
      </React.Fragment>
    </BoxContainer>
  );
}
export default HearderMini;
