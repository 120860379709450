import Page from "./page";
import { useTranslation } from "react-i18next";
import qrcode from '../../assets/software/qrcodeOrange.png';
import Pic1 from '../../assets/software/customer/1.png';
import Pic2 from '../../assets/software/customer/2.png';
import logo1 from '../../assets/software/customer/logo1.png';
import logo2 from '../../assets/software/customer/logo2.png';
import main from '../../assets/software/customer/main.png';
function Selfhelp() {
  let { t } = useTranslation();

  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("customerType.reportTitle"),
      infos: [
        t("customerType.reportInfo1")
      ],
      bgcolor: "rgb(255,248,240)",
    },
    {
      pic: Pic2,
      logo: logo2,
      title: t("customerType.goodsTitle"),
      infos: [
        t("customerType.goodsInfo1")
      ],
    }
  ]
  return (
    <Page
      {...{
        title: t("customerType.title"),
        content: t("customerType.info"),
        pic: main,
        bgColor: "rgba(255, 142, 43, 1)",
        linearGradient: 'linear-gradient(161.37deg, rgba(255, 106, 0, 1) 0%, rgba(255, 153, 64, 1) 100%)',
        downInfo: {
          bgColor: "#ff8122",
          qrBgColor: '#ffebd1',
          qrcode: qrcode,
          showMac: false,
          urlName: 'toposify_cds'
        },
        bodyDatas: datas,
        textColor: '#fff'
      }}
    />
  );
}

export default Selfhelp;

