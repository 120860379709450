import { createSlice } from '@reduxjs/toolkit'

export const handwareSlice = createSlice({
  name: 'handware',
  initialState: {
    value: {
      index: localStorage.getItem('handwareIndex') || 0
    },
  },
  reducers: {
    changeIndex: (state, action) => {
      const { index } = action.payload
      state.value = {
        index: index
      }
    },
  },
})

export const { changeIndex } = handwareSlice.actions

export default handwareSlice.reducer