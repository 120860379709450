import Overview from "./Overview";
import Body from "../../components/contentBody";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Title1 = styled(Box)(() => ({
  fontSize: 48,
  fontWeight: 700,
  color: "rgb(49, 55, 61)",
}));

const Title2 = styled(Box)(() => ({
  fontSize: 48,
  fontWeight: 700,
  color: "rgb(57, 175, 115)",
  paddingLeft: 16
}));

const TextInfo = styled(Box)(() => ({
  fontSize: 36,
  fontWeight: 700,
  color: "rgb(49, 55, 61)",
  paddingTop: 32,
}));

function Page(props) {
  return (
    <>
      <Overview {...props} />
      <Box
        sx={{
          height: { xs: "unset", md: 276 },
          bgcolor: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: 'column'
        }}
      >
        <Box sx={{display: { xs: "none", md: "flex" }}}>
          <Title1>{props.subtitle1}</Title1>
          <Title2>{props.subtitle2}</Title2>
        </Box>
        <Box sx={{display: { xs: "flex", md: "none" }, padding: 3}}>
          <Title1 sx={{fontSize: 18}}>{props.subtitle1}</Title1>
          <Title2 sx={{fontSize: 18}}>{props.subtitle2}</Title2>
        </Box>
        <TextInfo sx={{fontSize: 18, padding: '0 24px 24px 24px'}}>{props.subtitle3}</TextInfo>
      </Box>
      <Body {...{ bodyDatas: props.bodyDatas }} />
    </>
  );
}
export default Page;
