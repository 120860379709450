/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";

const LogoImg = styled("img")(() => ({
  width: 48,
  height: 48,
}));

export const ContainerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "92px 0",
  minheight: 500,
  [theme.breakpoints.down("lg")]: {
    justifyContent: "space-around",
  },
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}));

export const CardBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    width: 634,
  },
  [theme.breakpoints.down("xl")]: {
    width: "40%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "48%",
  },
  [theme.breakpoints.down("md")]: {
    width: "48%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60%",
    height: "60%",
    marginTop: 16,
  },
}));

export const SpaceBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    display: "block",
  },
  [theme.breakpoints.down("xl")]: {
    display: "block",
  },
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

function Selections({ id, pic, logo, title, infos, bgcolor }) {
  const InfoBox = {
    fontSize: 18,
    fontWeight: 400,
    mt: 2,
    color: "rgb(49, 55, 61)",
    display: "flex",
  };
  const TitleBox = {
    fontSize: 36,
    fontWeight: 700,
    mt: 2.5,
  };
  const CircleCss = { position: "relative", top: 7, fontSize: 12, mr: 1 };
  return (
    <>
      <ContainerBox
        id={id}
        sx={{
          bgcolor: bgcolor,
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
        }}
      >
        <CardBox>
          <img
            src={pic}
            alt=""
            style={{ width: "100%", height: 422, objectFit: "contain" }}
          />
        </CardBox>
        <SpaceBox sx={{ width: 40 }} />
        <CardBox sx={{ display: "flex", flexDirection: "column" }}>
          <LogoImg src={logo} />
          <Box sx={TitleBox}>{title}</Box>
          {infos.map((v) => {
            return (
              <Box sx={InfoBox} key={v}>
                <CircleIcon sx={CircleCss} />
                {v}
              </Box>
            );
          })}
        </CardBox>
      </ContainerBox>
      <ContainerBox
        id={id}
        sx={{
          bgcolor: bgcolor,
          display: { xs: "flex", md: "none" },
          justifyContent: "center",
          padding: 3,
        }}
      >
        <img src={pic} alt="" style={{ width: "100%" }} />
        <SpaceBox sx={{ width: 40 }} />
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box
            sx={{
              fontSize: 18,
              fontWeight: 700,
              mt: 2.5,
              width: "100%",
              textAlign: "center",
            }}
          >
            {title}
          </Box>
          {infos.map((v) => {
            return (
              <Box sx={{...InfoBox, fontSize: 14}} key={v}>
                {v}
              </Box>
            );
          })}
        </Box>
      </ContainerBox>
    </>
  );
}

function Body({ bodyDatas }) {
  return (
    <>
      {bodyDatas.map((v, index) => {
        return (
          <Selections
            {...{
              pic: v.pic,
              logo: v.logo,
              title: v.title,
              infos: v.infos,
              bgcolor: v.bgcolor,
              id: "item" + index,
            }}
            key={index}
          />
        );
      })}
    </>
  );
}

export default Body;
