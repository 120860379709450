import Page from "./page";
import { useTranslation } from "react-i18next";
import admin from "../../assets/software/admin.png";
import qrcode from '../../assets/software/qrcodePerpluse.png';

import Pic1 from '../../assets/software/admin/1.png';
import Pic2 from '../../assets/software/admin/2.png';
import Pic3 from '../../assets/software/admin/3.png';
import Pic4 from '../../assets/software/admin/4.png';
import Pic5 from '../../assets/software/admin/5.png';
import Pic6 from '../../assets/software/admin/6.png';
import logo1 from '../../assets/software/admin/logo1.png';
import logo2 from '../../assets/software/admin/logo2.png';
import logo3 from '../../assets/software/admin/logo3.png';
import logo4 from '../../assets/software/admin/logo4.png';
import logo5 from '../../assets/software/admin/logo5.png';
import logo6 from '../../assets/software/admin/logo6.png';

function Admin() {
  let { t } = useTranslation();

  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("adminType.reportTitle"),
      infos: [
        t("adminType.reportInfo1"),
        t("adminType.reportInfo2"),
        t("adminType.reportInfo3"),
        t("adminType.reportInfo4"),
      ],
      bgcolor: "#f7f9ff",
    },
    {
      pic: Pic2,
      logo: logo2,
      title: t("adminType.goodsTitle"),
      infos: [
        t("adminType.goodsInfo1"),
        t("adminType.goodsInfo2"),
        t("adminType.goodsInfo3")
      ],
    },
    {
      pic: Pic3,
      logo: logo3,
      title: t("adminType.stockTitle"),
      infos: [
        t("adminType.stockInfo1"),
        t("adminType.stockInfo2"),
        t("adminType.stockInfo3"),
        t("adminType.stockInfo4"),
      ],
      bgcolor: "#f7f9ff",
    },
    {
      pic: Pic4,
      logo: logo4,
      title: t("adminType.staffTitle"),
      infos: [
        t("adminType.staffInfo1"),
        t("adminType.staffInfo2"),
        t("adminType.staffInfo3")
      ],
    },
    {
      pic: Pic5,
      logo: logo5,
      title: t("adminType.vipTitle"),
      infos: [
        t("adminType.vipInfo1"),
        t("adminType.vipInfo2"),
        t("adminType.vipInfo3"),
        t("adminType.vipInfo4"),
      ],
      bgcolor: "#f7f9ff",
    },
    {
      pic: Pic6,
      logo: logo6,
      title: t("adminType.settingsTitle"),
      infos: [
        t("adminType.settingsInfo1"),
        t("adminType.settingsInfo2"),
        t("adminType.settingsInfo3"),
        t("adminType.settingsInfo4"),
      ],
    },
  ]

  return (
    <Page
      {...{
        title: t("adminType.title"),
        content: t("adminType.info"),
        pic: admin,
        bgColor: "rgba(211, 218, 242, 1)",
        linearGradient: 'linear-gradient(156.74deg, rgba(174, 166, 227, 0.4) 0%, rgba(208, 197, 230, 0.01) 100%)',
        downInfo: {
          bgColor: "#5d6bcf",
          qrBgColor: '#e3e5fa',
          qrcode: qrcode,
          urlName: ''
        },
        bodyDatas: datas,
        triangleBg: 'linear-gradient(161.37deg, rgba(166, 227, 188, 1) 0%, rgba(211, 242, 226, 0) 100%)',
        hideDown: true
      }}
    />
  );
}

export default Admin;