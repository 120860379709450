import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import React from "react";
import country from "../../locales/cn";
import { useSelector } from "react-redux";
import axios from "axios";

const Textarea = styled('textarea')(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default function SubForm() {
  const [name, setName] = React.useState("");
  const [sysZoneId, setSysZoneId] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [sysCountryId, setSysCountryId] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [remark, setRemark] = React.useState("");
  let { t } = useTranslation();
  const [softwares, setSoftwares] = React.useState([
    { id: 1, name: t("contact.software1") },
    { id: 2, name: t("contact.software2") },
    { id: 3, name: t("contact.software3") },
    { id: 4, name: t("contact.software4") },
    { id: 5, name: t("contact.software5") },
    { id: 6, name: t("contact.software6") },
  ]);
  const [hardwares, setHardwares] = React.useState([
    { id: 20, name: t("contact.hardware20") },
    { id: 21, name: t("contact.hardware21") },
    { id: 22, name: t("contact.hardware22") },
    { id: 23, name: t("contact.hardware23") },
    { id: 24, name: t("contact.hardware24") },
    { id: 25, name: t("contact.hardware25") },
    { id: 26, name: t("contact.hardware26") },
    { id: 27, name: t("contact.hardware27") },
  ]);
  const lang = useSelector((state) => state.lang.value);
  const countrys = country[lang];

  const [showInfo, setShowInfo] = React.useState(false);

  const disabled =
    !Boolean(name) ||
    !Boolean(email) ||
    !Boolean(sysCountryId) ||
    !Boolean(sysZoneId) ||
    !Boolean(phone);

  const submitForm = () => {
    if (disabled) {
      return;
    }

    const data = {
      email: email,
      memo: remark,
      name: name,
      sysCountryId: sysCountryId,
      sysZoneId: sysZoneId,
      telephone: phone,
      type: [
        ...softwares.filter(v=>v.checked === true).map(v=>v.id), 
        ...hardwares.filter(v=>v.checked === true).map(v=>v.id)
      ].join(','),
    };

    axios
      .post("https://admin.toposfy.com/admin/consultation/addConsultation", data)
      .then(function (response) {
        const { status, data } = response;
        if (status === 200 && data.code === 200) {
          setShowInfo(true);
          setTimeout(() => {
            window.location.reload()
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeHard = (e, item)=> {
    for (let hard of hardwares) {
      if (hard === item) {
        hard.checked = e.target.checked
      }
    }
    setHardwares(hardwares)
  }
  const changeSoft = (e, item)=> {
    for (let hard of softwares) {
      if (hard === item) {
        hard.checked = e.target.checked
      }
    }
    setSoftwares(softwares)
  }

  return (
    <Grid container spacing={2}>
      <Snackbar
        open={showInfo}
        autoHideDuration={6000}
        message={t("contact.successInfo")}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <Grid item xs={12}>
        {t("contact.subinfo")}
      </Grid>
      <Grid item xs={12}>
        {t("contact.software")}
      </Grid>
      <Grid item xs={12}>
        <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
          {softwares.map((v) => {
            return (
              <FormControlLabel
                control={<Checkbox value={v.value} onChange={(e)=> changeSoft(e, v)} />}
                label={v.name}
                key={v.id}
              />
            );
          })}
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        {t("contact.hardware")}
      </Grid>
      <Grid item xs={12}>
        <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
          {hardwares.map((v) => {
            return (
              <FormControlLabel
                control={<Checkbox value={v.value} onChange={(e)=> changeHard(e, v)} />}
                label={v.name}
                key={v.id}
              />
            );
          })}
        </FormGroup>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label={t("contact.name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl style={{ width: "38%" }}>
          <InputLabel id="area-label">{t("contact.area")}</InputLabel>
          <Select
            labelId="area-label"
            id="area-select"
            label={t("contact.area")}
            value={sysZoneId}
            onChange={(e) => setSysZoneId(e.target.value)}
          >
            {countrys.map((v) => {
              return (
                <MenuItem value={v.id} key={v.id}>
                  {v.country_name}&nbsp;{v.phone_code}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          style={{ width: "62%" }}
          label={t("contact.phone")}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          type="tel"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label={t("contact.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="age-label">{t("contact.country")}</InputLabel>
          <Select
            labelId="age-label"
            id="age-select"
            value={sysCountryId}
            label={t("contact.country")}
            onChange={(e) => setSysCountryId(e.target.value)}
          >
            {countrys.map((v) => {
              return (
                <MenuItem value={v.id} key={v.id}>
                  {v.country_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Textarea
          style={{ width: "100%", resize: "none" }}
          minRows={5}
          placeholder={t("contact.note")}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
        {disabled ? (
          <Button color="success" variant="contained" disabled={disabled}>
            {t("contact.btn")}
          </Button>
        ) : (
          <Button
            color="success"
            variant="contained"
            style={{ backgroundColor: "rgb(23,144,82)" }}
            onClick={submitForm}
          >
            {t("contact.btn")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
