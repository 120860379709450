import Page from "./page";
import { useTranslation } from "react-i18next";
import qrcode from '../../assets/software/qrcodeOrange.png';
import Pic1 from '../../assets/software/shop/1.png';
import Pic2 from '../../assets/software/shop/2.png';
import logo1 from '../../assets/software/shop/logo1.png';
import logo2 from '../../assets/software/shop/logo2.png';
import shopPic from '../../assets/software/shop/shop.png';
function Shop() {
  let { t } = useTranslation();

  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("shopType.reportTitle"),
      infos: [
        t("shopType.reportInfo1"),
        t("shopType.reportInfo2"),
        t("shopType.reportInfo3"),
        t("shopType.reportInfo4"),
      ],
      bgcolor: "rgb(255,248,240)",
    },
    {
      pic: Pic2,
      logo: logo2,
      title: t("shopType.goodsTitle"),
      infos: [
        t("shopType.goodsInfo1"),
        t("shopType.goodsInfo2"),
        t("shopType.goodsInfo3"),
      ],
    }
  ]
  return (
    <Page
      {...{
        title: t("shopType.title"),
        content: t("shopType.info"),
        pic: shopPic,
        bgColor: "rgba(255, 142, 43, 1)",
        linearGradient: 'linear-gradient(161.37deg, rgba(255, 106, 0, 1) 0%, rgba(255, 153, 64, 1) 100%)',
        downInfo: {
          bgColor: "#ff8122",
          qrBgColor: '#ffebd1',
          qrcode: qrcode,
          showMac: false,
          showWin: false,
          urlName: 'toposify_marchand'
        },
        bodyDatas: datas,
        textColor: '#fff'
      }}
    />
  );
}

export default Shop;

