import Page from "../page";
import { useTranslation } from "react-i18next";
import pos from "../../../assets/sence/minimart/bg.jpg";
import mainPic from '../../../assets/sence/minimart/main.png';
import Pic1 from '../../../assets/sence/minimart/1.png';
import Pic2 from '../../../assets/sence/minimart/2.png';
import logo from '../../../assets/sence/logo.png';
function Minimart() {
  let { t } = useTranslation();
  const datas = [
    {
      pic: Pic1,
      logo: logo,
      title: t("minimartShop.title1"),
      infos: [
        t("minimartShop.info1"),
      ],
      bgcolor: "rgb(245,244,245)",
    },
    {
      pic: Pic2,
      logo: logo,
      title: t("minimartShop.title2"),
      infos: [
        t("minimartShop.info2"),
      ],
    }
  ]
  return (
    <Page
      {...{
        title: t("minimartShop.title"),
        content: t("minimartShop.info"),
        btnText: t("minimartShop.button"),
        subtitle1: t("minimartShop.subtitle1"),
        subtitle2: t("minimartShop.subtitle2"),
        subtitle3: t("minimartShop.subtitle3"),
        pic: pos,
        bodyDatas: datas,
        mainPic: mainPic
      }}
    />
  );
}
export default Minimart;
