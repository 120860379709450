// import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import zhCnTrans from "../../locales/zh_CN.json";
import enGbTrans from "../../locales/en_GB.json";
import esESTrans from "../../locales/es_ES.json";
import frFRTrans from "../../locales/fr_FR.json";
import ptPTTrans from "../../locales/pt_PT.json";
import zhTWTrans from "../../locales/zh_TW.json";
import { initReactI18next } from "react-i18next";
import {langDefault} from '../../utils/language';


i18n
  // .use(LanguageDetector) //嗅探当前浏览器语言
  .use(initReactI18next) //init i18next
  .init({
    //引入资源文件
    resources: {
      zh_CN: {
        translation: zhCnTrans,
      },
      en_GB: {
        translation: enGbTrans,
      },
      es_ES: {
        translation: esESTrans,
      },
      fr_FR: {
        translation: frFRTrans,
      },
      pt_PT: {
        translation: ptPTTrans,
      },
      zh_TW: {
        translation: zhTWTrans,
      },
    },
    //选择默认语言，选择内容为上述配置中的key，即en/zh
    fallbackLng: langDefault,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
