import { styled } from "@mui/material/styles";
import React from "react";
import bg from "../../assets/partners/bg.jpg";

const ContainerTop = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(244, 244, 245, 1)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  height: "auto"
}));

function Overview() {
  return (
    <ContainerTop>
      <img src={bg} alt="toposfy Device" style={{maxWidth: '100%'}} />
    </ContainerTop>
  );
}
export default Overview;