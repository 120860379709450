let arr = [
  {
    sysLanguageId: 7,
    sysLanguageCode: "en_GB",
    sysLanguageName: "English",
  },
  {
    sysLanguageId: 9,
    sysLanguageCode: "es_ES",
    sysLanguageName: "Español",
  },
  {
    sysLanguageId: 14,
    sysLanguageCode: "fr_FR",
    sysLanguageName: "Français",
  },
  {
    sysLanguageId: 24,
    sysLanguageCode: "pt_PT",
    sysLanguageName: "Português",
  },
  {
    sysLanguageId: 34,
    sysLanguageCode: "zh_CN",
    sysLanguageName: "简体中文",
  },
  {
    sysLanguageId: 35,
    sysLanguageCode: "zh_TW",
    sysLanguageName: "繁体中文",
  },
];
export default arr;

let lang_default = localStorage.getItem("lang");
const sysLang = navigator.language;
const lang = sysLang.substring(0, 5);
if (!lang_default) {
  switch (lang) {
    case "es":
      lang_default = "es_ES";
      break;
    case "fr":
      lang_default = "fr_FR";
      break;
    case "pt":
      lang_default = "pt_PT";
      break;
    default:
      if (sysLang === "zh-CN") {
        lang_default = "zh_CN";
      } else if (sysLang === "zh-TW") {
        lang_default = "zh_TW";
      } else {
        lang_default = "en_GB";
      }
      break;
  }
}

export const langDefault = lang_default