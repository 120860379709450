import { createSlice } from '@reduxjs/toolkit'

export const skinSlice = createSlice({
  name: 'skin',
  initialState: {
    value: {
      light: true,
      barBg: '#eee',
      menuBg: '#fff',
      color: '#000'
    },
  },
  reducers: {
    changeSkin: (state, action) => {
      const { light, barBg, menuBg, color } = action.payload
      state.value = {
        light: light,
        barBg: barBg,
        menuBg: menuBg,
        color: color
      }
    },
  },
})

export const { changeSkin } = skinSlice.actions

export default skinSlice.reducer