import { styled } from "@mui/material/styles";
import React from "react";

const ContainerTop = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(244, 244, 245, 1)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  height: "auto"
}));

function Overview(props) {
  let { pic } = props;
  return (
    <ContainerTop>
      <img src={pic} alt="toposfy Device" style={{maxWidth: '100%'}} />
    </ContainerTop>
  );
}
export default Overview;
