import Page from "./page";
import { useTranslation } from "react-i18next";
import Main from "../../assets/software/diningCar/main.png";
import qrcode from "../../assets/software/qrcodeBlue.png";
import Pic1 from '../../assets/software/diningCar/1.png';
import Pic2 from '../../assets/software/diningCar/2.png';
import logo1 from '../../assets/software/diningCar/logo1.png';
import logo2 from '../../assets/software/diningCar/logo2.png';
function Pos() {
  let { t } = useTranslation();
  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("diningCarType.posTitle"),
      infos: [
        t("diningCarType.posInfo1"),
        t("diningCarType.posInfo2"),
        t("diningCarType.posInfo3")
      ],
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Pic2,
      logo: logo2,
      title: t("diningCarType.payTitle"),
      infos: [
        t("diningCarType.payInfo1"),
        t("diningCarType.payInfo2"),
        t("diningCarType.payInfo3")
      ],
    },
  ]
  return (
    <Page
      {...{
        title: t("diningCarType.title1"),
        content: t("diningCarType.info1"),
        pic: Main,
        bgColor: "rgba(211, 242, 226, 1)",
        linearGradient: 'linear-gradient(156.74deg, rgba(166, 227, 188, 0.4) 0%, rgba(197, 230, 205, 0.01) 100%)',
        downInfo: {
          bgColor: "#31b077",
          qrBgColor: '#e3faec',
          qrcode: qrcode,
          urlName: 'toposify_pos'
        },
        bodyDatas: datas,
        triangleBg: 'linear-gradient(161.37deg, rgba(166, 227, 188, 1) 0%, rgba(211, 242, 226, 0) 100%)'
      }}
    />
  );
}

export default Pos;
