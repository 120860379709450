import { createSlice } from '@reduxjs/toolkit'

import arr, {langDefault} from '../utils/language';
let langI = 0
for (let index in arr) {
  if (arr[index].sysLanguageCode === langDefault) {
    langI = index
    break
  }
}

console.log(langDefault);

export const langIndexSlice = createSlice({
  name: 'langIndex',
  initialState: {
    value: localStorage.getItem('langIndex') || langI,
  },
  reducers: {
    changeLangIndex: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { changeLangIndex } = langIndexSlice.actions

export const getLangIndex = (state) => {
  return state.langIndex.value
}

export default langIndexSlice.reducer
