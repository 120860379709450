import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box, Card } from "@mui/material";
import whiteImg from "../../assets/order/white.png";
import blackImg from "../../assets/order/black.png";
import check_green from "../../assets/order/check_green.png";
import check_orange from "../../assets/order/check_orange.png";
import FreeImg from "../../assets/price/free.png";
import PublicImg from "../../assets/price/public.png";

function Top() {
  const ContainerTop = styled("div")(({ theme }) => ({
    backgroundColor: "rgba(211, 242, 226, 1)",
    height: 64,
  }));
  return (
    <>
      <ContainerTop></ContainerTop>
    </>
  );
}

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "0 20px",
  display: "flex",
  paddingTop: 60,
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
}));

function Web() {
  const { t } = useTranslation();
  const datas = [
    { name: t("order.title1"), free: true, public: true },
    { name: t("order.title2"), free: true, public: true },
    { name: t("order.title3"), free: true, public: true },
    { name: t("order.title4"), free: true, public: true },
    { name: t("order.title5"), free: true, public: true },
    { name: t("order.title6"), free: true, public: true },
    { name: t("order.title7"), free: true, public: true },
    { name: t("order.title8"), free: true, public: true },
    { name: t("order.title9"), free: false, public: true },
    { name: t("order.title10"), free: false, public: true },
    { name: t("order.title11"), free: false, public: true },
    { name: t("order.title12"), free: false, public: true },
    { name: t("order.title13"), free: false, public: true },
    { name: t("order.title14"), free: false, public: true },
  ];
  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          minWidth: 1200,
          display: {
            xs: "none",
            md: "flex",
          },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Top />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            maxWidth: 1280,
          }}
        >
          <BoxContainer style={{ position: "relative", bottom: -18 }}>
            <Box sx={{ width: 12 }}></Box>
            <Box sx={{ width: 395, ml: 3 }}></Box>
            <Box
              sx={{
                width: 296,
                height: 349,
                backgroundImage: `url(${whiteImg})`,
              }}
            >
              <Box
                sx={{
                  margin: "20px 38px 32px 38px",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "rgba(49, 55, 61, 1)",
                }}
              >
                {t("order.free")}
              </Box>
              <Box
                sx={{
                  marginBottom: "32px",
                  fontWeight: 700,
                  color: "rgba(49, 55, 61, 1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ fontSize: 36, mr: 1 }}>$0</Box>
                <Box sx={{ fontSize: 30 }}>{t("order.unit")}</Box>
              </Box>
              <Box
                sx={{
                  margin: "0 8px 32px 8px",
                  fontWeight: 700,
                  fontSize: 20,
                  color: "rgba(49, 55, 61, 1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("order.info1")}
              </Box>
            </Box>
            <Box sx={{ width: 80 }}></Box>
            <Box
              sx={{
                width: 296,
                height: 349,
                backgroundImage: `url(${blackImg})`,
              }}
            >
              <Box
                sx={{
                  margin: "20px 38px 32px 38px",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "rgba(235, 209, 172, 1)",
                }}
              >
                {t("order.public")}
              </Box>
              <Box
                sx={{
                  marginBottom: "32px",
                  fontWeight: 700,
                  color: "rgba(235, 209, 172, 1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ fontSize: 36, mr: 1 }}>$25</Box>
                <Box sx={{ fontSize: 30 }}>{t("order.unit")}</Box>
              </Box>
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: 20,
                  color: "rgba(235, 209, 172, 1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 8px 32px 8px",
                }}
              >
                {t("order.info2")}
              </Box>
            </Box>
          </BoxContainer>
          <BoxContainer style={{ flexWrap: "wrap", paddingTop: 0 }}>
            <Box
              sx={{
                width: "auto",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                height: 54,
              }}
            >
              <Box
                sx={{
                  width: 12,
                  height: "100%",
                }}
              ></Box>
              <Box sx={{ width: 395, ml: 3 }}></Box>
              <Box
                sx={{
                  width: 296,
                  background: "rgba(247, 247, 247, 1)",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px 8px 0 0",
                }}
              ></Box>
              <Box sx={{ width: 80 }}></Box>
              <Box
                sx={{
                  width: 296,
                  background: "rgba(247, 247, 247, 1)",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px 8px 0 0",
                }}
              ></Box>
            </Box>
          </BoxContainer>
          <BoxContainer style={{ flexWrap: "wrap", paddingTop: 0 }}>
            {datas.map((v) => {
              return (
                <Box
                  sx={{
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    height: 54,
                    borderBottom: "1px solid rgba(217, 217, 217, 1)",
                    "&:first-of-type": {
                      borderTop: "1px solid rgba(217, 217, 217, 1)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 12,
                      height: "100%",
                      backgroundColor: "rgba(211, 242, 226, 1)",
                    }}
                  ></Box>
                  <Box sx={{ width: 395, ml: 3 }}>{v.name}</Box>
                  <Box
                    sx={{
                      width: 296,
                      background: "rgba(247, 247, 247, 1)",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {v.free ? (
                      <img
                        src={check_green}
                        alt="Toposfy"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : null}
                  </Box>
                  <Box sx={{ width: 80 }}></Box>
                  <Box
                    sx={{
                      width: 296,
                      background: "rgba(247, 247, 247, 1)",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {v.public ? (
                      <img
                        src={check_orange}
                        alt="Toposfy"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : null}
                  </Box>
                </Box>
              );
            })}
          </BoxContainer>
          <BoxContainer
            style={{ flexWrap: "wrap", paddingTop: 0, marginBottom: 260 }}
          >
            <Box
              sx={{
                width: "auto",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                height: 54,
              }}
            >
              <Box
                sx={{
                  width: 12,
                  height: "100%",
                }}
              ></Box>
              <Box sx={{ width: 395, ml: 3 }}></Box>
              <Box
                sx={{
                  width: 296,
                  background: "rgba(247, 247, 247, 1)",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0 0 8px 8px",
                }}
              ></Box>
              <Box sx={{ width: 80 }}></Box>
              <Box
                sx={{
                  width: 296,
                  background: "rgba(247, 247, 247, 1)",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0 0 8px 8px",
                }}
              ></Box>
            </Box>
          </BoxContainer>
        </Box>
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "none" }, flexDirection: "column" }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: 80,
            alignItems: "center",
            justifyContent: "center",
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          {t("order.text")}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            marginBottom: 6
          }}
        >
          <BoxContainer sx={{ paddingTop: 0 }}>
            <Box sx={{ width: "45%", pl: 3 }}></Box>
            <Box
              sx={{
                width: "45%",
                background: "rgba(247, 247, 247, 1)",
              }}
            >
              <Card
                sx={{
                  marginBottom: 2,
                  boxShadow: "0.42px 3.39px 2.54px  rgba(0, 0, 0, 0.15)",
                  background: "rgba(247, 247, 247, 1)",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    color: "rgba(49, 55, 61, 1)",
                    padding: 1.5,
                  }}
                >
                  {t("order.free")}
                </Box>
                <Box
                  sx={{
                    marginBottom: 1,
                    fontWeight: 700,
                    color: "rgba(49, 55, 61, 1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ fontSize: 16, mr: 1 }}>$0</Box>
                  <Box sx={{ fontSize: 16 }}>{t("order.unit")}</Box>
                </Box>
                <Box
                  sx={{
                    margin: "0 8px 48px 8px",
                    fontWeight: 700,
                    fontSize: 12,
                    color: "rgba(49, 55, 61, 1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {t("order.info1")}
                </Box>
                <img
                  src={FreeImg}
                  alt=""
                  style={{
                    position: "absolute",
                    right: "-14px",
                    bottom: "-20px",
                  }}
                />
              </Card>
            </Box>
            <Box sx={{ width: "10%" }}></Box>
          </BoxContainer>
          <BoxContainer style={{ flexWrap: "wrap", paddingTop: 0 }}>
            {datas.map((v) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    height: 54,
                    borderBottom: "1px solid rgba(217, 217, 217, 1)",
                    "&:first-of-type": {
                      borderTop: "1px solid rgba(217, 217, 217, 1)",
                    },
                  }}
                >
                  <Box sx={{ width: "45%", pl: 3 }}>{v.name}</Box>
                  <Box
                    sx={{
                      width: "45%",
                      background: "rgba(247, 247, 247, 1)",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {v.free ? (
                      <img
                        src={check_green}
                        alt="Toposfy"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : null}
                  </Box>
                  <Box sx={{ width: "10%" }}></Box>
                </Box>
              );
            })}
          </BoxContainer>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            marginBottom: 6
          }}
        >
          <BoxContainer sx={{ paddingTop: 0 }}>
            <Box sx={{ width: "45%", pl: 3 }}></Box>
            <Box
              sx={{
                width: "45%",
                background: "rgba(247, 247, 247, 1)",
              }}
            >
              <Card
                sx={{
                  marginBottom: 2,
                  boxShadow: "0.42px 3.38px 2.54px  rgba(0, 0, 0, 0.15)",
                  position: "relative",
                  background: 'linear-gradient(135deg, rgba(100, 92, 89, 1) 0%, rgba(53, 57, 56, 1) 100%)',
                  color: 'rgba(235, 209, 172, 1)',
                }}
              >
                <Box
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    padding: 1.5,
                  }}
                >
                  {t("order.public")}
                </Box>
                <Box
                  sx={{
                    marginBottom: 1,
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ fontSize: 16, mr: 1 }}>$25</Box>
                  <Box sx={{ fontSize: 16 }}>{t("order.unit")}</Box>
                </Box>
                <Box
                  sx={{
                    margin: "0 8px 48px 8px",
                    fontWeight: 700,
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {t("order.info2")}
                </Box>
                <img
                  src={PublicImg}
                  alt=""
                  style={{
                    position: "absolute",
                    right: "-14px",
                    bottom: "-20px",
                  }}
                />
              </Card>
            </Box>
            <Box sx={{ width: "10%" }}></Box>
          </BoxContainer>
          <BoxContainer style={{ flexWrap: "wrap", paddingTop: 0 }}>
            {datas.map((v) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    height: 54,
                    borderBottom: "1px solid rgba(217, 217, 217, 1)",
                    "&:first-of-type": {
                      borderTop: "1px solid rgba(217, 217, 217, 1)",
                    },
                  }}
                >
                  <Box sx={{ width: "45%", pl: 3 }}>{v.name}</Box>
                  <Box
                    sx={{
                      width: "45%",
                      background: "rgba(247, 247, 247, 1)",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {v.public ? (
                      <img
                        src={check_orange}
                        alt="Toposfy"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : null}
                  </Box>
                  <Box sx={{ width: "10%" }}></Box>
                </Box>
              );
            })}
          </BoxContainer>
        </Box>


      </Box>
    </>
  );
}
export default Web;
