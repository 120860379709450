import Page from "../page";
import { useTranslation } from "react-i18next";
import pos from "../../../assets/sence/cafe/bg.jpg";
import mainPic from '../../../assets/sence/cafe/main.png';
import Pic1 from '../../../assets/sence/cafe/1.png';
import Pic2 from '../../../assets/sence/cafe/2.png';
import Pic3 from '../../../assets/sence/cafe/3.png';
import logo from '../../../assets/sence/logo.png';
function Cafe() {
  let { t } = useTranslation();
  const datas = [
    {
      pic: Pic1,
      logo: logo,
      title: t("cafeShop.title1"),
      infos: [
        t("cafeShop.info1"),
      ],
      bgcolor: "rgb(245,244,245)",
    },
    {
      pic: Pic2,
      logo: logo,
      title: t("cafeShop.title2"),
      infos: [
        t("cafeShop.info2"),
      ],
    },
    {
      pic: Pic3,
      logo: logo,
      title: t("cafeShop.title3"),
      infos: [
        t("cafeShop.info3"),
      ],
    }
  ]
  return (
    <Page
      {...{
        title: t("cafeShop.title"),
        content: t("cafeShop.info"),
        btnText: t("cafeShop.button"),
        subtitle1: t("cafeShop.subtitle1"),
        subtitle2: t("cafeShop.subtitle2"),
        subtitle3: t("cafeShop.subtitle3"),
        pic: pos,
        bodyDatas: datas,
        mainPic: mainPic
      }}
    />
  );
}
export default Cafe;
