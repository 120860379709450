import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Grid, Box } from "@mui/material";
import SubForm from "./subForm";

function Form() {
  const ContainerBox = styled(Box)(({ theme }) => ({
    display: "flex",
    margin: "auto",
    padding: "64px 0",
    justifyContent: "center",
    backgroundColor: "#fff",
  }));
  return (
    <ContainerBox sx={{padding: {
      xs: 3,
      md: 'unset'
    }}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: 1200,
          margin: "0 auto",
        }}
      >
        <SubForm />
      </Box>
    </ContainerBox>
  );
}

function Top() {
  const ContainerTop = styled("div")(({ theme }) => ({
    backgroundColor: "rgba(211, 242, 226, 1)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("xl")]: {
      height: 194,
    },
    [theme.breakpoints.down("xl")]: {
      height: 194,
    },
    [theme.breakpoints.down("lg")]: {
      height: 194,
    },
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  }));

  const PTitle = styled("div")(() => ({
    fontSize: 30,
    fontWeight: 700,
    color: "rgba(49, 55, 61, 1)",
    paddingTop: "53px",
    paddingBottom: "24px",
  }));

  const PInfo = styled("div")(({ theme }) => ({
    fontSize: 20,
    fontWeight: 400,
    color: "rgba(49, 55, 61, 1)",
    paddingBottom: "53px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      width: 800,
    },
    [theme.breakpoints.down("xl")]: {
      width: 800,
    },
    [theme.breakpoints.down("lg")]: {
      width: 800,
    },
    [theme.breakpoints.down("md")]: {
      width: "auto",
      padding: "0 8px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      padding: "0 8px 8px 8px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0 8px 8px 8px",
    },
  }));

  let { t } = useTranslation();
  return (
    <>
      <ContainerTop>
        <PTitle>{t("contact.title")}</PTitle>
        <PInfo>{t("contact.info")}</PInfo>
      </ContainerTop>
      <Form />
    </>
  );
}

const ContainerTop = styled("div")(() => ({
  height: 164,
  backgroundColor: "rgba(244, 244, 245, 1)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const PTitle = styled("div")(() => ({
  fontSize: 30,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  paddingTop: "24px",
  paddingBottom: "24px",
}));

const PInfo = styled("div")(() => ({
  fontSize: 20,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  paddingBottom: "24px",
  textAlign: "center",
}));

const MainTitle = styled("div")(() => ({
  fontSize: 20,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  paddingTop: "30px",
  paddingBottom: "16px",
}));

function RowItem() {
  const data = [
    {
      city: "成都 · 中国",
      addr: "武侯区人民南路27号商鼎国际2栋2单元402",
      phone: "武侯区人民南路27号商鼎国际2栋2单元402",
      info: "周一到周日0:00-24:00客服热线（仅国内）",
      email: "332965272@qq.com",
    },
    {
      city: "HONGKONG · China",
      addr: "FLAT 1512,15/F,LUCKY CENTRE, NO,165-171 WAN CHAI ROAD, WAN CHAI, Hong Kong",
      phone: "武侯区人民南路27号商鼎国际2栋2单元402",
      info: "周一到周日0:00-24:00客服热线（仅国内）",
      email: "332965272@qq.com",
    },
  ];
  return data.map((v) => {
    return (
      <>
        <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8} sx={{ display: "flex" }}>
            <Box sx={{ width: "100%" }}>
              <div id="item0">
                <MainTitle>{v.city}</MainTitle>
                <Grid
                  container
                  sx={{
                    pb: 3,
                    borderBottom: "1px solid rgba(204, 204, 204, 1)",
                  }}
                  spacing={11.875}
                >
                  <Grid item xs={4}>
                    {v.addr}
                  </Grid>
                  <Grid item xs={4}>
                    <div>{v.phone}</div>
                    <div>{v.info}</div>
                  </Grid>
                  <Grid item xs={4}>
                    {v.email}
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Box sx={{ display: { xs: "flex", md: "none" }, flexDirection: 'column', padding: 3 }}>
          <MainTitle sx={{padding: 0, paddingBottom: 2}}>{v.city}</MainTitle>
          <Box>{v.addr}</Box>
          <Box>
            <div>{v.phone}</div>
            <div>{v.info}</div>
          </Box>
          <Box>{v.email}</Box>
        </Box>
      </>
    );
  });
}

function Web() {
  const { t } = useTranslation();
  return (
    <>
      <Top />
      <ContainerTop>
        <PTitle>{t("contactUs.text")}</PTitle>
        <PInfo>{t("contactUs.info")}</PInfo>
      </ContainerTop>
      <RowItem />
    </>
  );
}
export default Web;
