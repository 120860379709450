import H111 from "../../../assets/hardware/11/1.jpg";
import H112 from "../../../assets/hardware/11/2.jpg";
import H113 from "../../../assets/hardware/11/3.jpg";
import H114 from "../../../assets/hardware/11/4.jpg";
import H115 from "../../../assets/hardware/11/5.jpg";
import H116 from "../../../assets/hardware/11/6.jpg";
import H117 from "../../../assets/hardware/11/7.jpg";
import H121 from "../../../assets/hardware/12/1.jpg";
import H122 from "../../../assets/hardware/12/2.jpg";
import H123 from "../../../assets/hardware/12/3.jpg";
import H124 from "../../../assets/hardware/12/4.jpg";
import H125 from "../../../assets/hardware/12/5.jpg";
import H126 from "../../../assets/hardware/12/6.jpg";
import H127 from "../../../assets/hardware/12/7.jpg";


import H211 from "../../../assets/hardware/21/1.jpg";
import H212 from "../../../assets/hardware/21/2.jpg";
import H213 from "../../../assets/hardware/21/3.jpg";
import H214 from "../../../assets/hardware/21/4.jpg";
import H215 from "../../../assets/hardware/21/5.jpg";

import H220 from "../../../assets/hardware/22/0.jpg";
import H221 from "../../../assets/hardware/22/1.jpg";
import H222 from "../../../assets/hardware/22/2.jpg";
import H223 from "../../../assets/hardware/22/3.jpg";
import H224 from "../../../assets/hardware/22/4.jpg";
import H225 from "../../../assets/hardware/22/5.jpg";

import H310 from "../../../assets/hardware/31/0.jpg";
import H311 from "../../../assets/hardware/31/1.jpg";
import H312 from "../../../assets/hardware/31/2.jpg";
import H313 from "../../../assets/hardware/31/3.jpg";
import H314 from "../../../assets/hardware/31/4.jpg";
import H315 from "../../../assets/hardware/31/5.jpg";

import H320 from "../../../assets/hardware/32/0.jpg";
import H321 from "../../../assets/hardware/32/1.jpg";
import H322 from "../../../assets/hardware/32/2.jpg";
import H323 from "../../../assets/hardware/32/3.jpg";
import H324 from "../../../assets/hardware/32/4.jpg";
import H325 from "../../../assets/hardware/32/5.jpg";

import H331 from "../../../assets/hardware/33/1.jpg";
import H332 from "../../../assets/hardware/33/2.jpg";
import H333 from "../../../assets/hardware/33/3.jpg";
import H334 from "../../../assets/hardware/33/4.jpg";
import H335 from "../../../assets/hardware/33/5.jpg";


import H511 from "../../../assets/hardware/51/1.jpg";
import H512 from "../../../assets/hardware/51/2.jpg";
import H513 from "../../../assets/hardware/51/3.jpg";
import H514 from "../../../assets/hardware/51/4.jpg";
import H515 from "../../../assets/hardware/51/5.jpg";
import H516 from "../../../assets/hardware/51/6.jpg";
import H611 from "../../../assets/hardware/61/1.jpg";
import H612 from "../../../assets/hardware/61/2.jpg";
import H613 from "../../../assets/hardware/61/3.jpg";
import H614 from "../../../assets/hardware/61/4.jpg";
import H615 from "../../../assets/hardware/61/5.jpg";
import H616 from "../../../assets/hardware/61/6.jpg";
import H617 from "../../../assets/hardware/61/7.jpg";

import H711 from "../../../assets/hardware/71/1.jpg";
import H712 from "../../../assets/hardware/71/2.jpg";
import H713 from "../../../assets/hardware/71/3.jpg";
import H714 from "../../../assets/hardware/71/4.jpg";
import H715 from "../../../assets/hardware/71/5.jpg";
export function getDatas(t) {
  return [
    {
      id: 11,
      name: t("h11.titlename"),
      link: "https://www.amazon.com/dp/B0D7GTKBLY?ref=myi_title_dp",
      info: [
        t("h11.info1"),
        t("h11.info2"),
        t("h11.info3"),
        t("h11.info4"),
        t("h11.info5"),
        t("h11.info6"),
      ],
      pics: [H111, H112, H113, H114, H115, H116, H117],
    },
    {
      id: 12,
      name: t("h12.titlename"),
      link: "https://www.amazon.com/dp/B0D7CGJ826?ref=myi_title_dp",
      info: [
        t("h12.info1"),
        t("h12.info2"),
        t("h12.info3"),
        t("h12.info4"),
        t("h12.info5"),
        t("h12.info6"),
      ],
      pics: [H121, H122, H123, H124, H125, H126, H127],
    },

    {
      id: 21,
      name: t("h21.titlename"),
      link: "https://www.amazon.com/dp/B0D7CGJ826?ref=myi_title_dp",
      info: [
        t("h21.info1"),
        t("h21.info2"),
        t("h21.info3"),
        t("h21.info4"),
        t("h21.info5")
      ],
      pics: [H211, H212, H213, H214, H215],
    },

    {
      id: 22,
      name: t("h22.titlename"),
      link: "https://www.amazon.com/dp/B0D7CGJ826?ref=myi_title_dp",
      info: [
        t("h22.info1"),
        t("h22.info2"),
        t("h22.info3"),
        t("h22.info4"),
        t("h22.info5")
      ],
      pics: [H220, H221, H222, H223, H224, H225],
    },

    {
      id: 31,
      name: t("h31.titlename"),
      link: "https://www.amazon.com/dp/B0D7CGJ826?ref=myi_title_dp",
      info: [
        t("h31.info1"),
        t("h31.info2"),
        t("h31.info3"),
        t("h31.info4"),
        t("h31.info5")
      ],
      pics: [H310, H311, H312, H313, H314, H315],
    },

    {
      id: 32,
      name: t("h32.titlename"),
      link: "https://www.amazon.com/dp/B0D7CGJ826?ref=myi_title_dp",
      info: [
        t("h32.info1"),
        t("h32.info2"),
        t("h32.info3"),
        t("h32.info4"),
        t("h32.info5")
      ],
      pics: [H320, H321, H322, H323, H324, H325],
    },

    {
      id: 33,
      name: t("h33.titlename"),
      link: "https://www.amazon.com/dp/B0D7CGJ826?ref=myi_title_dp",
      info: [
        t("h33.info1"),
        t("h33.info2"),
        t("h33.info3"),
        t("h33.info4"),
        t("h33.info5")
      ],
      pics: [H331, H332, H333, H334, H335],
    },

    {
      id: 51,
      name: t("h51.titlename"),
      link: "https://www.amazon.com/dp/B0D7GF9C3Z?ref=myi_title_dp",
      info: [
        t("h51.info1"),
        t("h51.info2"),
        t("h51.info3"),
        t("h51.info4"),
        t("h51.info5"),
      ],
      pics: [H511, H512, H513, H514, H515, H516],
    },
    {
      id: 61,
      name: t("h61.titlename"),
      link: "https://www.amazon.com/dp/B0D7H3RM2D?ref=myi_title_dp",
      info: [
        t("h61.info1"),
        t("h61.info2"),
        t("h61.info3"),
        t("h61.info4"),
        t("h61.info5"),
        t("h61.info6")
      ],
      pics: [H611, H612, H613, H614, H615, H616, H617],
    },

    {
      id: 71,
      name: t("h71.titlename"),
      link: "https://www.amazon.com/dp/B0D7CGJ826?ref=myi_title_dp",
      info: [
        t("h71.info1"),
        t("h71.info2"),
        t("h71.info3"),
        t("h71.info4"),
        t("h71.info5")
      ],
      pics: [H711, H712, H713, H714, H715],
    },
  ];
}

