import Page from "../page";
import { useTranslation } from "react-i18next";
import pos from "../../../assets/sence/restaurant/bg.jpg";
import mainPic from '../../../assets/sence/restaurant/main.png';
import Pic1 from '../../../assets/sence/restaurant/1.png';
import Pic2 from '../../../assets/sence/restaurant/2.png';
import Pic3 from '../../../assets/sence/restaurant/3.png';
import Pic4 from '../../../assets/sence/restaurant/4.png';
import logo from '../../../assets/sence/logo.png';
function Restaurant() {
  let { t } = useTranslation();
  const datas = [
    {
      pic: Pic1,
      logo: logo,
      title: t("restaurantShop.title1"),
      infos: [
        t("restaurantShop.info1"),
      ],
      bgcolor: "rgb(245,244,245)",
    },
    {
      pic: Pic2,
      logo: logo,
      title: t("restaurantShop.title2"),
      infos: [
        t("restaurantShop.info2"),
      ],
    },
    {
      pic: Pic3,
      logo: logo,
      title: t("restaurantShop.title3"),
      infos: [
        t("restaurantShop.info3"),
      ],
      bgcolor: "rgb(245,244,245)",
    },
    {
      pic: Pic4,
      logo: logo,
      title: t("restaurantShop.title4"),
      infos: [
        t("restaurantShop.info4"),
      ],
    },
  ]
  return (
    <Page
      {...{
        title: t("restaurantShop.title"),
        content: t("restaurantShop.info"),
        btnText: t("restaurantShop.button"),
        subtitle1: t("restaurantShop.subtitle1"),
        subtitle2: t("restaurantShop.subtitle2"),
        subtitle3: t("restaurantShop.subtitle3"),
        pic: pos,
        mainPic: mainPic,
        bodyDatas: datas
      }}
    />
  );
}

export default Restaurant;
