import Web from "./Web";
import Mini from "./Mini";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const ContainerTop = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(244, 244, 245, 1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 100,
}));

const PTitle = styled("div")(() => ({
  fontSize: 30,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
}));

function Top() {
  let { t } = useTranslation();
  return (
    <ContainerTop id="featureTop">
      <PTitle>{t("mainMenu.hardware")}</PTitle>
    </ContainerTop>
  );
}

function HardwareInfo() {
  return (
    <>
      <Top />
      <Web />
      <Mini />
    </>
  );
}
export default HardwareInfo;
