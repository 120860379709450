import Overview from './Overview';
import Scene from './scene';
import Body from './body';
import Product from './product';
import Footer from './footer';

function Home() {
  return (
    <>
      <Overview />
      <Scene />
      <Body />
      <Product />
      <Footer />
    </>
  );
}
export default Home;
