function DownBoxSvg({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="71"
      height="70.999755859375"
      viewBox="0 0 71 70.999755859375"
      fill="none"
    >
      <circle cx="35.5" cy="35.5" r="35.5" fill="#FFFFFF"></circle>
      <path
        d="M52.6314 43.7029C50.4219 42.5882 48.7554 40.9283 47.782 38.6036C47.0815 36.9307 46.9086 35.1748 47.0999 33.3732C47.2777 31.6993 47.8532 30.1884 48.8542 28.8486C49.6046 27.8443 50.533 27.0379 51.5657 26.3498C51.8175 26.1821 51.8191 26.1796 51.6384 25.9245C51.2749 25.4114 50.8689 24.9368 50.4165 24.5018C48.4932 22.6523 46.1459 21.8575 43.5657 21.6667C42.2269 21.5677 40.9336 21.8571 39.6725 22.2857C38.6103 22.6466 37.5502 23.0188 36.5082 23.4354C35.6202 23.7903 34.7728 23.783 33.8756 23.454C32.8151 23.0651 31.7719 22.625 30.6934 22.2874C29.6333 21.9556 28.5623 21.7105 27.4374 21.794C26.4778 21.8651 25.5412 22.0369 24.6389 22.3752C21.3862 23.5947 19.2354 25.9498 17.9515 29.1724C17.1773 31.1158 16.8878 33.1536 16.9182 35.353C16.893 36.3409 16.9846 37.4299 17.1379 38.5151C17.5465 41.4065 18.4224 44.1504 19.6404 46.7882C20.5584 48.7763 21.7683 50.5741 23.0929 52.2965C23.8463 53.2764 24.6858 54.1714 25.6876 54.8963C26.9181 55.7866 28.2626 56.0487 29.7301 55.6716C30.4926 55.4757 31.2299 55.209 31.9559 54.9051C33.0539 54.4456 34.1847 54.1174 35.3759 54.0491C36.8328 53.9656 38.2038 54.3339 39.5348 54.8963C40.2395 55.194 40.9574 55.4479 41.7014 55.6259C43.2063 55.9859 44.6063 55.7533 45.8967 54.8768C46.6423 54.3703 47.285 53.7462 47.8592 53.0515C50.039 50.4134 51.7728 47.5126 52.8421 44.2234C52.959 43.8638 52.9623 43.8698 52.6314 43.7029Z"
        fill={color}
      ></path>
      <path
        d="M35.083 21.2705C36.2372 21.321 37.3427 21.1209 38.3897 20.6142C39.6791 19.9902 40.7112 19.0452 41.5862 17.9162C42.9832 16.1139 43.7914 14.0866 43.7916 11.7629C43.7916 11.6108 43.7939 11.4586 43.7911 11.3066C43.782 10.8202 43.7462 10.801 43.2667 10.8653C42.1253 11.0182 41.0705 11.4261 40.0604 11.97C37.7127 13.2347 36.132 15.1748 35.2405 17.7028C34.8603 18.7806 34.7368 19.9029 34.8414 21.0484C34.8569 21.219 34.9337 21.264 35.083 21.2705Z"
        fill={color}
      ></path>
    </svg>
  );
}

export default DownBoxSvg;
