import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import downByIos from "../assets/downByIos.png";
import downByWin from "../assets/downByWin.png";

const ContainerTop = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(244, 244, 245, 1)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.up("xl")]: {
    height: 334,
  },
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {
    height: "auto",
  },
}));

const PTitle = styled("div")(() => ({
  fontSize: 30,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  paddingTop: "32px",
  paddingBottom: "24px",
}));

const PInfo = styled("div")(() => ({
  fontSize: 20,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  paddingBottom: "24px",
  width: "80%",
}));

const PImg = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    width: 222,
    height: 72,
  },
  [theme.breakpoints.down("xl")]: {
    width: 222,
    height: 72,
    marginBottom: 16
  },
  [theme.breakpoints.down("lg")]: {
    width: 166.5,
    height: 54,
    marginBottom: 16
  },
  [theme.breakpoints.down("md")]: {
    width: 166.5,
    height: 54,
    marginBottom: 16
  },
  [theme.breakpoints.down("sm")]: {
    width: 111,
    height: 36,
    marginBottom: 16
  },
  [theme.breakpoints.down("xs")]: {
    width: 111,
    height: 36,
    marginBottom: 16
  },
}));

function Bottom() {
  let { t } = useTranslation();
  return (
    <ContainerTop style={{ textAlign: "center" }}>
      <PTitle>{t("software.down1")}</PTitle>
      <PInfo>{t("software.down2")}</PInfo>
      <div style={{ display: "flex" }}>
        <PImg src={downByIos} style={{ marginRight: "16px" }} alt="" />
        <PImg src={downByWin} />
      </div>
    </ContainerTop>
  );
}

function DownFooterWeb() {
  return <Bottom />;
}
export default DownFooterWeb;
