function DownBoxSvg({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="71"
      height="70.9998779296875"
      viewBox="0 0 71 70.9998779296875"
      fill="none"
    >
      <circle
        cx="35.5001220703125"
        cy="35.5001220703125"
        r="35.5"
        fill="#FFFFFF"
      ></circle>
      <path
        d="M35.6382 19.4628L35.6382 35.2664L56.5346 35.2664L56.5346 16.0001L35.6382 19.4628ZM35.6382 54.5929L56.5346 57.9954L56.5346 38.74L35.6382 38.74L35.6382 54.5929ZM13.5547 35.2609L31.2182 35.2609L31.2182 20.0372L13.5547 22.9638L13.5547 35.2609ZM13.5547 51.1466L31.2182 54.024L31.2182 38.74L13.5547 38.74L13.5547 51.1466Z"
        fill={color}
      ></path>
    </svg>
  );
}

export default DownBoxSvg;
