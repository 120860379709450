import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import restaurant from "../../assets/home/restaurant.png";
import retailstore from "../../assets/home/retailstore.png";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

function ImgBox({ alt, url }) {
  return (
    <CardMedia component="img" alt={alt} width="540" image={url} />
  );
}

export const ContainerBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    justifyContent: 'space-around'
  },
  [theme.breakpoints.down("sm")]: {
    flexWrap: 'wrap'
  },
}));


export const CardBox = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    width: 634,
  },
  [theme.breakpoints.down("xl")]: {
    width: "40%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "48%"
  },
  [theme.breakpoints.down("md")]: {
    width: "48%"
  },
  [theme.breakpoints.down("sm")]: {
    width: "60%",
    height: '60%',
    marginTop: 16
  },
}));

export const SpaceBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    display: 'block'
  },
  [theme.breakpoints.down("xl")]: {
    display: 'block'
  },
  [theme.breakpoints.down("lg")]: {
    display: 'none'
  }
}));

function ImgCard({ alt, url }) {
  let { t } = useTranslation();
  return (
    <CardBox>
      <ImgBox {...{ alt, url }} />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {t(`home.${alt}`)}
        </Typography>
      </CardContent>
    </CardBox>
  );
}

function Scene() {
  return (
    <ContainerBox sx={{ display: "flex", py: "40px", justifyContent: 'center' }}>
      <ImgCard {...{ alt: "restaurant", url: restaurant }} />
      <SpaceBox sx={{ width: 40 }} />
      <ImgCard {...{ alt: "retailstore", url: retailstore }} />
    </ContainerBox>
  );
}
export default Scene;
