import { Box } from "@mui/material";
import Device from "../../assets/home/device.png";
import PlayBtn from "../../assets/home/playBtn.png";
import { useTranslation, Trans, Translation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  ContainerBox,
  ContentBox,
  PicOuterBox,
  Title,
  TextInfo,
  BeginButton,
  ContactButton,
  PlayText,
  ImgBox,
  ContainerBoxMini,
  ImgBoxMini
} from "./OverviewCss";

const BoxContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1152,
  display: "flex",
}));

function Overview() {
  let { t } = useTranslation();
  return (
    <>
      <ContainerBox sx={{ display: { xs: "none", md: "flex" } }}>
        <BoxContainer>
          <ContentBox>
            <Title sx={{ pt: 8 }}>{t("home.title")}</Title>
            <TextInfo sx={{ pt: 3 }}>
              <Trans>home.textInfo</Trans>
            </TextInfo>
            <Box sx={{ py: { xl: 10, sm: 5, xs: 5 } }}>
              <Translation>
                {(t) => (
                  <BeginButton
                    variant="contained"
                    sx={{ mr: 2 }}
                    href="http://m.toposfy.com/"
                  >
                    {t("home.beginBtn")}
                  </BeginButton>
                )}
              </Translation>
              <ContactButton variant="outlined">
                {t("home.contactBtn")}
              </ContactButton>
            </Box>
          </ContentBox>
          <PicOuterBox>
            <Box
              sx={{
                position: "absolute",
                width: "auto",
                height: 32,
                top: "auto",
                bottom: 32,
                marginLeft: 0,
                display: "flex",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <img src={PlayBtn} alt="Play vedio" />
              <PlayText>Watch video</PlayText>
            </Box>
            <ImgBox src={Device} alt="toposfy Device" />
          </PicOuterBox>
        </BoxContainer>
      </ContainerBox>
      <ContainerBoxMini sx={{ display: { xs: "flex", md: "none" } }}>
        <BoxContainer sx={{flexDirection: 'column'}}>
          <Title sx={{ pt: 8, fontSize: 20, fontWeight: 500, textAlign: 'center', padding: 0, margin: '0 auto 20px auto' }}>{t("home.title")}</Title>
          <ImgBoxMini src={Device} alt="toposfy Device" />
          <TextInfo sx={{ pt: 3 }}>
            <Trans>home.textInfo</Trans>
          </TextInfo>
        </BoxContainer>
      </ContainerBoxMini>
    </>
  );
}
export default Overview;
