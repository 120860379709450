import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import RestaurantLogo from "../../assets/home/restaurantLogo.png";
import RetailLogo from "../../assets/home/retailLogo.png";
import ServiceLogo from "../../assets/home/serviceLogo.png";

const Title = styled("div")(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: "rgba(16, 16, 16, 1)",
  marginBottom: "8px",
}));

const Info = styled("div")(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: "rgba(115, 118, 122, 1)",
  marginBottom: "8px",
}));

const FTitle = styled("div")(() => ({
  fontSize: 24,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  marginTop: "32px",
  marginBottom: "20px",
}));

function Footer() {
  const FBox = styled(Box)(() => ({
    paddingBottom: 32, display: 'flex', alignItems: 'center'
  }));
  let { t } = useTranslation();
  const datas1 = [
    t("home.type11"),
    t("home.type12"),
    t("home.type13"),
    t("home.type14"),
    t("home.type15"),
    t("home.type16"),
    t("home.type17"),
  ];
  const datas2 = [
    t("home.type21"),
    t("home.type22"),
    t("home.type23"),
    t("home.type24"),
    t("home.type25"),
    t("home.type26"),
    t("home.type27"),
  ];
  const datas3 = [
    t("home.type31"),
    t("home.type32"),
    t("home.type33"),
    t("home.type34"),
    t("home.type35"),
  ];
  return (
    <>
      <Box
        sx={{
          height: 352,
          bgcolor: "rgb(255, 255, 255)",
          display: { sm: "flex", xs: "none" },
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FTitle>{t("home.footerTitle")}</FTitle>
        <Box sx={{ width: 626 }}>
          <Grid container>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img src={RestaurantLogo} alt="" style={{ height: "42px" }} />
              <Box sx={{ ml: 2 }}>
                <Title>{t("home.type1")}</Title>
                {datas1.map((v) => {
                  return <Info key={v}>{v}</Info>;
                })}
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img src={RetailLogo} alt="" style={{ height: "42px" }} />
              <Box sx={{ ml: 2 }}>
                <Title>{t("home.type2")}</Title>
                {datas2.map((v) => {
                  return <Info key={v}>{v}</Info>;
                })}
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img src={ServiceLogo} alt="" style={{ height: "42px" }} />
              <Box sx={{ ml: 2 }}>
                <Title>{t("home.type3")}</Title>
                {datas3.map((v) => {
                  return <Info key={v}>{v}</Info>;
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: "rgb(255, 255, 255)",
          display: { sm: "none", xs: "flex" },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: 'center',
          textAlign: 'center',
          padding: '20px 0'
        }}
      >
        <Box>
          <FBox>
            <img src={RestaurantLogo} alt="" style={{ height: "42px", marginRight: 16 }} />
            <Title>{t("home.type1")}</Title>
          </FBox>
          <FBox>
            <img src={RetailLogo} alt="" style={{ height: "42px", marginRight: 16 }} />
            <Title>{t("home.type2")}</Title>
          </FBox>
          <FBox>
            <img src={ServiceLogo} alt="" style={{ height: "42px", marginRight: 16 }} />
            <Title>{t("home.type3")}</Title>
          </FBox>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
