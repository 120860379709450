import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Menu,
  MenuItem,
  List,
  ListItemButton,
  ListItemText,
  Link,
} from "@mui/material";
import "../layout/headerAppBar.css";
import LoginIcon from "@mui/icons-material/Login";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import language from "../utils/language";
import { changeLang } from "../store/langSlice";
import { changeLangIndex } from "../store/langIndexSlice";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

function LangMenu() {
  const { i18n } = useTranslation();
  const skin = useSelector((state) => state.skin.value);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const options = language;
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const selectedIndex = useSelector((state) => state.langIndex.value);
  const handleMenuItemClick = (event, index, option) => {
    setAnchorEl(null);
    i18n.changeLanguage(option.sysLanguageCode);
    localStorage.setItem("lang", option.sysLanguageCode);
    localStorage.setItem("langIndex", index);
    dispatch(changeLang(option.sysLanguageCode));
    dispatch(changeLangIndex(index));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <List component="nav" aria-label="Device settings" sx={{ p: 0 }}>
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{ p: 0 }}
        >
          <LanguageIcon sx={{ mr: 1 }} />
          <ListItemText
            secondary={options[selectedIndex].sysLanguageName}
            sx={{ "& p": { color: skin.color } }}
          />
          {open ? <ArrowDropUpSharpIcon /> : <ArrowDropDownSharpIcon />}
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.sysLanguageId}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, option)}
          >
            {option.sysLanguageName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function AppItem(props) {
  return (
    <Box
      className="header-app-bar-item"
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
    >
      {props.children}
    </Box>
  );
}

const BoxContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1312,
  height: 32,
  [theme.breakpoints.down("lgUp")]: {
    margin: "0 24px",
  },
  [theme.breakpoints.up("lgUp")]: {
    margin: "0 auto",
  },
}));

function Header() {
  const { t } = useTranslation();
  const skin = useSelector((state) => state.skin.value);

  const BoxBarContainer = styled(Box)(({ theme }) => ({
    bgcolor: skin.barBg,
    color: skin.color,
    [theme.breakpoints.down("mg")]: {
      display: "none",
    },
    [theme.breakpoints.up("mg")]: {
      display: "block",
    },
  }));

  return (
    <BoxBarContainer id="HeaderAppBar">
      <BoxContainer>
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
        >
          {/* <AppItem>{t("appbar.Community")}</AppItem> */}
          <AppItem>
            <LoginIcon sx={{ mr: 1 }} />
            <Link
              href="http://m.toposfy.com/"
              underline="none"
              sx={{ color: "inherit" }}
            >
              {t("appbar.login")}
            </Link>
          </AppItem>
          <AppItem>
            <LangMenu/>
          </AppItem>
        </Box>
      </BoxContainer>
    </BoxBarContainer>
  );
}

export default Header;
