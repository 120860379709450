import * as React from "react";
import { Box, Menu, MenuItem, Link, List, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import { useSelector, useDispatch } from "react-redux";
import LoginIcon from "@mui/icons-material/Login";
import LanguageIcon from "@mui/icons-material/Language";
import language from "../utils/language";
import { changeLang } from "../store/langSlice";
import { changeLangIndex } from "../store/langIndexSlice";

const RowCss = {
  height: 48,
  paddingLeft: 16,
  paddingRight: 16,
  display: "flex",
  alignItems: "center",
};

function LangMenu() {
  const { i18n } = useTranslation();
  const skin = useSelector((state) => state.skin.value);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const options = language;
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const selectedIndex = useSelector((state) => state.langIndex.value);
  const handleMenuItemClick = (event, index, option) => {
    setAnchorEl(null);
    i18n.changeLanguage(option.sysLanguageCode);
    localStorage.setItem("lang", option.sysLanguageCode);
    localStorage.setItem("langIndex", index);
    dispatch(changeLang(option.sysLanguageCode));
    dispatch(changeLangIndex(index));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <List component="nav" aria-label="Device settings" sx={{ pl: 2, pr: 2, display: 'flex' }}>
        <LanguageIcon sx={{ mr: 4 }} />
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{ p: 0 }}
        >
          <ListItemText
            secondary={options[selectedIndex].sysLanguageName}
            sx={{ "& p": { color: skin.color } }}
          />
          {open ? <ArrowDropUpSharpIcon /> : <ArrowDropDownSharpIcon />}
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.sysLanguageId}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, option)}
          >
            {option.sysLanguageName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function HeaderMenuMini() {
  let { t } = useTranslation();
  const skin = useSelector((state) => state.skin.value);
  let textColor = { color: skin.color };
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: skin.menuBg,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      {/* <Link href="#/" sx={textColor} underline="none" style={RowCss}>
        <InboxIcon sx={{ color: skin.color, marginRight: 4 }} />
        {t("appbar.Community")}
      </Link> */}
      <Link
        href="http://m.toposfy.com/"
        sx={textColor}
        underline="none"
        style={RowCss}
      >
        <LoginIcon sx={{ color: skin.color, marginRight: 4 }} />
        {t("appbar.login")}
      </Link>
      <LangMenu />
    </Box>
  );
}

export default HeaderMenuMini;
