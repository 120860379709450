import App from "../App";
import Home from "../view/home/index";
import Feature from '../view/feature/Web';
import Hardware from '../view/hardware/index';
import ContactUs from '../view/contactUs/index';
import Help from '../view/help/index';
import Admin from '../view/businessType/admin';
import Kitchen from '../view/businessType/kitchen';
import Pos from '../view/businessType/pos';
import Sale from '../view/businessType/sale';
import Selfhelp from '../view/businessType/selfhelp';
import Shop from '../view/businessType/shop';
import DiningCar from '../view/businessType/diningCar';
import Scan from '../view/businessType/scan';
import Customer from '../view/businessType/customer';

import Bar from '../view/scene/bar';
import Cafe from '../view/scene/cafe';
import Clothing from '../view/scene/clothing';
import Minimart from '../view/scene/minimart';
import Restaurant from '../view/scene/restaurant';
import DiningCar2 from '../view/scene/diningCar';

import Price from '../view/price/index';
import Partners from '../view/partners/page';

import HardwareInfo from '../view/hardware/goods/info';
import PrivacyPolic from '../view/privacyPolic';
import DeleteUser from '../view/deleteUser';
import DeleteAccountInfo from '../view/deleteUser/info';
import UserAgreement from '../view/userAgreement';
import StatPrivacyPolic from '../view/statPrivacyPolic';
import StatUserAgreement from '../view/statUserAgreement';

import {
  createHashRouter
} from "react-router-dom";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "feature/index",
        element: <Feature />,
      },
      {
        path: "hardware/index",
        element: <Hardware />,
      },
      {
        path: "partners/index",
        element: <Partners />,
      },
      {
        path: "contactUs/index",
        element: <ContactUs />,
      },
      {
        path: "help/index",
        element: <Help />,
      },

      {
        path: "type/admin",
        element: <Admin />,
      },
      {
        path: "type/kitchen",
        element: <Kitchen />,
      },
      {
        path: "type/pos",
        element: <Pos />,
      },
      {
        path: "type/sale",
        element: <Sale />,
      },
      {
        path: "type/selfhelp",
        element: <Selfhelp />,
      },
      {
        path: "type/shop",
        element: <Shop />,
      },
      {
        path: "type/diningCar",
        element: <DiningCar />,
      },
      {
        path: "type/scan",
        element: <Scan />,
      },
      {
        path: "type/customer",
        element: <Customer />,
      },

      {
        path: "scene/bar",
        element: <Bar />,
      },
      {
        path: "scene/clothing",
        element: <Clothing />,
      },
      {
        path: "scene/restaurant",
        element: <Restaurant />,
      },
      {
        path: "scene/cafe",
        element: <Cafe />,
      },
      {
        path: "scene/minimart",
        element: <Minimart />,
      },
      {
        path: "scene/diningCar",
        element: <DiningCar2 />,
      },
      {
        path: "price/index",
        element: <Price />,
      },
      {
        path: "hardware/goods/info/:id",
        element: <HardwareInfo />,
      },
    ],
  },
  {
    path: "privacyPolic",
    element: <PrivacyPolic />,
  },
  {
    path: "deleteAccountIndex",
    element: <DeleteUser />,
  },
  {
    path: "deleteAccountInfo",
    element: <DeleteAccountInfo />,
  },
  {
    path: "userAgreement",
    element: <UserAgreement />,
  },
  {
    path: "statPrivacyPolic",
    element: <StatPrivacyPolic />,
  },
  {
    path: "statUserAgreement",
    element: <StatUserAgreement />,
  },
]);
export default router;
