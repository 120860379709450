import Page from "../page";
import { useTranslation } from "react-i18next";
import pos from "../../../assets/sence/clothing/bg.jpg";
import mainPic from '../../../assets/sence/clothing/main.png';
import Pic1 from '../../../assets/sence/clothing/1.png';
import Pic2 from '../../../assets/sence/clothing/2.png';
import logo from '../../../assets/sence/logo.png';
function Clothing() {
  let { t } = useTranslation();
  const datas = [
    {
      pic: Pic1,
      logo: logo,
      title: t("clothingtShop.title1"),
      infos: [
        t("clothingtShop.info1"),
      ],
      bgcolor: "rgb(245,244,245)",
    },
    {
      pic: Pic2,
      logo: logo,
      title: t("clothingtShop.title2"),
      infos: [
        t("clothingtShop.info2"),
      ],
    }
  ]
  return (
    <Page
      {...{
        title: t("clothingtShop.title"),
        content: t("clothingtShop.info"),
        btnText: t("clothingtShop.button"),
        subtitle1: t("clothingtShop.subtitle1"),
        subtitle2: t("clothingtShop.subtitle2"),
        subtitle3: t("clothingtShop.subtitle3"),
        pic: pos,
        bodyDatas: datas,
        mainPic: mainPic
      }}
    />
  );
}
export default Clothing;
