import Page from "./page";
import { useTranslation } from "react-i18next";
import sale from "../../assets/software/sale.png";
import qrcode from '../../assets/software/qrcodeBlue.png';

import Pic1 from '../../assets/software/sale/1.png';
import Pic2 from '../../assets/software/sale/2.png';
import Pic3 from '../../assets/software/sale/3.png';
import logo1 from '../../assets/software/sale/logo1.png';
import logo2 from '../../assets/software/sale/logo2.png';
import logo3 from '../../assets/software/sale/logo3.png';

function Sale() {
  let { t } = useTranslation();

  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("saleType.reportTitle"),
      infos: [
        t("saleType.reportInfo1"),
        t("saleType.reportInfo2"),
      ],
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Pic2,
      logo: logo2,
      title: t("saleType.goodsTitle"),
      infos: [
        t("saleType.goodsInfo1"),
        t("saleType.goodsInfo2"),
      ],
    },
    {
      pic: Pic3,
      logo: logo3,
      title: t("saleType.stockTitle"),
      infos: [
        t("saleType.stockInfo1"),
        t("saleType.stockInfo2"),
      ],
      bgcolor: "rgb(245,255,250)",
    }
  ]

  return (
    <Page
      {...{
        title: t("saleType.title"),
        content: t("saleType.info"),
        pic: sale,
        bgColor: "rgba(211, 242, 226, 1)",
        linearGradient: 'linear-gradient(156.74deg, rgba(166, 227, 188, 0.4) 0%, rgba(197, 230, 205, 0.01) 100%)',
        downInfo: {
          bgColor: "#31b077",
          qrBgColor: '#e3faec',
          qrcode: qrcode,
          urlName: 'toposify_statistics'
        },
        bodyDatas: datas
      }}
    />
  );
}

export default Sale;
