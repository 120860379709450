const country = {
    zh_CN: [
      {
        id: 1,
        country_name: "尼日利亚",
        country_code: "NG",
        phone_code: "+234",
      },
      {
        id: 2,
        country_name: "加拿大",
        country_code: "CA",
        phone_code: "+1",
      },
      {
        id: 3,
        country_name: "刚果民主共和国",
        country_code: "CD",
        phone_code: "+243",
      },
      {
        id: 4,
        country_name: "俄罗斯",
        country_code: "RU",
        phone_code: "+7",
      },
      {
        id: 5,
        country_name: "美国",
        country_code: "US",
        phone_code: "+1",
      },
      {
        id: 6,
        country_name: "比利时",
        country_code: "BE",
        phone_code: "+32",
      },
      {
        id: 7,
        country_name: "孟加拉国",
        country_code: "BD",
        phone_code: "+880",
      },
      {
        id: 8,
        country_name: "塞尔维亚",
        country_code: "RS",
        phone_code: "+381",
      },
      {
        id: 9,
        country_name: "巴巴多斯",
        country_code: "BB",
        phone_code: "+1246",
      },
      {
        id: 10,
        country_name: "波斯尼亚和黑塞哥维那",
        country_code: "BA",
        phone_code: "+387",
      },
      {
        id: 11,
        country_name: "罗马尼亚",
        country_code: "RO",
        phone_code: "+40",
      },
      {
        id: 12,
        country_name: "阿塞拜疆",
        country_code: "AZ",
        phone_code: "+994",
      },
      {
        id: 13,
        country_name: "阿鲁巴",
        country_code: "AW",
        phone_code: "+297",
      },
      {
        id: 14,
        country_name: "澳大利亚",
        country_code: "AU",
        phone_code: "+61",
      },
      {
        id: 15,
        country_name: "奥地利",
        country_code: "AT",
        phone_code: "+43",
      },
      {
        id: 16,
        country_name: "留尼汪",
        country_code: "RE",
        phone_code: "+262",
      },
      {
        id: 17,
        country_name: "美属萨摩亚",
        country_code: "AS",
        phone_code: "+1684",
      },
      {
        id: 18,
        country_name: "阿根廷",
        country_code: "AR",
        phone_code: "+54",
      },
      {
        id: 19,
        country_name: "安哥拉",
        country_code: "AO",
        phone_code: "+244",
      },
      {
        id: 20,
        country_name: "亚美尼亚",
        country_code: "AM",
        phone_code: "+374",
      },
      {
        id: 21,
        country_name: "阿尔巴尼亚",
        country_code: "AL",
        phone_code: "+355",
      },
      {
        id: 22,
        country_name: "安圭拉",
        country_code: "AI",
        phone_code: "+1264",
      },
      {
        id: 23,
        country_name: "安提瓜 和巴布达",
        country_code: "AG",
        phone_code: "+1268",
      },
      {
        id: 24,
        country_name: "阿富汗",
        country_code: "AF",
        phone_code: "+93",
      },
      {
        id: 25,
        country_name: "阿拉伯联合酋长国",
        country_code: "AE",
        phone_code: "+971",
      },
      {
        id: 26,
        country_name: "安道尔",
        country_code: "AD",
        phone_code: "+376",
      },
      {
        id: 27,
        country_name: "阿森松岛",
        country_code: "AC",
        phone_code: "+247",
      },
      {
        id: 28,
        country_name: "卡塔尔",
        country_code: "QA",
        phone_code: "+974",
      },
      {
        id: 29,
        country_name: "巴拉圭",
        country_code: "PY",
        phone_code: "+595",
      },
      {
        id: 30,
        country_name: "帕劳",
        country_code: "PW",
        phone_code: "+680",
      },
      {
        id: 31,
        country_name: "葡萄牙",
        country_code: "PT",
        phone_code: "+351",
      },
      {
        id: 32,
        country_name: "巴勒斯坦领土",
        country_code: "PS",
        phone_code: "+970",
      },
      {
        id: 33,
        country_name: "波多黎各",
        country_code: "PR",
        phone_code: "+1787",
      },
      {
        id: 34,
        country_name: "索马里",
        country_code: "SO",
        phone_code: "+252",
      },
      {
        id: 35,
        country_name: "塞内加尔",
        country_code: "SN",
        phone_code: "+221",
      },
      {
        id: 36,
        country_name: "圣马力诺",
        country_code: "SM",
        phone_code: "+378",
      },
      {
        id: 37,
        country_name: "塞拉利昂",
        country_code: "SL",
        phone_code: "+232",
      },
      {
        id: 38,
        country_name: "斯洛伐克",
        country_code: "SK",
        phone_code: "+421",
      },
      {
        id: 39,
        country_name: "伯利兹",
        country_code: "BZ",
        phone_code: "+501",
      },
      {
        id: 40,
        country_name: "白俄罗斯",
        country_code: "BY",
        phone_code: "+375",
      },
      {
        id: 41,
        country_name: "斯洛文尼亚",
        country_code: "SI",
        phone_code: "+386",
      },
      {
        id: 42,
        country_name: "博茨瓦纳",
        country_code: "BW",
        phone_code: "+267",
      },
      {
        id: 43,
        country_name: "新加坡",
        country_code: "SG",
        phone_code: "+65",
      },
      {
        id: 44,
        country_name: "瑞典",
        country_code: "SE",
        phone_code: "+46",
      },
      {
        id: 45,
        country_name: "不丹",
        country_code: "BT",
        phone_code: "+975",
      },
      {
        id: 46,
        country_name: "苏丹",
        country_code: "SD",
        phone_code: "+249",
      },
      {
        id: 47,
        country_name: "巴哈马",
        country_code: "BS",
        phone_code: "+1242",
      },
      {
        id: 48,
        country_name: "巴西",
        country_code: "BR",
        phone_code: "+55",
      },
      {
        id: 49,
        country_name: "塞舌尔",
        country_code: "SC",
        phone_code: "+248",
      },
      {
        id: 50,
        country_name: "所罗门群岛",
        country_code: "SB",
        phone_code: "+677",
      },
      {
        id: 51,
        country_name: "博内尔岛，圣尤斯特歇斯和萨巴",
        country_code: "BQ",
        phone_code: "+599",
      },
      {
        id: 52,
        country_name: "沙特阿拉伯",
        country_code: "SA",
        phone_code: "+966",
      },
      {
        id: 53,
        country_name: "玻利维亚",
        country_code: "BO",
        phone_code: "+591",
      },
      {
        id: 54,
        country_name: "文莱",
        country_code: "BN",
        phone_code: "+673",
      },
      {
        id: 55,
        country_name: "百慕大群岛",
        country_code: "BM",
        phone_code: "+1441",
      },
      {
        id: 56,
        country_name: "贝宁",
        country_code: "BJ",
        phone_code: "+229",
      },
      {
        id: 57,
        country_name: "布隆迪",
        country_code: "BI",
        phone_code: "+257",
      },
      {
        id: 58,
        country_name: "巴林",
        country_code: "BH",
        phone_code: "+973",
      },
      {
        id: 59,
        country_name: "保加利亚",
        country_code: "BG",
        phone_code: "+359",
      },
      {
        id: 60,
        country_name: "卢旺达",
        country_code: "RW",
        phone_code: "+250",
      },
      {
        id: 61,
        country_name: "布基纳法索",
        country_code: "BF",
        phone_code: "+226",
      },
      {
        id: 62,
        country_name: "东帝汶",
        country_code: "TL",
        phone_code: "+670",
      },
      {
        id: 63,
        country_name: "捷克",
        country_code: "CZ",
        phone_code: "+420",
      },
      {
        id: 64,
        country_name: "塔吉克斯坦",
        country_code: "TJ",
        phone_code: "+992",
      },
      {
        id: 65,
        country_name: "塞浦路斯",
        country_code: "CY",
        phone_code: "+357",
      },
      {
        id: 66,
        country_name: "泰国",
        country_code: "TH",
        phone_code: "+66",
      },
      {
        id: 67,
        country_name: "库拉索",
        country_code: "CW",
        phone_code: "+599",
      },
      {
        id: 68,
        country_name: "多哥",
        country_code: "TG",
        phone_code: "+228",
      },
      {
        id: 69,
        country_name: "开普",
        country_code: "CV",
        phone_code: "+238",
      },
      {
        id: 70,
        country_name: "古巴",
        country_code: "CU",
        phone_code: "+53",
      },
      {
        id: 71,
        country_name: "乍得",
        country_code: "TD",
        phone_code: "+235",
      },
      {
        id: 72,
        country_name: "哥斯达黎加",
        country_code: "CR",
        phone_code: "+506",
      },
      {
        id: 73,
        country_name: "特克斯和凯科斯群岛",
        country_code: "TC",
        phone_code: "+1649",
      },
      {
        id: 74,
        country_name: "哥伦比亚",
        country_code: "CO",
        phone_code: "+57",
      },
      {
        id: 75,
        country_name: "喀麦隆",
        country_code: "CM",
        phone_code: "+237",
      },
      {
        id: 76,
        country_name: "智利",
        country_code: "CL",
        phone_code: "+56",
      },
      {
        id: 77,
        country_name: "库克群岛",
        country_code: "CK",
        phone_code: "+682",
      },
      {
        id: 78,
        country_name: "斯威士兰",
        country_code: "SZ",
        phone_code: "+268",
      },
      {
        id: 79,
        country_name: "叙利亚",
        country_code: "SY",
        phone_code: "+963",
      },
      {
        id: 80,
        country_name: "科特迪瓦",
        country_code: "CI",
        phone_code: "+225",
      },
      {
        id: 81,
        country_name: "瑞士",
        country_code: "CH",
        phone_code: "+41",
      },
      {
        id: 82,
        country_name: "圣马丁岛（荷兰部分）",
        country_code: "SX",
        phone_code: "+1721",
      },
      {
        id: 83,
        country_name: "刚果共和国",
        country_code: "CG",
        phone_code: "+242",
      },
      {
        id: 84,
        country_name: "萨尔瓦多",
        country_code: "SV",
        phone_code: "+503",
      },
      {
        id: 85,
        country_name: "中非共和国",
        country_code: "CF",
        phone_code: "+236",
      },
      {
        id: 86,
        country_name: "圣多美和普林西比",
        country_code: "ST",
        phone_code: "+239",
      },
      {
        id: 87,
        country_name: "南苏丹",
        country_code: "SS",
        phone_code: "+211",
      },
      {
        id: 88,
        country_name: "苏里南",
        country_code: "SR",
        phone_code: "+597",
      },
      {
        id: 89,
        country_name: "越南",
        country_code: "VN",
        phone_code: "+84",
      },
      {
        id: 90,
        country_name: "美属维尔京群岛",
        country_code: "VI",
        phone_code: "+1284",
      },
      {
        id: 91,
        country_name: "英属处女群岛",
        country_code: "VG",
        phone_code: "+1340",
      },
      {
        id: 92,
        country_name: "委内瑞拉",
        country_code: "VE",
        phone_code: "+58",
      },
      {
        id: 93,
        country_name: "埃塞俄比亚",
        country_code: "ET",
        phone_code: "+251",
      },
      {
        id: 94,
        country_name: "西班牙",
        country_code: "ES",
        phone_code: "+34",
      },
      {
        id: 95,
        country_name: "圣文森特和格林纳丁斯",
        country_code: "VC",
        phone_code: "+1784",
      },
      {
        id: 96,
        country_name: "乌兹别克斯坦",
        country_code: "UZ",
        phone_code: "+998",
      },
      {
        id: 97,
        country_name: "乌拉圭",
        country_code: "UY",
        phone_code: "+598",
      },
      {
        id: 98,
        country_name: "埃及",
        country_code: "EG",
        phone_code: "+20",
      },
      {
        id: 99,
        country_name: "爱沙尼亚",
        country_code: "EE",
        phone_code: "+372",
      },
      {
        id: 100,
        country_name: "厄瓜多尔",
        country_code: "EC",
        phone_code: "+593",
      },
      {
        id: 101,
        country_name: "阿尔及利亚",
        country_code: "DZ",
        phone_code: "+213",
      },
      {
        id: 102,
        country_name: "乌干达",
        country_code: "UG",
        phone_code: "+256",
      },
      {
        id: 103,
        country_name: "乌克兰",
        country_code: "UA",
        phone_code: "+380",
      },
      {
        id: 104,
        country_name: "多明尼加共和国",
        country_code: "DO",
        phone_code: "+1809",
      },
      {
        id: 105,
        country_name: "多米尼加",
        country_code: "DM",
        phone_code: "+1767",
      },
      {
        id: 106,
        country_name: "丹麦",
        country_code: "DK",
        phone_code: "+45",
      },
      {
        id: 107,
        country_name: "坦桑尼亚",
        country_code: "TZ",
        phone_code: "+255",
      },
      {
        id: 108,
        country_name: "吉布提",
        country_code: "DJ",
        phone_code: "+253",
      },
      {
        id: 109,
        country_name: "中国台湾",
        country_code: "TW",
        phone_code: "+886",
      },
      {
        id: 110,
        country_name: "德国",
        country_code: "DE",
        phone_code: "+49",
      },
      {
        id: 111,
        country_name: "特立尼达和多巴哥",
        country_code: "TT",
        phone_code: "+1868",
      },
      {
        id: 112,
        country_name: "土耳其",
        country_code: "TR",
        phone_code: "+90",
      },
      {
        id: 113,
        country_name: "汤加",
        country_code: "TO",
        phone_code: "+676",
      },
      {
        id: 114,
        country_name: "突尼斯",
        country_code: "TN",
        phone_code: "+216",
      },
      {
        id: 115,
        country_name: "土库曼斯坦",
        country_code: "TM",
        phone_code: "+993",
      },
      {
        id: 116,
        country_name: "尼日尔",
        country_code: "NE",
        phone_code: "+227",
      },
      {
        id: 117,
        country_name: "新喀里多尼亚",
        country_code: "NC",
        phone_code: "+687",
      },
      {
        id: 118,
        country_name: "纳米比亚",
        country_code: "NA",
        phone_code: "+264",
      },
      {
        id: 119,
        country_name: "莫桑比克",
        country_code: "MZ",
        phone_code: "+258",
      },
      {
        id: 120,
        country_name: "马来西亚",
        country_code: "MY",
        phone_code: "+60",
      },
      {
        id: 121,
        country_name: "墨西哥",
        country_code: "MX",
        phone_code: "+52",
      },
      {
        id: 122,
        country_name: "马拉维",
        country_code: "MW",
        phone_code: "+265",
      },
      {
        id: 123,
        country_name: "马尔代夫",
        country_code: "MV",
        phone_code: "+960",
      },
      {
        id: 124,
        country_name: "毛里求斯",
        country_code: "MU",
        phone_code: "+230",
      },
      {
        id: 125,
        country_name: "马耳他",
        country_code: "MT",
        phone_code: "+356",
      },
      {
        id: 126,
        country_name: "蒙特塞拉特岛",
        country_code: "MS",
        phone_code: "+1664",
      },
      {
        id: 127,
        country_name: "毛里塔尼亚",
        country_code: "MR",
        phone_code: "+222",
      },
      {
        id: 128,
        country_name: "马提尼克",
        country_code: "MQ",
        phone_code: "+596",
      },
      {
        id: 129,
        country_name: "马里亚那群岛",
        country_code: "MP",
        phone_code: "+1670",
      },
      {
        id: 130,
        country_name: "中国澳门",
        country_code: "MO",
        phone_code: "+853",
      },
      {
        id: 131,
        country_name: "蒙古",
        country_code: "MN",
        phone_code: "+976",
      },
      {
        id: 132,
        country_name: "缅甸",
        country_code: "MM",
        phone_code: "+95",
      },
      {
        id: 133,
        country_name: "马里",
        country_code: "ML",
        phone_code: "+223",
      },
      {
        id: 134,
        country_name: "马其顿",
        country_code: "MK",
        phone_code: "+389",
      },
      {
        id: 135,
        country_name: "马达加斯加",
        country_code: "MG",
        phone_code: "+261",
      },
      {
        id: 136,
        country_name: "黑山",
        country_code: "ME",
        phone_code: "+382",
      },
      {
        id: 137,
        country_name: "摩尔多瓦",
        country_code: "MD",
        phone_code: "+373",
      },
      {
        id: 138,
        country_name: "摩纳哥",
        country_code: "MC",
        phone_code: "+377",
      },
      {
        id: 139,
        country_name: "摩洛哥",
        country_code: "MA",
        phone_code: "+212",
      },
      {
        id: 140,
        country_name: "利比亚",
        country_code: "LY",
        phone_code: "+218",
      },
      {
        id: 141,
        country_name: "拉脱维亚",
        country_code: "LV",
        phone_code: "+371",
      },
      {
        id: 142,
        country_name: "卢森堡",
        country_code: "LU",
        phone_code: "+352",
      },
      {
        id: 143,
        country_name: "立陶宛",
        country_code: "LT",
        phone_code: "+370",
      },
      {
        id: 144,
        country_name: "莱索托",
        country_code: "LS",
        phone_code: "+266",
      },
      {
        id: 145,
        country_name: "利比里亚",
        country_code: "LR",
        phone_code: "+231",
      },
      {
        id: 146,
        country_name: "斯里兰卡",
        country_code: "LK",
        phone_code: "+94",
      },
      {
        id: 147,
        country_name: "列支敦士登",
        country_code: "LI",
        phone_code: "+423",
      },
      {
        id: 148,
        country_name: "圣露西亚",
        country_code: "LC",
        phone_code: "+1758",
      },
      {
        id: 149,
        country_name: "黎巴嫩",
        country_code: "LB",
        phone_code: "+961",
      },
      {
        id: 150,
        country_name: "老挝",
        country_code: "LA",
        phone_code: "+856",
      },
      {
        id: 151,
        country_name: "哈萨克斯坦",
        country_code: "KZ",
        phone_code: "+7",
      },
      {
        id: 152,
        country_name: "开曼群岛",
        country_code: "KY",
        phone_code: "+1345",
      },
      {
        id: 153,
        country_name: "科威特",
        country_code: "KW",
        phone_code: "+965",
      },
      {
        id: 154,
        country_name: "韩国",
        country_code: "KR",
        phone_code: "+82",
      },
      {
        id: 155,
        country_name: "圣基茨和尼维斯",
        country_code: "KN",
        phone_code: "+1869",
      },
      {
        id: 156,
        country_name: "科摩罗",
        country_code: "KM",
        phone_code: "+269",
      },
      {
        id: 157,
        country_name: "基里巴斯",
        country_code: "KI",
        phone_code: "+686",
      },
      {
        id: 158,
        country_name: "柬埔寨",
        country_code: "KH",
        phone_code: "+855",
      },
      {
        id: 159,
        country_name: "吉尔吉斯斯坦",
        country_code: "KG",
        phone_code: "+996",
      },
      {
        id: 160,
        country_name: "肯尼亚",
        country_code: "KE",
        phone_code: "+254",
      },
      {
        id: 161,
        country_name: "日本",
        country_code: "JP",
        phone_code: "+81",
      },
      {
        id: 162,
        country_name: "约旦",
        country_code: "JO",
        phone_code: "+962",
      },
      {
        id: 163,
        country_name: "牙买加",
        country_code: "JM",
        phone_code: "+1876",
      },
      {
        id: 164,
        country_name: "津巴布韦",
        country_code: "ZW",
        phone_code: "+263",
      },
      {
        id: 165,
        country_name: "赞比亚",
        country_code: "ZM",
        phone_code: "+260",
      },
      {
        id: 166,
        country_name: "意大利",
        country_code: "IT",
        phone_code: "+39",
      },
      {
        id: 167,
        country_name: "冰岛",
        country_code: "IS",
        phone_code: "+354",
      },
      {
        id: 168,
        country_name: "伊朗",
        country_code: "IR",
        phone_code: "+98",
      },
      {
        id: 169,
        country_name: "伊拉克",
        country_code: "IQ",
        phone_code: "+964",
      },
      {
        id: 170,
        country_name: "南非",
        country_code: "ZA",
        phone_code: "+27",
      },
      {
        id: 171,
        country_name: "印度",
        country_code: "IN",
        phone_code: "+91",
      },
      {
        id: 172,
        country_name: "以色列",
        country_code: "IL",
        phone_code: "+972",
      },
      {
        id: 173,
        country_name: "爱尔兰",
        country_code: "IE",
        phone_code: "+353",
      },
      {
        id: 174,
        country_name: "印度尼西亚",
        country_code: "ID",
        phone_code: "+62",
      },
      {
        id: 175,
        country_name: "马约特",
        country_code: "YT",
        phone_code: "+269",
      },
      {
        id: 176,
        country_name: "匈牙利",
        country_code: "HU",
        phone_code: "+36",
      },
      {
        id: 177,
        country_name: "也门",
        country_code: "YE",
        phone_code: "+967",
      },
      {
        id: 178,
        country_name: "海地",
        country_code: "HT",
        phone_code: "+509",
      },
      {
        id: 179,
        country_name: "克罗地亚",
        country_code: "HR",
        phone_code: "+385",
      },
      {
        id: 180,
        country_name: "洪都拉斯",
        country_code: "HN",
        phone_code: "+504",
      },
      {
        id: 181,
        country_name: "中国香港",
        country_code: "HK",
        phone_code: "+852",
      },
      {
        id: 182,
        country_name: "圭亚那",
        country_code: "GY",
        phone_code: "+592",
      },
      {
        id: 183,
        country_name: "几内亚比绍共和国",
        country_code: "GW",
        phone_code: "+245",
      },
      {
        id: 184,
        country_name: "关岛",
        country_code: "GU",
        phone_code: "+1671",
      },
      {
        id: 185,
        country_name: "瓜地马拉",
        country_code: "GT",
        phone_code: "+502",
      },
      {
        id: 186,
        country_name: "希腊",
        country_code: "GR",
        phone_code: "+30",
      },
      {
        id: 187,
        country_name: "赤道几内亚",
        country_code: "GQ",
        phone_code: "+240",
      },
      {
        id: 188,
        country_name: "瓜德罗普岛",
        country_code: "GP",
        phone_code: "+590",
      },
      {
        id: 189,
        country_name: "几内亚",
        country_code: "GN",
        phone_code: "+224",
      },
      {
        id: 190,
        country_name: "冈比亚",
        country_code: "GM",
        phone_code: "+220",
      },
      {
        id: 191,
        country_name: "格陵兰岛",
        country_code: "GL",
        phone_code: "+299",
      },
      {
        id: 192,
        country_name: "直布罗陀",
        country_code: "GI",
        phone_code: "+350",
      },
      {
        id: 193,
        country_name: "加纳",
        country_code: "GH",
        phone_code: "+233",
      },
      {
        id: 194,
        country_name: "法属圭亚那",
        country_code: "GF",
        phone_code: "+594",
      },
      {
        id: 195,
        country_name: "格鲁吉亚",
        country_code: "GE",
        phone_code: "+995",
      },
      {
        id: 196,
        country_name: "格林纳达",
        country_code: "GD",
        phone_code: "+1473",
      },
      {
        id: 197,
        country_name: "萨摩亚",
        country_code: "WS",
        phone_code: "+685",
      },
      {
        id: 198,
        country_name: "英国",
        country_code: "GB",
        phone_code: "+44",
      },
      {
        id: 199,
        country_name: "加蓬",
        country_code: "GA",
        phone_code: "+241",
      },
      {
        id: 200,
        country_name: "法国",
        country_code: "FR",
        phone_code: "+33",
      },
      {
        id: 201,
        country_name: "法罗群岛",
        country_code: "FO",
        phone_code: "+298",
      },
      {
        id: 202,
        country_name: "斐济",
        country_code: "FJ",
        phone_code: "+679",
      },
      {
        id: 203,
        country_name: "芬兰",
        country_code: "FI",
        phone_code: "+358",
      },
      {
        id: 204,
        country_name: "瓦努阿图",
        country_code: "VU",
        phone_code: "+678",
      },
      {
        id: 205,
        country_name: "圣彼埃尔和密克隆岛",
        country_code: "PM",
        phone_code: "+508",
      },
      {
        id: 206,
        country_name: "波兰",
        country_code: "PL",
        phone_code: "+48",
      },
      {
        id: 207,
        country_name: "巴基斯坦",
        country_code: "PK",
        phone_code: "+92",
      },
      {
        id: 208,
        country_name: "菲律宾",
        country_code: "PH",
        phone_code: "+63",
      },
      {
        id: 209,
        country_name: "巴布亚新几内亚",
        country_code: "PG",
        phone_code: "+675",
      },
      {
        id: 210,
        country_name: "法属波利尼 西亚",
        country_code: "PF",
        phone_code: "+689",
      },
      {
        id: 211,
        country_name: "秘鲁",
        country_code: "PE",
        phone_code: "+51",
      },
      {
        id: 212,
        country_name: "巴拿马",
        country_code: "PA",
        phone_code: "+507",
      },
      {
        id: 213,
        country_name: "阿曼",
        country_code: "OM",
        phone_code: "+968",
      },
      {
        id: 214,
        country_name: "新西兰",
        country_code: "NZ",
        phone_code: "+64",
      },
      {
        id: 215,
        country_name: "尼泊尔",
        country_code: "NP",
        phone_code: "+977",
      },
      {
        id: 216,
        country_name: "挪威",
        country_code: "NO",
        phone_code: "+47",
      },
      {
        id: 217,
        country_name: "荷兰",
        country_code: "NL",
        phone_code: "+31",
      },
      {
        id: 218,
        country_name: "尼加拉瓜",
        country_code: "NI",
        phone_code: "+505",
      },
      {
        id: 219,
        country_name: "中国大陆",
        country_code: "CN",
        phone_code: "+86",
      },
    ],
    zh_TW: [
      {
        id: 1,
        country_name: "尼日利亞",
        country_code: "NG",
        phone_code: "+234",
      },
      {
        id: 2,
        country_name: "加拿大",
        country_code: "CA",
        phone_code: "+1",
      },
      {
        id: 3,
        country_name: "剛果民主共和國",
        country_code: "CD",
        phone_code: "+243",
      },
      {
        id: 4,
        country_name: "俄羅斯",
        country_code: "RU",
        phone_code: "+7",
      },
      {
        id: 5,
        country_name: "美國",
        country_code: "US",
        phone_code: "+1",
      },
      {
        id: 6,
        country_name: "比利時",
        country_code: "BE",
        phone_code: "+32",
      },
      {
        id: 7,
        country_name: "孟加拉國",
        country_code: "BD",
        phone_code: "+880",
      },
      {
        id: 8,
        country_name: "塞爾維亞",
        country_code: "RS",
        phone_code: "+381",
      },
      {
        id: 9,
        country_name: "巴巴多斯",
        country_code: "BB",
        phone_code: "+1246",
      },
      {
        id: 10,
        country_name: "波斯尼亞和黑塞哥維那",
        country_code: "BA",
        phone_code: "+387",
      },
      {
        id: 11,
        country_name: "羅馬尼亞",
        country_code: "RO",
        phone_code: "+40",
      },
      {
        id: 12,
        country_name: "阿塞拜疆",
        country_code: "AZ",
        phone_code: "+994",
      },
      {
        id: 13,
        country_name: "阿魯巴",
        country_code: "AW",
        phone_code: "+297",
      },
      {
        id: 14,
        country_name: "澳大利亞",
        country_code: "AU",
        phone_code: "+61",
      },
      {
        id: 15,
        country_name: "奧地利",
        country_code: "AT",
        phone_code: "+43",
      },
      {
        id: 16,
        country_name: "留尼汪",
        country_code: "RE",
        phone_code: "+262",
      },
      {
        id: 17,
        country_name: "美屬薩摩亞",
        country_code: "AS",
        phone_code: "+1684",
      },
      {
        id: 18,
        country_name: "阿根廷",
        country_code: "AR",
        phone_code: "+54",
      },
      {
        id: 19,
        country_name: "安哥拉",
        country_code: "AO",
        phone_code: "+244",
      },
      {
        id: 20,
        country_name: "亞美尼亞",
        country_code: "AM",
        phone_code: "+374",
      },
      {
        id: 21,
        country_name: "阿爾巴尼亞",
        country_code: "AL",
        phone_code: "+355",
      },
      {
        id: 22,
        country_name: "安圭拉",
        country_code: "AI",
        phone_code: "+1264",
      },
      {
        id: 23,
        country_name: "安提瓜 和巴布達",
        country_code: "AG",
        phone_code: "+1268",
      },
      {
        id: 24,
        country_name: "阿富汗",
        country_code: "AF",
        phone_code: "+93",
      },
      {
        id: 25,
        country_name: "阿拉伯聯合酋長國",
        country_code: "AE",
        phone_code: "+971",
      },
      {
        id: 26,
        country_name: "安道爾",
        country_code: "AD",
        phone_code: "+376",
      },
      {
        id: 27,
        country_name: "阿森松島",
        country_code: "AC",
        phone_code: "+247",
      },
      {
        id: 28,
        country_name: "卡塔爾",
        country_code: "QA",
        phone_code: "+974",
      },
      {
        id: 29,
        country_name: "巴拉圭",
        country_code: "PY",
        phone_code: "+595",
      },
      {
        id: 30,
        country_name: "帕勞",
        country_code: "PW",
        phone_code: "+680",
      },
      {
        id: 31,
        country_name: "葡萄牙",
        country_code: "PT",
        phone_code: "+351",
      },
      {
        id: 32,
        country_name: "巴勒斯坦領土",
        country_code: "PS",
        phone_code: "+970",
      },
      {
        id: 33,
        country_name: "波多黎各",
        country_code: "PR",
        phone_code: "+1787",
      },
      {
        id: 34,
        country_name: "索馬里",
        country_code: "SO",
        phone_code: "+252",
      },
      {
        id: 35,
        country_name: "塞內加爾",
        country_code: "SN",
        phone_code: "+221",
      },
      {
        id: 36,
        country_name: "聖馬力諾",
        country_code: "SM",
        phone_code: "+378",
      },
      {
        id: 37,
        country_name: "塞拉利昂",
        country_code: "SL",
        phone_code: "+232",
      },
      {
        id: 38,
        country_name: "斯洛伐克",
        country_code: "SK",
        phone_code: "+421",
      },
      {
        id: 39,
        country_name: "伯利茲",
        country_code: "BZ",
        phone_code: "+501",
      },
      {
        id: 40,
        country_name: "白俄羅斯",
        country_code: "BY",
        phone_code: "+375",
      },
      {
        id: 41,
        country_name: "斯洛文尼亞",
        country_code: "SI",
        phone_code: "+386",
      },
      {
        id: 42,
        country_name: "博茨瓦納",
        country_code: "BW",
        phone_code: "+267",
      },
      {
        id: 43,
        country_name: "新加坡",
        country_code: "SG",
        phone_code: "+65",
      },
      {
        id: 44,
        country_name: "瑞典",
        country_code: "SE",
        phone_code: "+46",
      },
      {
        id: 45,
        country_name: "不丹",
        country_code: "BT",
        phone_code: "+975",
      },
      {
        id: 46,
        country_name: "蘇丹",
        country_code: "SD",
        phone_code: "+249",
      },
      {
        id: 47,
        country_name: "巴哈馬",
        country_code: "BS",
        phone_code: "+1242",
      },
      {
        id: 48,
        country_name: "巴西",
        country_code: "BR",
        phone_code: "+55",
      },
      {
        id: 49,
        country_name: "塞舌爾",
        country_code: "SC",
        phone_code: "+248",
      },
      {
        id: 50,
        country_name: "所羅門群島",
        country_code: "SB",
        phone_code: "+677",
      },
      {
        id: 51,
        country_name: "博內爾島，聖尤斯特歇斯和薩巴",
        country_code: "BQ",
        phone_code: "+599",
      },
      {
        id: 52,
        country_name: "沙特阿拉伯",
        country_code: "SA",
        phone_code: "+966",
      },
      {
        id: 53,
        country_name: "玻利維亞",
        country_code: "BO",
        phone_code: "+591",
      },
      {
        id: 54,
        country_name: "文萊",
        country_code: "BN",
        phone_code: "+673",
      },
      {
        id: 55,
        country_name: "百慕大群島",
        country_code: "BM",
        phone_code: "+1441",
      },
      {
        id: 56,
        country_name: "貝寧",
        country_code: "BJ",
        phone_code: "+229",
      },
      {
        id: 57,
        country_name: "布隆迪",
        country_code: "BI",
        phone_code: "+257",
      },
      {
        id: 58,
        country_name: "巴林",
        country_code: "BH",
        phone_code: "+973",
      },
      {
        id: 59,
        country_name: "保加利亞",
        country_code: "BG",
        phone_code: "+359",
      },
      {
        id: 60,
        country_name: "盧旺達",
        country_code: "RW",
        phone_code: "+250",
      },
      {
        id: 61,
        country_name: "布基納法索",
        country_code: "BF",
        phone_code: "+226",
      },
      {
        id: 62,
        country_name: "東帝汶",
        country_code: "TL",
        phone_code: "+670",
      },
      {
        id: 63,
        country_name: "捷克",
        country_code: "CZ",
        phone_code: "+420",
      },
      {
        id: 64,
        country_name: "塔吉克斯坦",
        country_code: "TJ",
        phone_code: "+992",
      },
      {
        id: 65,
        country_name: "塞浦路斯",
        country_code: "CY",
        phone_code: "+357",
      },
      {
        id: 66,
        country_name: "泰國",
        country_code: "TH",
        phone_code: "+66",
      },
      {
        id: 67,
        country_name: "庫拉索",
        country_code: "CW",
        phone_code: "+599",
      },
      {
        id: 68,
        country_name: "多哥",
        country_code: "TG",
        phone_code: "+228",
      },
      {
        id: 69,
        country_name: "開普",
        country_code: "CV",
        phone_code: "+238",
      },
      {
        id: 70,
        country_name: "古巴",
        country_code: "CU",
        phone_code: "+53",
      },
      {
        id: 71,
        country_name: "乍得",
        country_code: "TD",
        phone_code: "+235",
      },
      {
        id: 72,
        country_name: "哥斯達黎加",
        country_code: "CR",
        phone_code: "+506",
      },
      {
        id: 73,
        country_name: "特克斯和凱科斯群島",
        country_code: "TC",
        phone_code: "+1649",
      },
      {
        id: 74,
        country_name: "哥倫比亞",
        country_code: "CO",
        phone_code: "+57",
      },
      {
        id: 75,
        country_name: "喀麥隆",
        country_code: "CM",
        phone_code: "+237",
      },
      {
        id: 76,
        country_name: "智利",
        country_code: "CL",
        phone_code: "+56",
      },
      {
        id: 77,
        country_name: "庫克群島",
        country_code: "CK",
        phone_code: "+682",
      },
      {
        id: 78,
        country_name: "斯威士蘭",
        country_code: "SZ",
        phone_code: "+268",
      },
      {
        id: 79,
        country_name: "敘利亞",
        country_code: "SY",
        phone_code: "+963",
      },
      {
        id: 80,
        country_name: "科特迪瓦",
        country_code: "CI",
        phone_code: "+225",
      },
      {
        id: 81,
        country_name: "瑞士",
        country_code: "CH",
        phone_code: "+41",
      },
      {
        id: 82,
        country_name: "聖馬丁島（荷蘭部分）",
        country_code: "SX",
        phone_code: "+1721",
      },
      {
        id: 83,
        country_name: "剛果共和國",
        country_code: "CG",
        phone_code: "+242",
      },
      {
        id: 84,
        country_name: "薩爾瓦多",
        country_code: "SV",
        phone_code: "+503",
      },
      {
        id: 85,
        country_name: "中非共和國",
        country_code: "CF",
        phone_code: "+236",
      },
      {
        id: 86,
        country_name: "聖多美和普林西比",
        country_code: "ST",
        phone_code: "+239",
      },
      {
        id: 87,
        country_name: "南蘇丹",
        country_code: "SS",
        phone_code: "+211",
      },
      {
        id: 88,
        country_name: "蘇里南",
        country_code: "SR",
        phone_code: "+597",
      },
      {
        id: 89,
        country_name: "越南",
        country_code: "VN",
        phone_code: "+84",
      },
      {
        id: 90,
        country_name: "美屬維爾京群島",
        country_code: "VI",
        phone_code: "+1284",
      },
      {
        id: 91,
        country_name: "英屬處女群島",
        country_code: "VG",
        phone_code: "+1340",
      },
      {
        id: 92,
        country_name: "委內瑞拉",
        country_code: "VE",
        phone_code: "+58",
      },
      {
        id: 93,
        country_name: "埃塞俄比亞",
        country_code: "ET",
        phone_code: "+251",
      },
      {
        id: 94,
        country_name: "西班牙",
        country_code: "ES",
        phone_code: "+34",
      },
      {
        id: 95,
        country_name: "聖文森特和格林納丁斯",
        country_code: "VC",
        phone_code: "+1784",
      },
      {
        id: 96,
        country_name: "烏茲別克斯坦",
        country_code: "UZ",
        phone_code: "+998",
      },
      {
        id: 97,
        country_name: "烏拉圭",
        country_code: "UY",
        phone_code: "+598",
      },
      {
        id: 98,
        country_name: "埃及",
        country_code: "EG",
        phone_code: "+20",
      },
      {
        id: 99,
        country_name: "愛沙尼亞",
        country_code: "EE",
        phone_code: "+372",
      },
      {
        id: 100,
        country_name: "厄瓜多爾",
        country_code: "EC",
        phone_code: "+593",
      },
      {
        id: 101,
        country_name: "阿爾及利亞",
        country_code: "DZ",
        phone_code: "+213",
      },
      {
        id: 102,
        country_name: "烏干達",
        country_code: "UG",
        phone_code: "+256",
      },
      {
        id: 103,
        country_name: "烏克蘭",
        country_code: "UA",
        phone_code: "+380",
      },
      {
        id: 104,
        country_name: "多明尼加共和國",
        country_code: "DO",
        phone_code: "+1809",
      },
      {
        id: 105,
        country_name: "多米尼加",
        country_code: "DM",
        phone_code: "+1767",
      },
      {
        id: 106,
        country_name: "丹麥",
        country_code: "DK",
        phone_code: "+45",
      },
      {
        id: 107,
        country_name: "坦桑尼亞",
        country_code: "TZ",
        phone_code: "+255",
      },
      {
        id: 108,
        country_name: "吉布提",
        country_code: "DJ",
        phone_code: "+253",
      },
      {
        id: 109,
        country_name: "中國台灣",
        country_code: "TW",
        phone_code: "+886",
      },
      {
        id: 110,
        country_name: "德國",
        country_code: "DE",
        phone_code: "+49",
      },
      {
        id: 111,
        country_name: "特立尼達和多巴哥",
        country_code: "TT",
        phone_code: "+1868",
      },
      {
        id: 112,
        country_name: "土耳其",
        country_code: "TR",
        phone_code: "+90",
      },
      {
        id: 113,
        country_name: "湯加",
        country_code: "TO",
        phone_code: "+676",
      },
      {
        id: 114,
        country_name: "突尼斯",
        country_code: "TN",
        phone_code: "+216",
      },
      {
        id: 115,
        country_name: "土庫曼斯坦",
        country_code: "TM",
        phone_code: "+993",
      },
      {
        id: 116,
        country_name: "尼日爾",
        country_code: "NE",
        phone_code: "+227",
      },
      {
        id: 117,
        country_name: "新喀裡多尼亞",
        country_code: "NC",
        phone_code: "+687",
      },
      {
        id: 118,
        country_name: "納米比亞",
        country_code: "NA",
        phone_code: "+264",
      },
      {
        id: 119,
        country_name: "莫桑比克",
        country_code: "MZ",
        phone_code: "+258",
      },
      {
        id: 120,
        country_name: "馬來西亞",
        country_code: "MY",
        phone_code: "+60",
      },
      {
        id: 121,
        country_name: "墨西哥",
        country_code: "MX",
        phone_code: "+52",
      },
      {
        id: 122,
        country_name: "馬拉維",
        country_code: "MW",
        phone_code: "+265",
      },
      {
        id: 123,
        country_name: "馬爾代夫",
        country_code: "MV",
        phone_code: "+960",
      },
      {
        id: 124,
        country_name: "毛里求斯",
        country_code: "MU",
        phone_code: "+230",
      },
      {
        id: 125,
        country_name: "馬耳他",
        country_code: "MT",
        phone_code: "+356",
      },
      {
        id: 126,
        country_name: "蒙特塞拉特島",
        country_code: "MS",
        phone_code: "+1664",
      },
      {
        id: 127,
        country_name: "毛里塔尼亞",
        country_code: "MR",
        phone_code: "+222",
      },
      {
        id: 128,
        country_name: "馬提尼克",
        country_code: "MQ",
        phone_code: "+596",
      },
      {
        id: 129,
        country_name: "馬里亞那群島",
        country_code: "MP",
        phone_code: "+1670",
      },
      {
        id: 130,
        country_name: "中國澳門",
        country_code: "MO",
        phone_code: "+853",
      },
      {
        id: 131,
        country_name: "蒙古",
        country_code: "MN",
        phone_code: "+976",
      },
      {
        id: 132,
        country_name: "緬甸",
        country_code: "MM",
        phone_code: "+95",
      },
      {
        id: 133,
        country_name: "馬里",
        country_code: "ML",
        phone_code: "+223",
      },
      {
        id: 134,
        country_name: "馬其頓",
        country_code: "MK",
        phone_code: "+389",
      },
      {
        id: 135,
        country_name: "馬達加斯加",
        country_code: "MG",
        phone_code: "+261",
      },
      {
        id: 136,
        country_name: "黑山",
        country_code: "ME",
        phone_code: "+382",
      },
      {
        id: 137,
        country_name: "摩爾多瓦",
        country_code: "MD",
        phone_code: "+373",
      },
      {
        id: 138,
        country_name: "摩納哥",
        country_code: "MC",
        phone_code: "+377",
      },
      {
        id: 139,
        country_name: "摩洛哥",
        country_code: "MA",
        phone_code: "+212",
      },
      {
        id: 140,
        country_name: "利比亞",
        country_code: "LY",
        phone_code: "+218",
      },
      {
        id: 141,
        country_name: "拉脫維亞",
        country_code: "LV",
        phone_code: "+371",
      },
      {
        id: 142,
        country_name: "盧森堡",
        country_code: "LU",
        phone_code: "+352",
      },
      {
        id: 143,
        country_name: "立陶宛",
        country_code: "LT",
        phone_code: "+370",
      },
      {
        id: 144,
        country_name: "萊索托",
        country_code: "LS",
        phone_code: "+266",
      },
      {
        id: 145,
        country_name: "利比里亞",
        country_code: "LR",
        phone_code: "+231",
      },
      {
        id: 146,
        country_name: "斯里蘭卡",
        country_code: "LK",
        phone_code: "+94",
      },
      {
        id: 147,
        country_name: "列支敦士登",
        country_code: "LI",
        phone_code: "+423",
      },
      {
        id: 148,
        country_name: "聖露西亞",
        country_code: "LC",
        phone_code: "+1758",
      },
      {
        id: 149,
        country_name: "黎巴嫩",
        country_code: "LB",
        phone_code: "+961",
      },
      {
        id: 150,
        country_name: "老撾",
        country_code: "LA",
        phone_code: "+856",
      },
      {
        id: 151,
        country_name: "哈薩克斯坦",
        country_code: "KZ",
        phone_code: "+7",
      },
      {
        id: 152,
        country_name: "開曼群島",
        country_code: "KY",
        phone_code: "+1345",
      },
      {
        id: 153,
        country_name: "科威特",
        country_code: "KW",
        phone_code: "+965",
      },
      {
        id: 154,
        country_name: "韓國",
        country_code: "KR",
        phone_code: "+82",
      },
      {
        id: 155,
        country_name: "聖基茨和尼維斯",
        country_code: "KN",
        phone_code: "+1869",
      },
      {
        id: 156,
        country_name: "科摩羅",
        country_code: "KM",
        phone_code: "+269",
      },
      {
        id: 157,
        country_name: "基里巴斯",
        country_code: "KI",
        phone_code: "+686",
      },
      {
        id: 158,
        country_name: "柬埔寨",
        country_code: "KH",
        phone_code: "+855",
      },
      {
        id: 159,
        country_name: "吉爾吉斯斯坦",
        country_code: "KG",
        phone_code: "+996",
      },
      {
        id: 160,
        country_name: "肯尼亞",
        country_code: "KE",
        phone_code: "+254",
      },
      {
        id: 161,
        country_name: "日本",
        country_code: "JP",
        phone_code: "+81",
      },
      {
        id: 162,
        country_name: "約旦",
        country_code: "JO",
        phone_code: "+962",
      },
      {
        id: 163,
        country_name: "牙買加",
        country_code: "JM",
        phone_code: "+1876",
      },
      {
        id: 164,
        country_name: "津巴布韋",
        country_code: "ZW",
        phone_code: "+263",
      },
      {
        id: 165,
        country_name: "贊比亞",
        country_code: "ZM",
        phone_code: "+260",
      },
      {
        id: 166,
        country_name: "意大利",
        country_code: "IT",
        phone_code: "+39",
      },
      {
        id: 167,
        country_name: "冰島",
        country_code: "IS",
        phone_code: "+354",
      },
      {
        id: 168,
        country_name: "伊朗",
        country_code: "IR",
        phone_code: "+98",
      },
      {
        id: 169,
        country_name: "伊拉克",
        country_code: "IQ",
        phone_code: "+964",
      },
      {
        id: 170,
        country_name: "南非",
        country_code: "ZA",
        phone_code: "+27",
      },
      {
        id: 171,
        country_name: "印度",
        country_code: "IN",
        phone_code: "+91",
      },
      {
        id: 172,
        country_name: "以色列",
        country_code: "IL",
        phone_code: "+972",
      },
      {
        id: 173,
        country_name: "愛爾蘭",
        country_code: "IE",
        phone_code: "+353",
      },
      {
        id: 174,
        country_name: "印度尼西亞",
        country_code: "ID",
        phone_code: "+62",
      },
      {
        id: 175,
        country_name: "馬約特",
        country_code: "YT",
        phone_code: "+269",
      },
      {
        id: 176,
        country_name: "匈牙利",
        country_code: "HU",
        phone_code: "+36",
      },
      {
        id: 177,
        country_name: "也門",
        country_code: "YE",
        phone_code: "+967",
      },
      {
        id: 178,
        country_name: "海地",
        country_code: "HT",
        phone_code: "+509",
      },
      {
        id: 179,
        country_name: "克羅地亞",
        country_code: "HR",
        phone_code: "+385",
      },
      {
        id: 180,
        country_name: "洪都拉斯",
        country_code: "HN",
        phone_code: "+504",
      },
      {
        id: 181,
        country_name: "中國香港",
        country_code: "HK",
        phone_code: "+852",
      },
      {
        id: 182,
        country_name: "圭亞那",
        country_code: "GY",
        phone_code: "+592",
      },
      {
        id: 183,
        country_name: "幾內亞比紹共和國",
        country_code: "GW",
        phone_code: "+245",
      },
      {
        id: 184,
        country_name: "關島",
        country_code: "GU",
        phone_code: "+1671",
      },
      {
        id: 185,
        country_name: "瓜地馬拉",
        country_code: "GT",
        phone_code: "+502",
      },
      {
        id: 186,
        country_name: "希臘",
        country_code: "GR",
        phone_code: "+30",
      },
      {
        id: 187,
        country_name: "赤道幾內亞",
        country_code: "GQ",
        phone_code: "+240",
      },
      {
        id: 188,
        country_name: "瓜德羅普島",
        country_code: "GP",
        phone_code: "+590",
      },
      {
        id: 189,
        country_name: "幾內亞",
        country_code: "GN",
        phone_code: "+224",
      },
      {
        id: 190,
        country_name: "岡比亞",
        country_code: "GM",
        phone_code: "+220",
      },
      {
        id: 191,
        country_name: "格陵蘭島",
        country_code: "GL",
        phone_code: "+299",
      },
      {
        id: 192,
        country_name: "直布羅陀",
        country_code: "GI",
        phone_code: "+350",
      },
      {
        id: 193,
        country_name: "加納",
        country_code: "GH",
        phone_code: "+233",
      },
      {
        id: 194,
        country_name: "法屬圭亞那",
        country_code: "GF",
        phone_code: "+594",
      },
      {
        id: 195,
        country_name: "格魯吉亞",
        country_code: "GE",
        phone_code: "+995",
      },
      {
        id: 196,
        country_name: "格林納達",
        country_code: "GD",
        phone_code: "+1473",
      },
      {
        id: 197,
        country_name: "薩摩亞",
        country_code: "WS",
        phone_code: "+685",
      },
      {
        id: 198,
        country_name: "英國",
        country_code: "GB",
        phone_code: "+44",
      },
      {
        id: 199,
        country_name: "加蓬",
        country_code: "GA",
        phone_code: "+241",
      },
      {
        id: 200,
        country_name: "法國",
        country_code: "FR",
        phone_code: "+33",
      },
      {
        id: 201,
        country_name: "法羅群島",
        country_code: "FO",
        phone_code: "+298",
      },
      {
        id: 202,
        country_name: "斐濟",
        country_code: "FJ",
        phone_code: "+679",
      },
      {
        id: 203,
        country_name: "芬蘭",
        country_code: "FI",
        phone_code: "+358",
      },
      {
        id: 204,
        country_name: "瓦努阿圖",
        country_code: "VU",
        phone_code: "+678",
      },
      {
        id: 205,
        country_name: "聖彼埃爾和密克隆島",
        country_code: "PM",
        phone_code: "+508",
      },
      {
        id: 206,
        country_name: "波蘭",
        country_code: "PL",
        phone_code: "+48",
      },
      {
        id: 207,
        country_name: "巴基斯坦",
        country_code: "PK",
        phone_code: "+92",
      },
      {
        id: 208,
        country_name: "菲律賓",
        country_code: "PH",
        phone_code: "+63",
      },
      {
        id: 209,
        country_name: "巴布亞新幾內亞",
        country_code: "PG",
        phone_code: "+675",
      },
      {
        id: 210,
        country_name: "法屬波利尼 西亞",
        country_code: "PF",
        phone_code: "+689",
      },
      {
        id: 211,
        country_name: "秘魯",
        country_code: "PE",
        phone_code: "+51",
      },
      {
        id: 212,
        country_name: "巴拿馬",
        country_code: "PA",
        phone_code: "+507",
      },
      {
        id: 213,
        country_name: "阿曼",
        country_code: "OM",
        phone_code: "+968",
      },
      {
        id: 214,
        country_name: "新西蘭",
        country_code: "NZ",
        phone_code: "+64",
      },
      {
        id: 215,
        country_name: "尼泊爾",
        country_code: "NP",
        phone_code: "+977",
      },
      {
        id: 216,
        country_name: "挪威",
        country_code: "NO",
        phone_code: "+47",
      },
      {
        id: 217,
        country_name: "荷蘭",
        country_code: "NL",
        phone_code: "+31",
      },
      {
        id: 218,
        country_name: "尼加拉瓜",
        country_code: "NI",
        phone_code: "+505",
      },
      {
        id: 219,
        country_name: "中國大陸",
        country_code: "CN",
        phone_code: "+86",
      },
    ],
    en_GB: [
      {
        id: 1,
        country_name: "Nigeria",
        country_code: "NG",
        phone_code: "+234",
      },
      {
        id: 2,
        country_name: "Canada",
        country_code: "CA",
        phone_code: "+1",
      },
      {
        id: 3,
        country_name: "Democratic Republic of Congo",
        country_code: "CD",
        phone_code: "+243",
      },
      {
        id: 4,
        country_name: "Russia",
        country_code: "RU",
        phone_code: "+7",
      },
      {
        id: 5,
        country_name: "U.S.",
        country_code: "US",
        phone_code: "+1",
      },
      {
        id: 6,
        country_name: "Belgium",
        country_code: "BE",
        phone_code: "+32",
      },
      {
        id: 7,
        country_name: "Bangladesh",
        country_code: "BD",
        phone_code: "+880",
      },
      {
        id: 8,
        country_name: "Serbia",
        country_code: "RS",
        phone_code: "+381",
      },
      {
        id: 9,
        country_name: "Barbados",
        country_code: "BB",
        phone_code: "+1246",
      },
      {
        id: 10,
        country_name: "Bosnia and Herzegovina",
        country_code: "BA",
        phone_code: "+387",
      },
      {
        id: 11,
        country_name: "Romania",
        country_code: "RO",
        phone_code: "+40",
      },
      {
        id: 12,
        country_name: "Azerbaijan",
        country_code: "AZ",
        phone_code: "+994",
      },
      {
        id: 13,
        country_name: "Aruba",
        country_code: "AW",
        phone_code: "+297",
      },
      {
        id: 14,
        country_name: "Australia",
        country_code: "AU",
        phone_code: "+61",
      },
      {
        id: 15,
        country_name: "Austria",
        country_code: "AT",
        phone_code: "+43",
      },
      {
        id: 16,
        country_name: "Reunion",
        country_code: "RE",
        phone_code: "+262",
      },
      {
        id: 17,
        country_name: "American Samoa",
        country_code: "AS",
        phone_code: "+1684",
      },
      {
        id: 18,
        country_name: "Argentina",
        country_code: "AR",
        phone_code: "+54",
      },
      {
        id: 19,
        country_name: "Angola",
        country_code: "AO",
        phone_code: "+244",
      },
      {
        id: 20,
        country_name: "Armenia",
        country_code: "AM",
        phone_code: "+374",
      },
      {
        id: 21,
        country_name: "Albania",
        country_code: "AL",
        phone_code: "+355",
      },
      {
        id: 22,
        country_name: "Anguilla",
        country_code: "AI",
        phone_code: "+1264",
      },
      {
        id: 23,
        country_name: "Antigua and Barbuda",
        country_code: "AG",
        phone_code: "+1268",
      },
      {
        id: 24,
        country_name: "Afghanistan",
        country_code: "AF",
        phone_code: "+93",
      },
      {
        id: 25,
        country_name: "United Arab Emirates",
        country_code: "AE",
        phone_code: "+971",
      },
      {
        id: 26,
        country_name: "Andorra",
        country_code: "AD",
        phone_code: "+376",
      },
      {
        id: 27,
        country_name: "Ascension Island",
        country_code: "AC",
        phone_code: "+247",
      },
      {
        id: 28,
        country_name: "Qatar",
        country_code: "QA",
        phone_code: "+974",
      },
      {
        id: 29,
        country_name: "Paraguay",
        country_code: "PY",
        phone_code: "+595",
      },
      {
        id: 30,
        country_name: "Palau",
        country_code: "PW",
        phone_code: "+680",
      },
      {
        id: 31,
        country_name: "Portugal",
        country_code: "PT",
        phone_code: "+351",
      },
      {
        id: 32,
        country_name: "Palestinian Territories",
        country_code: "PS",
        phone_code: "+970",
      },
      {
        id: 33,
        country_name: "Puerto Rico",
        country_code: "PR",
        phone_code: "+1787",
      },
      {
        id: 34,
        country_name: "Somalia",
        country_code: "SO",
        phone_code: "+252",
      },
      {
        id: 35,
        country_name: "Senegal",
        country_code: "SN",
        phone_code: "+221",
      },
      {
        id: 36,
        country_name: "San Marino",
        country_code: "SM",
        phone_code: "+378",
      },
      {
        id: 37,
        country_name: "Sierra Leone",
        country_code: "SL",
        phone_code: "+232",
      },
      {
        id: 38,
        country_name: "Slovakia",
        country_code: "SK",
        phone_code: "+421",
      },
      {
        id: 39,
        country_name: "Belize",
        country_code: "BZ",
        phone_code: "+501",
      },
      {
        id: 40,
        country_name: "Belarus",
        country_code: "BY",
        phone_code: "+375",
      },
      {
        id: 41,
        country_name: "Slovenia",
        country_code: "SI",
        phone_code: "+386",
      },
      {
        id: 42,
        country_name: "Botswana",
        country_code: "BW",
        phone_code: "+267",
      },
      {
        id: 43,
        country_name: "Singapore",
        country_code: "SG",
        phone_code: "+65",
      },
      {
        id: 44,
        country_name: "Sweden",
        country_code: "SE",
        phone_code: "+46",
      },
      {
        id: 45,
        country_name: "Bhutan",
        country_code: "BT",
        phone_code: "+975",
      },
      {
        id: 46,
        country_name: "Sudan",
        country_code: "SD",
        phone_code: "+249",
      },
      {
        id: 47,
        country_name: "Bahamas",
        country_code: "BS",
        phone_code: "+1242",
      },
      {
        id: 48,
        country_name: "Brazil",
        country_code: "BR",
        phone_code: "+55",
      },
      {
        id: 49,
        country_name: "Seychelles",
        country_code: "SC",
        phone_code: "+248",
      },
      {
        id: 50,
        country_name: "Solomon Islands",
        country_code: "SB",
        phone_code: "+677",
      },
      {
        id: 51,
        country_name: "Bonaire, St Eustatius and Saba",
        country_code: "BQ",
        phone_code: "+599",
      },
      {
        id: 52,
        country_name: "Saudi Arabia",
        country_code: "SA",
        phone_code: "+966",
      },
      {
        id: 53,
        country_name: "Bolivia",
        country_code: "BO",
        phone_code: "+591",
      },
      {
        id: 54,
        country_name: "Brunei",
        country_code: "BN",
        phone_code: "+673",
      },
      {
        id: 55,
        country_name: "Bermuda",
        country_code: "BM",
        phone_code: "+1441",
      },
      {
        id: 56,
        country_name: "Benin",
        country_code: "BJ",
        phone_code: "+229",
      },
      {
        id: 57,
        country_name: "Burundi",
        country_code: "BI",
        phone_code: "+257",
      },
      {
        id: 58,
        country_name: "Bahrain",
        country_code: "BH",
        phone_code: "+973",
      },
      {
        id: 59,
        country_name: "Bulgaria",
        country_code: "BG",
        phone_code: "+359",
      },
      {
        id: 60,
        country_name: "Rwanda",
        country_code: "RW",
        phone_code: "+250",
      },
      {
        id: 61,
        country_name: "Burkina Faso",
        country_code: "BF",
        phone_code: "+226",
      },
      {
        id: 62,
        country_name: "East Timor",
        country_code: "TL",
        phone_code: "+670",
      },
      {
        id: 63,
        country_name: "Czech",
        country_code: "CZ",
        phone_code: "+420",
      },
      {
        id: 64,
        country_name: "Tajikistan",
        country_code: "TJ",
        phone_code: "+992",
      },
      {
        id: 65,
        country_name: "Cyprus",
        country_code: "CY",
        phone_code: "+357",
      },
      {
        id: 66,
        country_name: "Thailand",
        country_code: "TH",
        phone_code: "+66",
      },
      {
        id: 67,
        country_name: "Curacao",
        country_code: "CW",
        phone_code: "+599",
      },
      {
        id: 68,
        country_name: "togo",
        country_code: "TG",
        phone_code: "+228",
      },
      {
        id: 69,
        country_name: "Cape",
        country_code: "CV",
        phone_code: "+238",
      },
      {
        id: 70,
        country_name: "Cuba",
        country_code: "CU",
        phone_code: "+53",
      },
      {
        id: 71,
        country_name: "Chad",
        country_code: "TD",
        phone_code: "+235",
      },
      {
        id: 72,
        country_name: "Costa Rica",
        country_code: "CR",
        phone_code: "+506",
      },
      {
        id: 73,
        country_name: "Turks and Caicos Islands",
        country_code: "TC",
        phone_code: "+1649",
      },
      {
        id: 74,
        country_name: "Colombia",
        country_code: "CO",
        phone_code: "+57",
      },
      {
        id: 75,
        country_name: "Cameroon",
        country_code: "CM",
        phone_code: "+237",
      },
      {
        id: 76,
        country_name: "Chile",
        country_code: "CL",
        phone_code: "+56",
      },
      {
        id: 77,
        country_name: "Island",
        country_code: "CK",
        phone_code: "+682",
      },
      {
        id: 78,
        country_name: "Swaziland",
        country_code: "SZ",
        phone_code: "+268",
      },
      {
        id: 79,
        country_name: "Syria",
        country_code: "SY",
        phone_code: "+963",
      },
      {
        id: 80,
        country_name: "Côte d'Ivoire",
        country_code: "CI",
        phone_code: "+225",
      },
      {
        id: 81,
        country_name: "Switzerland",
        country_code: "CH",
        phone_code: "+41",
      },
      {
        id: 82,
        country_name: "St. Maarten (Dutch part)",
        country_code: "SX",
        phone_code: "+1721",
      },
      {
        id: 83,
        country_name: "the republic of Congo",
        country_code: "CG",
        phone_code: "+242",
      },
      {
        id: 84,
        country_name: "salvador",
        country_code: "SV",
        phone_code: "+503",
      },
      {
        id: 85,
        country_name: "Central African Republic",
        country_code: "CF",
        phone_code: "+236",
      },
      {
        id: 86,
        country_name: "Sao Tome and Principe",
        country_code: "ST",
        phone_code: "+239",
      },
      {
        id: 87,
        country_name: "South Sudan",
        country_code: "SS",
        phone_code: "+211",
      },
      {
        id: 88,
        country_name: "Suriname",
        country_code: "SR",
        phone_code: "+597",
      },
      {
        id: 89,
        country_name: "Vietnam",
        country_code: "VN",
        phone_code: "+84",
      },
      {
        id: 90,
        country_name: "United States Virgin Islands",
        country_code: "VI",
        phone_code: "+1284",
      },
      {
        id: 91,
        country_name: "British Virgin Islands",
        country_code: "VG",
        phone_code: "+1340",
      },
      {
        id: 92,
        country_name: "Venezuela",
        country_code: "VE",
        phone_code: "+58",
      },
      {
        id: 93,
        country_name: "Ethiopia",
        country_code: "ET",
        phone_code: "+251",
      },
      {
        id: 94,
        country_name: "Spain",
        country_code: "ES",
        phone_code: "+34",
      },
      {
        id: 95,
        country_name: "Saint Vincent and the Grenadines",
        country_code: "VC",
        phone_code: "+1784",
      },
      {
        id: 96,
        country_name: "Uzbekistan",
        country_code: "UZ",
        phone_code: "+998",
      },
      {
        id: 97,
        country_name: "Uruguay",
        country_code: "UY",
        phone_code: "+598",
      },
      {
        id: 98,
        country_name: "Egypt",
        country_code: "EG",
        phone_code: "+20",
      },
      {
        id: 99,
        country_name: "Estonia",
        country_code: "EE",
        phone_code: "+372",
      },
      {
        id: 100,
        country_name: "Ecuador",
        country_code: "EC",
        phone_code: "+593",
      },
      {
        id: 101,
        country_name: "Algeria",
        country_code: "DZ",
        phone_code: "+213",
      },
      {
        id: 102,
        country_name: "Uganda",
        country_code: "UG",
        phone_code: "+256",
      },
      {
        id: 103,
        country_name: "Ukraine",
        country_code: "UA",
        phone_code: "+380",
      },
      {
        id: 104,
        country_name: "Dominican Republic",
        country_code: "DO",
        phone_code: "+1809",
      },
      {
        id: 105,
        country_name: "Dominica",
        country_code: "DM",
        phone_code: "+1767",
      },
      {
        id: 106,
        country_name: "Denmark",
        country_code: "DK",
        phone_code: "+45",
      },
      {
        id: 107,
        country_name: "Tanzania",
        country_code: "TZ",
        phone_code: "+255",
      },
      {
        id: 108,
        country_name: "Djibouti",
        country_code: "DJ",
        phone_code: "+253",
      },
      {
        id: 109,
        country_name: "Taiwan, China",
        country_code: "TW",
        phone_code: "+886",
      },
      {
        id: 110,
        country_name: "Germany",
        country_code: "DE",
        phone_code: "+49",
      },
      {
        id: 111,
        country_name: "Trinidad and Tobago",
        country_code: "TT",
        phone_code: "+1868",
      },
      {
        id: 112,
        country_name: "Turkey",
        country_code: "TR",
        phone_code: "+90",
      },
      {
        id: 113,
        country_name: "Tonga",
        country_code: "TO",
        phone_code: "+676",
      },
      {
        id: 114,
        country_name: "Tunisia",
        country_code: "TN",
        phone_code: "+216",
      },
      {
        id: 115,
        country_name: "Turkmenistan",
        country_code: "TM",
        phone_code: "+993",
      },
      {
        id: 116,
        country_name: "Niger",
        country_code: "NE",
        phone_code: "+227",
      },
      {
        id: 117,
        country_name: "new caledonia",
        country_code: "NC",
        phone_code: "+687",
      },
      {
        id: 118,
        country_name: "Namibia",
        country_code: "NA",
        phone_code: "+264",
      },
      {
        id: 119,
        country_name: "Mozambique",
        country_code: "MZ",
        phone_code: "+258",
      },
      {
        id: 120,
        country_name: "Malaysia",
        country_code: "MY",
        phone_code: "+60",
      },
      {
        id: 121,
        country_name: "Mexico",
        country_code: "MX",
        phone_code: "+52",
      },
      {
        id: 122,
        country_name: "Malawi",
        country_code: "MW",
        phone_code: "+265",
      },
      {
        id: 123,
        country_name: "Maldives",
        country_code: "MV",
        phone_code: "+960",
      },
      {
        id: 124,
        country_name: "Mauritius",
        country_code: "MU",
        phone_code: "+230",
      },
      {
        id: 125,
        country_name: "Malta",
        country_code: "MT",
        phone_code: "+356",
      },
      {
        id: 126,
        country_name: "montserrat",
        country_code: "MS",
        phone_code: "+1664",
      },
      {
        id: 127,
        country_name: "Mauritania",
        country_code: "MR",
        phone_code: "+222",
      },
      {
        id: 128,
        country_name: "Martinique",
        country_code: "MQ",
        phone_code: "+596",
      },
      {
        id: 129,
        country_name: "Mariana Islands",
        country_code: "MP",
        phone_code: "+1670",
      },
      {
        id: 130,
        country_name: "Macao, China",
        country_code: "MO",
        phone_code: "+853",
      },
      {
        id: 131,
        country_name: "Mongolia",
        country_code: "MN",
        phone_code: "+976",
      },
      {
        id: 132,
        country_name: "Myanmar",
        country_code: "MM",
        phone_code: "+95",
      },
      {
        id: 133,
        country_name: "Mali",
        country_code: "ML",
        phone_code: "+223",
      },
      {
        id: 134,
        country_name: "Macedonia",
        country_code: "MK",
        phone_code: "+389",
      },
      {
        id: 135,
        country_name: "Madagascar",
        country_code: "MG",
        phone_code: "+261",
      },
      {
        id: 136,
        country_name: "Montenegro",
        country_code: "ME",
        phone_code: "+382",
      },
      {
        id: 137,
        country_name: "Moldova",
        country_code: "MD",
        phone_code: "+373",
      },
      {
        id: 138,
        country_name: "Monaco",
        country_code: "MC",
        phone_code: "+377",
      },
      {
        id: 139,
        country_name: "Morocco",
        country_code: "MA",
        phone_code: "+212",
      },
      {
        id: 140,
        country_name: "Libya",
        country_code: "LY",
        phone_code: "+218",
      },
      {
        id: 141,
        country_name: "Latvia",
        country_code: "LV",
        phone_code: "+371",
      },
      {
        id: 142,
        country_name: "Luxembourg",
        country_code: "LU",
        phone_code: "+352",
      },
      {
        id: 143,
        country_name: "Lithuania",
        country_code: "LT",
        phone_code: "+370",
      },
      {
        id: 144,
        country_name: "Lesotho",
        country_code: "LS",
        phone_code: "+266",
      },
      {
        id: 145,
        country_name: "Liberia",
        country_code: "LR",
        phone_code: "+231",
      },
      {
        id: 146,
        country_name: "Sri Lanka",
        country_code: "LK",
        phone_code: "+94",
      },
      {
        id: 147,
        country_name: "Liechtenstein",
        country_code: "LI",
        phone_code: "+423",
      },
      {
        id: 148,
        country_name: "St Lucia",
        country_code: "LC",
        phone_code: "+1758",
      },
      {
        id: 149,
        country_name: "Lebanon",
        country_code: "LB",
        phone_code: "+961",
      },
      {
        id: 150,
        country_name: "Laos",
        country_code: "LA",
        phone_code: "+856",
      },
      {
        id: 151,
        country_name: "Kazakhstan",
        country_code: "KZ",
        phone_code: "+7",
      },
      {
        id: 152,
        country_name: "Cayman Islands",
        country_code: "KY",
        phone_code: "+1345",
      },
      {
        id: 153,
        country_name: "Kuwait",
        country_code: "KW",
        phone_code: "+965",
      },
      {
        id: 154,
        country_name: "South Korea",
        country_code: "KR",
        phone_code: "+82",
      },
      {
        id: 155,
        country_name: "Saint Kitts and Nevis",
        country_code: "KN",
        phone_code: "+1869",
      },
      {
        id: 156,
        country_name: "Comoros",
        country_code: "KM",
        phone_code: "+269",
      },
      {
        id: 157,
        country_name: "Kiribati",
        country_code: "KI",
        phone_code: "+686",
      },
      {
        id: 158,
        country_name: "Cambodia",
        country_code: "KH",
        phone_code: "+855",
      },
      {
        id: 159,
        country_name: "Kyrgyzstan",
        country_code: "KG",
        phone_code: "+996",
      },
      {
        id: 160,
        country_name: "Kenya",
        country_code: "KE",
        phone_code: "+254",
      },
      {
        id: 161,
        country_name: "Japan",
        country_code: "JP",
        phone_code: "+81",
      },
      {
        id: 162,
        country_name: "Jordan",
        country_code: "JO",
        phone_code: "+962",
      },
      {
        id: 163,
        country_name: "Jamaica",
        country_code: "JM",
        phone_code: "+1876",
      },
      {
        id: 164,
        country_name: "Zimbabwe",
        country_code: "ZW",
        phone_code: "+263",
      },
      {
        id: 165,
        country_name: "Zambia",
        country_code: "ZM",
        phone_code: "+260",
      },
      {
        id: 166,
        country_name: "Italy",
        country_code: "IT",
        phone_code: "+39",
      },
      {
        id: 167,
        country_name: "Iceland",
        country_code: "IS",
        phone_code: "+354",
      },
      {
        id: 168,
        country_name: "Iran",
        country_code: "IR",
        phone_code: "+98",
      },
      {
        id: 169,
        country_name: "Iraq",
        country_code: "IQ",
        phone_code: "+964",
      },
      {
        id: 170,
        country_name: "South Africa",
        country_code: "ZA",
        phone_code: "+27",
      },
      {
        id: 171,
        country_name: "India",
        country_code: "IN",
        phone_code: "+91",
      },
      {
        id: 172,
        country_name: "Israel",
        country_code: "IL",
        phone_code: "+972",
      },
      {
        id: 173,
        country_name: "Ireland",
        country_code: "IE",
        phone_code: "+353",
      },
      {
        id: 174,
        country_name: "Indonesia",
        country_code: "ID",
        phone_code: "+62",
      },
      {
        id: 175,
        country_name: "Mayotte",
        country_code: "YT",
        phone_code: "+269",
      },
      {
        id: 176,
        country_name: "Hungary",
        country_code: "HU",
        phone_code: "+36",
      },
      {
        id: 177,
        country_name: "Yemen",
        country_code: "YE",
        phone_code: "+967",
      },
      {
        id: 178,
        country_name: "Haiti",
        country_code: "HT",
        phone_code: "+509",
      },
      {
        id: 179,
        country_name: "Croatia",
        country_code: "HR",
        phone_code: "+385",
      },
      {
        id: 180,
        country_name: "Honduras",
        country_code: "HN",
        phone_code: "+504",
      },
      {
        id: 181,
        country_name: "China Hong Kong",
        country_code: "HK",
        phone_code: "+852",
      },
      {
        id: 182,
        country_name: "Guyana",
        country_code: "GY",
        phone_code: "+592",
      },
      {
        id: 183,
        country_name: "Republic of Guinea-Bissau",
        country_code: "GW",
        phone_code: "+245",
      },
      {
        id: 184,
        country_name: "Guam",
        country_code: "GU",
        phone_code: "+1671",
      },
      {
        id: 185,
        country_name: "Guatemala",
        country_code: "GT",
        phone_code: "+502",
      },
      {
        id: 186,
        country_name: "Greece",
        country_code: "GR",
        phone_code: "+30",
      },
      {
        id: 187,
        country_name: "Equatorial Guinea",
        country_code: "GQ",
        phone_code: "+240",
      },
      {
        id: 188,
        country_name: "Guadeloupe",
        country_code: "GP",
        phone_code: "+590",
      },
      {
        id: 189,
        country_name: "Guinea",
        country_code: "GN",
        phone_code: "+224",
      },
      {
        id: 190,
        country_name: "Gambia",
        country_code: "GM",
        phone_code: "+220",
      },
      {
        id: 191,
        country_name: "Greenland",
        country_code: "GL",
        phone_code: "+299",
      },
      {
        id: 192,
        country_name: "Gibraltar",
        country_code: "GI",
        phone_code: "+350",
      },
      {
        id: 193,
        country_name: "Ghana",
        country_code: "GH",
        phone_code: "+233",
      },
      {
        id: 194,
        country_name: "French Guiana",
        country_code: "GF",
        phone_code: "+594",
      },
      {
        id: 195,
        country_name: "Georgia",
        country_code: "GE",
        phone_code: "+995",
      },
      {
        id: 196,
        country_name: "Grenada",
        country_code: "GD",
        phone_code: "+1473",
      },
      {
        id: 197,
        country_name: "Samoa",
        country_code: "WS",
        phone_code: "+685",
      },
      {
        id: 198,
        country_name: "U.K.",
        country_code: "GB",
        phone_code: "+44",
      },
      {
        id: 199,
        country_name: "Gabon",
        country_code: "GA",
        phone_code: "+241",
      },
      {
        id: 200,
        country_name: "France",
        country_code: "FR",
        phone_code: "+33",
      },
      {
        id: 201,
        country_name: "Faroe Islands",
        country_code: "FO",
        phone_code: "+298",
      },
      {
        id: 202,
        country_name: "Fiji",
        country_code: "FJ",
        phone_code: "+679",
      },
      {
        id: 203,
        country_name: "Finland",
        country_code: "FI",
        phone_code: "+358",
      },
      {
        id: 204,
        country_name: "Vanuatu",
        country_code: "VU",
        phone_code: "+678",
      },
      {
        id: 205,
        country_name: "Saint Pierre and Miquelon",
        country_code: "PM",
        phone_code: "+508",
      },
      {
        id: 206,
        country_name: "Poland",
        country_code: "PL",
        phone_code: "+48",
      },
      {
        id: 207,
        country_name: "Pakistan",
        country_code: "PK",
        phone_code: "+92",
      },
      {
        id: 208,
        country_name: "the Philippines",
        country_code: "PH",
        phone_code: "+63",
      },
      {
        id: 209,
        country_name: "Papua New Guinea",
        country_code: "PG",
        phone_code: "+675",
      },
      {
        id: 210,
        country_name: "French Polynesia",
        country_code: "PF",
        phone_code: "+689",
      },
      {
        id: 211,
        country_name: "Peru",
        country_code: "PE",
        phone_code: "+51",
      },
      {
        id: 212,
        country_name: "Panama",
        country_code: "PA",
        phone_code: "+507",
      },
      {
        id: 213,
        country_name: "Oman",
        country_code: "OM",
        phone_code: "+968",
      },
      {
        id: 214,
        country_name: "new Zealand",
        country_code: "NZ",
        phone_code: "+64",
      },
      {
        id: 215,
        country_name: "Nepal",
        country_code: "NP",
        phone_code: "+977",
      },
      {
        id: 216,
        country_name: "Norway",
        country_code: "NO",
        phone_code: "+47",
      },
      {
        id: 217,
        country_name: "Netherlands",
        country_code: "NL",
        phone_code: "+31",
      },
      {
        id: 218,
        country_name: "Nicaragua",
        country_code: "NI",
        phone_code: "+505",
      },
      {
        id: 219,
        country_name: "Chinese mainland",
        country_code: "CN",
        phone_code: "+86",
      },
    ],
    fr_FR: [
      {
        id: 1,
        country_name: "Nigeria",
        country_code: "NG",
        phone_code: "+234",
      },
      {
        id: 2,
        country_name: "Canada",
        country_code: "CA",
        phone_code: "+1",
      },
      {
        id: 3,
        country_name: "République Démocratique du Congo",
        country_code: "CD",
        phone_code: "+243",
      },
      {
        id: 4,
        country_name: "Russie",
        country_code: "RU",
        phone_code: "+7",
      },
      {
        id: 5,
        country_name: "États-Unis",
        country_code: "US",
        phone_code: "+1",
      },
      {
        id: 6,
        country_name: "Belgique",
        country_code: "BE",
        phone_code: "+32",
      },
      {
        id: 7,
        country_name: "Bengladesh",
        country_code: "BD",
        phone_code: "+880",
      },
      {
        id: 8,
        country_name: "Serbie",
        country_code: "RS",
        phone_code: "+381",
      },
      {
        id: 9,
        country_name: "Barbade",
        country_code: "BB",
        phone_code: "+1246",
      },
      {
        id: 10,
        country_name: "Bosnie Herzégovine",
        country_code: "BA",
        phone_code: "+387",
      },
      {
        id: 11,
        country_name: "Roumanie",
        country_code: "RO",
        phone_code: "+40",
      },
      {
        id: 12,
        country_name: "Azerbaïdjan",
        country_code: "AZ",
        phone_code: "+994",
      },
      {
        id: 13,
        country_name: "Aruba",
        country_code: "AW",
        phone_code: "+297",
      },
      {
        id: 14,
        country_name: "Australie",
        country_code: "AU",
        phone_code: "+61",
      },
      {
        id: 15,
        country_name: "L'Autriche",
        country_code: "AT",
        phone_code: "+43",
      },
      {
        id: 16,
        country_name: "Réunion",
        country_code: "RE",
        phone_code: "+262",
      },
      {
        id: 17,
        country_name: "Samoa américaines",
        country_code: "AS",
        phone_code: "+1684",
      },
      {
        id: 18,
        country_name: "Argentine",
        country_code: "AR",
        phone_code: "+54",
      },
      {
        id: 19,
        country_name: "Angola",
        country_code: "AO",
        phone_code: "+244",
      },
      {
        id: 20,
        country_name: "Arménie",
        country_code: "AM",
        phone_code: "+374",
      },
      {
        id: 21,
        country_name: "Albanie",
        country_code: "AL",
        phone_code: "+355",
      },
      {
        id: 22,
        country_name: "Anguilla",
        country_code: "AI",
        phone_code: "+1264",
      },
      {
        id: 23,
        country_name: "Antigua-et-Barbuda",
        country_code: "AG",
        phone_code: "+1268",
      },
      {
        id: 24,
        country_name: "Afghanistan",
        country_code: "AF",
        phone_code: "+93",
      },
      {
        id: 25,
        country_name: "Emirats Arabes Unis",
        country_code: "AE",
        phone_code: "+971",
      },
      {
        id: 26,
        country_name: "Andorre",
        country_code: "AD",
        phone_code: "+376",
      },
      {
        id: 27,
        country_name: "Île de l'Ascension",
        country_code: "AC",
        phone_code: "+247",
      },
      {
        id: 28,
        country_name: "Qatar",
        country_code: "QA",
        phone_code: "+974",
      },
      {
        id: 29,
        country_name: "Paraguay",
        country_code: "PY",
        phone_code: "+595",
      },
      {
        id: 30,
        country_name: "Palaos",
        country_code: "PW",
        phone_code: "+680",
      },
      {
        id: 31,
        country_name: "le Portugal",
        country_code: "PT",
        phone_code: "+351",
      },
      {
        id: 32,
        country_name: "Territoires palestiniens",
        country_code: "PS",
        phone_code: "+970",
      },
      {
        id: 33,
        country_name: "Porto Rico",
        country_code: "PR",
        phone_code: "+1787",
      },
      {
        id: 34,
        country_name: "Somalie",
        country_code: "SO",
        phone_code: "+252",
      },
      {
        id: 35,
        country_name: "Sénégal",
        country_code: "SN",
        phone_code: "+221",
      },
      {
        id: 36,
        country_name: "Saint Marin",
        country_code: "SM",
        phone_code: "+378",
      },
      {
        id: 37,
        country_name: "Sierra Leone",
        country_code: "SL",
        phone_code: "+232",
      },
      {
        id: 38,
        country_name: "Slovaquie",
        country_code: "SK",
        phone_code: "+421",
      },
      {
        id: 39,
        country_name: "Bélize",
        country_code: "BZ",
        phone_code: "+501",
      },
      {
        id: 40,
        country_name: "Biélorussie",
        country_code: "BY",
        phone_code: "+375",
      },
      {
        id: 41,
        country_name: "Slovénie",
        country_code: "SI",
        phone_code: "+386",
      },
      {
        id: 42,
        country_name: "Bostwana",
        country_code: "BW",
        phone_code: "+267",
      },
      {
        id: 43,
        country_name: "Singapour",
        country_code: "SG",
        phone_code: "+65",
      },
      {
        id: 44,
        country_name: "Suède",
        country_code: "SE",
        phone_code: "+46",
      },
      {
        id: 45,
        country_name: "Bhoutan",
        country_code: "BT",
        phone_code: "+975",
      },
      {
        id: 46,
        country_name: "Soudan",
        country_code: "SD",
        phone_code: "+249",
      },
      {
        id: 47,
        country_name: "Bahamas",
        country_code: "BS",
        phone_code: "+1242",
      },
      {
        id: 48,
        country_name: "Brésil",
        country_code: "BR",
        phone_code: "+55",
      },
      {
        id: 49,
        country_name: "les Seychelles",
        country_code: "SC",
        phone_code: "+248",
      },
      {
        id: 50,
        country_name: "Les îles Salomon",
        country_code: "SB",
        phone_code: "+677",
      },
      {
        id: 51,
        country_name: "Bonaire, St Eustache Et Saba",
        country_code: "BQ",
        phone_code: "+599",
      },
      {
        id: 52,
        country_name: "Arabie Saoudite",
        country_code: "SA",
        phone_code: "+966",
      },
      {
        id: 53,
        country_name: "Bolivie",
        country_code: "BO",
        phone_code: "+591",
      },
      {
        id: 54,
        country_name: "Brunéi",
        country_code: "BN",
        phone_code: "+673",
      },
      {
        id: 55,
        country_name: "Bermudes",
        country_code: "BM",
        phone_code: "+1441",
      },
      {
        id: 56,
        country_name: "bénin",
        country_code: "BJ",
        phone_code: "+229",
      },
      {
        id: 57,
        country_name: "Burundi",
        country_code: "BI",
        phone_code: "+257",
      },
      {
        id: 58,
        country_name: "Bahreïn",
        country_code: "BH",
        phone_code: "+973",
      },
      {
        id: 59,
        country_name: "Bulgarie",
        country_code: "BG",
        phone_code: "+359",
      },
      {
        id: 60,
        country_name: "Rwanda",
        country_code: "RW",
        phone_code: "+250",
      },
      {
        id: 61,
        country_name: "Burkina Faso",
        country_code: "BF",
        phone_code: "+226",
      },
      {
        id: 62,
        country_name: "Timor oriental",
        country_code: "TL",
        phone_code: "+670",
      },
      {
        id: 63,
        country_name: "République tchèque",
        country_code: "CZ",
        phone_code: "+420",
      },
      {
        id: 64,
        country_name: "Tadjikistan",
        country_code: "TJ",
        phone_code: "+992",
      },
      {
        id: 65,
        country_name: "Chypre",
        country_code: "CY",
        phone_code: "+357",
      },
      {
        id: 66,
        country_name: "Thaïlande",
        country_code: "TH",
        phone_code: "+66",
      },
      {
        id: 67,
        country_name: "Curacao",
        country_code: "CW",
        phone_code: "+599",
      },
      {
        id: 68,
        country_name: "Aller",
        country_code: "TG",
        phone_code: "+228",
      },
      {
        id: 69,
        country_name: "Cap",
        country_code: "CV",
        phone_code: "+238",
      },
      {
        id: 70,
        country_name: "Cuba",
        country_code: "CU",
        phone_code: "+53",
      },
      {
        id: 71,
        country_name: "Tchad",
        country_code: "TD",
        phone_code: "+235",
      },
      {
        id: 72,
        country_name: "Costa Rica",
        country_code: "CR",
        phone_code: "+506",
      },
      {
        id: 73,
        country_name: "îles Turques-et-Caïques",
        country_code: "TC",
        phone_code: "+1649",
      },
      {
        id: 74,
        country_name: "Colombie",
        country_code: "CO",
        phone_code: "+57",
      },
      {
        id: 75,
        country_name: "Cameroun",
        country_code: "CM",
        phone_code: "+237",
      },
      {
        id: 76,
        country_name: "Chili",
        country_code: "CL",
        phone_code: "+56",
      },
      {
        id: 77,
        country_name: "île",
        country_code: "CK",
        phone_code: "+682",
      },
      {
        id: 78,
        country_name: "Swaziland",
        country_code: "SZ",
        phone_code: "+268",
      },
      {
        id: 79,
        country_name: "Syrie",
        country_code: "SY",
        phone_code: "+963",
      },
      {
        id: 80,
        country_name: "Côte d'Ivoire",
        country_code: "CI",
        phone_code: "+225",
      },
      {
        id: 81,
        country_name: "Suisse",
        country_code: "CH",
        phone_code: "+41",
      },
      {
        id: 82,
        country_name: "Sint Maarten (partie hollandaise)",
        country_code: "SX",
        phone_code: "+1721",
      },
      {
        id: 83,
        country_name: "La République du Congo",
        country_code: "CG",
        phone_code: "+242",
      },
      {
        id: 84,
        country_name: "Le Salvador",
        country_code: "SV",
        phone_code: "+503",
      },
      {
        id: 85,
        country_name: "République centrafricaine",
        country_code: "CF",
        phone_code: "+236",
      },
      {
        id: 86,
        country_name: "Sao Tomé et Principe",
        country_code: "ST",
        phone_code: "+239",
      },
      {
        id: 87,
        country_name: "Soudan du sud",
        country_code: "SS",
        phone_code: "+211",
      },
      {
        id: 88,
        country_name: "Suriname",
        country_code: "SR",
        phone_code: "+597",
      },
      {
        id: 89,
        country_name: "Viêt Nam",
        country_code: "VN",
        phone_code: "+84",
      },
      {
        id: 90,
        country_name: "Îles Vierges des États-Unis",
        country_code: "VI",
        phone_code: "+1284",
      },
      {
        id: 91,
        country_name: "Îles Vierges britanniques",
        country_code: "VG",
        phone_code: "+1340",
      },
      {
        id: 92,
        country_name: "Venezuela",
        country_code: "VE",
        phone_code: "+58",
      },
      {
        id: 93,
        country_name: "Ethiopie",
        country_code: "ET",
        phone_code: "+251",
      },
      {
        id: 94,
        country_name: "Espagne",
        country_code: "ES",
        phone_code: "+34",
      },
      {
        id: 95,
        country_name: "Saint-Vincent-et-les-Grenadines",
        country_code: "VC",
        phone_code: "+1784",
      },
      {
        id: 96,
        country_name: "Ouzbékistan",
        country_code: "UZ",
        phone_code: "+998",
      },
      {
        id: 97,
        country_name: "Uruguay",
        country_code: "UY",
        phone_code: "+598",
      },
      {
        id: 98,
        country_name: "Egypte",
        country_code: "EG",
        phone_code: "+20",
      },
      {
        id: 99,
        country_name: "Estonie",
        country_code: "EE",
        phone_code: "+372",
      },
      {
        id: 100,
        country_name: "Equateur",
        country_code: "EC",
        phone_code: "+593",
      },
      {
        id: 101,
        country_name: "Algérie",
        country_code: "DZ",
        phone_code: "+213",
      },
      {
        id: 102,
        country_name: "Ouganda",
        country_code: "UG",
        phone_code: "+256",
      },
      {
        id: 103,
        country_name: "Ukraine",
        country_code: "UA",
        phone_code: "+380",
      },
      {
        id: 104,
        country_name: "République dominicaine",
        country_code: "DO",
        phone_code: "+1809",
      },
      {
        id: 105,
        country_name: "Dominique",
        country_code: "DM",
        phone_code: "+1767",
      },
      {
        id: 106,
        country_name: "Danemark",
        country_code: "DK",
        phone_code: "+45",
      },
      {
        id: 107,
        country_name: "Tanzanie",
        country_code: "TZ",
        phone_code: "+255",
      },
      {
        id: 108,
        country_name: "Djibouti",
        country_code: "DJ",
        phone_code: "+253",
      },
      {
        id: 109,
        country_name: "Taïwan, Chine",
        country_code: "TW",
        phone_code: "+886",
      },
      {
        id: 110,
        country_name: "Allemagne",
        country_code: "DE",
        phone_code: "+49",
      },
      {
        id: 111,
        country_name: "Trinité-et-Tobago",
        country_code: "TT",
        phone_code: "+1868",
      },
      {
        id: 112,
        country_name: "Turquie",
        country_code: "TR",
        phone_code: "+90",
      },
      {
        id: 113,
        country_name: "Tonga",
        country_code: "TO",
        phone_code: "+676",
      },
      {
        id: 114,
        country_name: "Tunisie",
        country_code: "TN",
        phone_code: "+216",
      },
      {
        id: 115,
        country_name: "Turkménistan",
        country_code: "TM",
        phone_code: "+993",
      },
      {
        id: 116,
        country_name: "Niger",
        country_code: "NE",
        phone_code: "+227",
      },
      {
        id: 117,
        country_name: "Nouvelle Calédonie",
        country_code: "NC",
        phone_code: "+687",
      },
      {
        id: 118,
        country_name: "Namibie",
        country_code: "NA",
        phone_code: "+264",
      },
      {
        id: 119,
        country_name: "Mozambique",
        country_code: "MZ",
        phone_code: "+258",
      },
      {
        id: 120,
        country_name: "Malaisie",
        country_code: "MY",
        phone_code: "+60",
      },
      {
        id: 121,
        country_name: "Mexique",
        country_code: "MX",
        phone_code: "+52",
      },
      {
        id: 122,
        country_name: "Malawi",
        country_code: "MW",
        phone_code: "+265",
      },
      {
        id: 123,
        country_name: "Maldives",
        country_code: "MV",
        phone_code: "+960",
      },
      {
        id: 124,
        country_name: "Maurice",
        country_code: "MU",
        phone_code: "+230",
      },
      {
        id: 125,
        country_name: "Malte",
        country_code: "MT",
        phone_code: "+356",
      },
      {
        id: 126,
        country_name: "Montserrat",
        country_code: "MS",
        phone_code: "+1664",
      },
      {
        id: 127,
        country_name: "Mauritanie",
        country_code: "MR",
        phone_code: "+222",
      },
      {
        id: 128,
        country_name: "Martinique",
        country_code: "MQ",
        phone_code: "+596",
      },
      {
        id: 129,
        country_name: "Îles Mariannes",
        country_code: "MP",
        phone_code: "+1670",
      },
      {
        id: 130,
        country_name: "Macao, Chine",
        country_code: "MO",
        phone_code: "+853",
      },
      {
        id: 131,
        country_name: "Mongolie",
        country_code: "MN",
        phone_code: "+976",
      },
      {
        id: 132,
        country_name: "Birmanie",
        country_code: "MM",
        phone_code: "+95",
      },
      {
        id: 133,
        country_name: "Mali",
        country_code: "ML",
        phone_code: "+223",
      },
      {
        id: 134,
        country_name: "Macédoine",
        country_code: "MK",
        phone_code: "+389",
      },
      {
        id: 135,
        country_name: "Madagascar",
        country_code: "MG",
        phone_code: "+261",
      },
      {
        id: 136,
        country_name: "Monténégro",
        country_code: "ME",
        phone_code: "+382",
      },
      {
        id: 137,
        country_name: "Moldavie",
        country_code: "MD",
        phone_code: "+373",
      },
      {
        id: 138,
        country_name: "Monaco",
        country_code: "MC",
        phone_code: "+377",
      },
      {
        id: 139,
        country_name: "Maroc",
        country_code: "MA",
        phone_code: "+212",
      },
      {
        id: 140,
        country_name: "Libye",
        country_code: "LY",
        phone_code: "+218",
      },
      {
        id: 141,
        country_name: "Lettonie",
        country_code: "LV",
        phone_code: "+371",
      },
      {
        id: 142,
        country_name: "Luxembourg",
        country_code: "LU",
        phone_code: "+352",
      },
      {
        id: 143,
        country_name: "Lituanie",
        country_code: "LT",
        phone_code: "+370",
      },
      {
        id: 144,
        country_name: "Lesotho",
        country_code: "LS",
        phone_code: "+266",
      },
      {
        id: 145,
        country_name: "Libéria",
        country_code: "LR",
        phone_code: "+231",
      },
      {
        id: 146,
        country_name: "Sri Lanka",
        country_code: "LK",
        phone_code: "+94",
      },
      {
        id: 147,
        country_name: "Liechtenstein",
        country_code: "LI",
        phone_code: "+423",
      },
      {
        id: 148,
        country_name: "Sainte-Lucie",
        country_code: "LC",
        phone_code: "+1758",
      },
      {
        id: 149,
        country_name: "Liban",
        country_code: "LB",
        phone_code: "+961",
      },
      {
        id: 150,
        country_name: "Laos",
        country_code: "LA",
        phone_code: "+856",
      },
      {
        id: 151,
        country_name: "Kazakhstan",
        country_code: "KZ",
        phone_code: "+7",
      },
      {
        id: 152,
        country_name: "Îles Caïmans",
        country_code: "KY",
        phone_code: "+1345",
      },
      {
        id: 153,
        country_name: "Koweit",
        country_code: "KW",
        phone_code: "+965",
      },
      {
        id: 154,
        country_name: "Corée du Sud",
        country_code: "KR",
        phone_code: "+82",
      },
      {
        id: 155,
        country_name: "Saint-Christophe-et-Niévès",
        country_code: "KN",
        phone_code: "+1869",
      },
      {
        id: 156,
        country_name: "Comores",
        country_code: "KM",
        phone_code: "+269",
      },
      {
        id: 157,
        country_name: "Kiribati",
        country_code: "KI",
        phone_code: "+686",
      },
      {
        id: 158,
        country_name: "Cambodge",
        country_code: "KH",
        phone_code: "+855",
      },
      {
        id: 159,
        country_name: "Kirghizistan",
        country_code: "KG",
        phone_code: "+996",
      },
      {
        id: 160,
        country_name: "Kenya",
        country_code: "KE",
        phone_code: "+254",
      },
      {
        id: 161,
        country_name: "Japon",
        country_code: "JP",
        phone_code: "+81",
      },
      {
        id: 162,
        country_name: "Jordan",
        country_code: "JO",
        phone_code: "+962",
      },
      {
        id: 163,
        country_name: "Jamaïque",
        country_code: "JM",
        phone_code: "+1876",
      },
      {
        id: 164,
        country_name: "Zimbabwe",
        country_code: "ZW",
        phone_code: "+263",
      },
      {
        id: 165,
        country_name: "Zambie",
        country_code: "ZM",
        phone_code: "+260",
      },
      {
        id: 166,
        country_name: "Italie",
        country_code: "IT",
        phone_code: "+39",
      },
      {
        id: 167,
        country_name: "Islande",
        country_code: "IS",
        phone_code: "+354",
      },
      {
        id: 168,
        country_name: "L'Iran",
        country_code: "IR",
        phone_code: "+98",
      },
      {
        id: 169,
        country_name: "Irak",
        country_code: "IQ",
        phone_code: "+964",
      },
      {
        id: 170,
        country_name: "Afrique du Sud",
        country_code: "ZA",
        phone_code: "+27",
      },
      {
        id: 171,
        country_name: "Inde",
        country_code: "IN",
        phone_code: "+91",
      },
      {
        id: 172,
        country_name: "Israël",
        country_code: "IL",
        phone_code: "+972",
      },
      {
        id: 173,
        country_name: "Irlande",
        country_code: "IE",
        phone_code: "+353",
      },
      {
        id: 174,
        country_name: "Indonésie",
        country_code: "ID",
        phone_code: "+62",
      },
      {
        id: 175,
        country_name: "Mayotte",
        country_code: "YT",
        phone_code: "+269",
      },
      {
        id: 176,
        country_name: "Hongrie",
        country_code: "HU",
        phone_code: "+36",
      },
      {
        id: 177,
        country_name: "Yémen",
        country_code: "YE",
        phone_code: "+967",
      },
      {
        id: 178,
        country_name: "Haïti",
        country_code: "HT",
        phone_code: "+509",
      },
      {
        id: 179,
        country_name: "Croatie",
        country_code: "HR",
        phone_code: "+385",
      },
      {
        id: 180,
        country_name: "Honduras",
        country_code: "HN",
        phone_code: "+504",
      },
      {
        id: 181,
        country_name: "Chine Hong Kong",
        country_code: "HK",
        phone_code: "+852",
      },
      {
        id: 182,
        country_name: "Guyane",
        country_code: "GY",
        phone_code: "+592",
      },
      {
        id: 183,
        country_name: "République de Guinée-Bissau",
        country_code: "GW",
        phone_code: "+245",
      },
      {
        id: 184,
        country_name: "Guam",
        country_code: "GU",
        phone_code: "+1671",
      },
      {
        id: 185,
        country_name: "Guatemala",
        country_code: "GT",
        phone_code: "+502",
      },
      {
        id: 186,
        country_name: "Grèce",
        country_code: "GR",
        phone_code: "+30",
      },
      {
        id: 187,
        country_name: "Guinée Équatoriale",
        country_code: "GQ",
        phone_code: "+240",
      },
      {
        id: 188,
        country_name: "Guadeloupe",
        country_code: "GP",
        phone_code: "+590",
      },
      {
        id: 189,
        country_name: "Guinée",
        country_code: "GN",
        phone_code: "+224",
      },
      {
        id: 190,
        country_name: "Gambie",
        country_code: "GM",
        phone_code: "+220",
      },
      {
        id: 191,
        country_name: "Groenland",
        country_code: "GL",
        phone_code: "+299",
      },
      {
        id: 192,
        country_name: "Gibraltar",
        country_code: "GI",
        phone_code: "+350",
      },
      {
        id: 193,
        country_name: "Ghana",
        country_code: "GH",
        phone_code: "+233",
      },
      {
        id: 194,
        country_name: "Guyane Française",
        country_code: "GF",
        phone_code: "+594",
      },
      {
        id: 195,
        country_name: "Géorgie",
        country_code: "GE",
        phone_code: "+995",
      },
      {
        id: 196,
        country_name: "Grenade",
        country_code: "GD",
        phone_code: "+1473",
      },
      {
        id: 197,
        country_name: "Samoa",
        country_code: "WS",
        phone_code: "+685",
      },
      {
        id: 198,
        country_name: "ROYAUME-UNI.",
        country_code: "GB",
        phone_code: "+44",
      },
      {
        id: 199,
        country_name: "Gabon",
        country_code: "GA",
        phone_code: "+241",
      },
      {
        id: 200,
        country_name: "France",
        country_code: "FR",
        phone_code: "+33",
      },
      {
        id: 201,
        country_name: "Îles Féroé",
        country_code: "FO",
        phone_code: "+298",
      },
      {
        id: 202,
        country_name: "Fidji",
        country_code: "FJ",
        phone_code: "+679",
      },
      {
        id: 203,
        country_name: "Finlande",
        country_code: "FI",
        phone_code: "+358",
      },
      {
        id: 204,
        country_name: "Vanuatu",
        country_code: "VU",
        phone_code: "+678",
      },
      {
        id: 205,
        country_name: "Saint-Pierre-et-Miquelon",
        country_code: "PM",
        phone_code: "+508",
      },
      {
        id: 206,
        country_name: "Pologne",
        country_code: "PL",
        phone_code: "+48",
      },
      {
        id: 207,
        country_name: "Pakistan",
        country_code: "PK",
        phone_code: "+92",
      },
      {
        id: 208,
        country_name: "Les Philippines",
        country_code: "PH",
        phone_code: "+63",
      },
      {
        id: 209,
        country_name: "Papouasie Nouvelle Guinée",
        country_code: "PG",
        phone_code: "+675",
      },
      {
        id: 210,
        country_name: "Polynésie française",
        country_code: "PF",
        phone_code: "+689",
      },
      {
        id: 211,
        country_name: "Pérou",
        country_code: "PE",
        phone_code: "+51",
      },
      {
        id: 212,
        country_name: "Panama",
        country_code: "PA",
        phone_code: "+507",
      },
      {
        id: 213,
        country_name: "Oman",
        country_code: "OM",
        phone_code: "+968",
      },
      {
        id: 214,
        country_name: "Nouvelle-Zélande",
        country_code: "NZ",
        phone_code: "+64",
      },
      {
        id: 215,
        country_name: "Népal",
        country_code: "NP",
        phone_code: "+977",
      },
      {
        id: 216,
        country_name: "Norvège",
        country_code: "NO",
        phone_code: "+47",
      },
      {
        id: 217,
        country_name: "Pays-Bas",
        country_code: "NL",
        phone_code: "+31",
      },
      {
        id: 218,
        country_name: "Nicaragua",
        country_code: "NI",
        phone_code: "+505",
      },
      {
        id: 219,
        country_name: "Chine Continentale",
        country_code: "CN",
        phone_code: "+86",
      },
    ],
    pt_PT: [
      {
        id: 1,
        country_name: "Nigéria",
        country_code: "NG",
        phone_code: "+234",
      },
      {
        id: 2,
        country_name: "Canadá",
        country_code: "CA",
        phone_code: "+1",
      },
      {
        id: 3,
        country_name: "República Democrática do Congo",
        country_code: "CD",
        phone_code: "+243",
      },
      {
        id: 4,
        country_name: "Rússia",
        country_code: "RU",
        phone_code: "+7",
      },
      {
        id: 5,
        country_name: "Estados Unidos",
        country_code: "US",
        phone_code: "+1",
      },
      {
        id: 6,
        country_name: "Bélgica",
        country_code: "BE",
        phone_code: "+32",
      },
      {
        id: 7,
        country_name: "Bangladesh",
        country_code: "BD",
        phone_code: "+880",
      },
      {
        id: 8,
        country_name: "Sérvia",
        country_code: "RS",
        phone_code: "+381",
      },
      {
        id: 9,
        country_name: "Barbados",
        country_code: "BB",
        phone_code: "+1246",
      },
      {
        id: 10,
        country_name: "Bósnia e Herzegovina",
        country_code: "BA",
        phone_code: "+387",
      },
      {
        id: 11,
        country_name: "Romênia",
        country_code: "RO",
        phone_code: "+40",
      },
      {
        id: 12,
        country_name: "Azerbaijão",
        country_code: "AZ",
        phone_code: "+994",
      },
      {
        id: 13,
        country_name: "Aruba",
        country_code: "AW",
        phone_code: "+297",
      },
      {
        id: 14,
        country_name: "Austrália",
        country_code: "AU",
        phone_code: "+61",
      },
      {
        id: 15,
        country_name: "Áustria",
        country_code: "AT",
        phone_code: "+43",
      },
      {
        id: 16,
        country_name: "Reunião",
        country_code: "RE",
        phone_code: "+262",
      },
      {
        id: 17,
        country_name: "Samoa Americana",
        country_code: "AS",
        phone_code: "+1684",
      },
      {
        id: 18,
        country_name: "Argentina",
        country_code: "AR",
        phone_code: "+54",
      },
      {
        id: 19,
        country_name: "Angola",
        country_code: "AO",
        phone_code: "+244",
      },
      {
        id: 20,
        country_name: "Armênia",
        country_code: "AM",
        phone_code: "+374",
      },
      {
        id: 21,
        country_name: "Albânia",
        country_code: "AL",
        phone_code: "+355",
      },
      {
        id: 22,
        country_name: "Anguila",
        country_code: "AI",
        phone_code: "+1264",
      },
      {
        id: 23,
        country_name: "Antígua e Barbuda",
        country_code: "AG",
        phone_code: "+1268",
      },
      {
        id: 24,
        country_name: "Afeganistão",
        country_code: "AF",
        phone_code: "+93",
      },
      {
        id: 25,
        country_name: "Emirados Árabes Unidos",
        country_code: "AE",
        phone_code: "+971",
      },
      {
        id: 26,
        country_name: "Andorra",
        country_code: "AD",
        phone_code: "+376",
      },
      {
        id: 27,
        country_name: "Ilha de Ascensão",
        country_code: "AC",
        phone_code: "+247",
      },
      {
        id: 28,
        country_name: "Catar",
        country_code: "QA",
        phone_code: "+974",
      },
      {
        id: 29,
        country_name: "Paraguai",
        country_code: "PY",
        phone_code: "+595",
      },
      {
        id: 30,
        country_name: "Palau",
        country_code: "PW",
        phone_code: "+680",
      },
      {
        id: 31,
        country_name: "Portugal",
        country_code: "PT",
        phone_code: "+351",
      },
      {
        id: 32,
        country_name: "Territórios Palestinos",
        country_code: "PS",
        phone_code: "+970",
      },
      {
        id: 33,
        country_name: "Porto Rico",
        country_code: "PR",
        phone_code: "+1787",
      },
      {
        id: 34,
        country_name: "Somália",
        country_code: "SO",
        phone_code: "+252",
      },
      {
        id: 35,
        country_name: "Senegal",
        country_code: "SN",
        phone_code: "+221",
      },
      {
        id: 36,
        country_name: "San Marino",
        country_code: "SM",
        phone_code: "+378",
      },
      {
        id: 37,
        country_name: "Serra Leoa",
        country_code: "SL",
        phone_code: "+232",
      },
      {
        id: 38,
        country_name: "Eslováquia",
        country_code: "SK",
        phone_code: "+421",
      },
      {
        id: 39,
        country_name: "Belize",
        country_code: "BZ",
        phone_code: "+501",
      },
      {
        id: 40,
        country_name: "Bielorrússia",
        country_code: "BY",
        phone_code: "+375",
      },
      {
        id: 41,
        country_name: "Eslovênia",
        country_code: "SI",
        phone_code: "+386",
      },
      {
        id: 42,
        country_name: "Botsuana",
        country_code: "BW",
        phone_code: "+267",
      },
      {
        id: 43,
        country_name: "Cingapura",
        country_code: "SG",
        phone_code: "+65",
      },
      {
        id: 44,
        country_name: "Suécia",
        country_code: "SE",
        phone_code: "+46",
      },
      {
        id: 45,
        country_name: "Butão",
        country_code: "BT",
        phone_code: "+975",
      },
      {
        id: 46,
        country_name: "Sudão",
        country_code: "SD",
        phone_code: "+249",
      },
      {
        id: 47,
        country_name: "Bahamas",
        country_code: "BS",
        phone_code: "+1242",
      },
      {
        id: 48,
        country_name: "Brasil",
        country_code: "BR",
        phone_code: "+55",
      },
      {
        id: 49,
        country_name: "Seychelles",
        country_code: "SC",
        phone_code: "+248",
      },
      {
        id: 50,
        country_name: "Ilhas Salomão",
        country_code: "SB",
        phone_code: "+677",
      },
      {
        id: 51,
        country_name: "Bonaire, Santo Eustáquio e Saba",
        country_code: "BQ",
        phone_code: "+599",
      },
      {
        id: 52,
        country_name: "Arábia Saudita",
        country_code: "SA",
        phone_code: "+966",
      },
      {
        id: 53,
        country_name: "Bolívia",
        country_code: "BO",
        phone_code: "+591",
      },
      {
        id: 54,
        country_name: "Brunei",
        country_code: "BN",
        phone_code: "+673",
      },
      {
        id: 55,
        country_name: "Bermudas",
        country_code: "BM",
        phone_code: "+1441",
      },
      {
        id: 56,
        country_name: "Benim",
        country_code: "BJ",
        phone_code: "+229",
      },
      {
        id: 57,
        country_name: "Burundi",
        country_code: "BI",
        phone_code: "+257",
      },
      {
        id: 58,
        country_name: "Bahrein",
        country_code: "BH",
        phone_code: "+973",
      },
      {
        id: 59,
        country_name: "Bulgária",
        country_code: "BG",
        phone_code: "+359",
      },
      {
        id: 60,
        country_name: "Ruanda",
        country_code: "RW",
        phone_code: "+250",
      },
      {
        id: 61,
        country_name: "Burkina Faso",
        country_code: "BF",
        phone_code: "+226",
      },
      {
        id: 62,
        country_name: "Timor Leste",
        country_code: "TL",
        phone_code: "+670",
      },
      {
        id: 63,
        country_name: "República Checa",
        country_code: "CZ",
        phone_code: "+420",
      },
      {
        id: 64,
        country_name: "Tadjiquistão",
        country_code: "TJ",
        phone_code: "+992",
      },
      {
        id: 65,
        country_name: "Chipre",
        country_code: "CY",
        phone_code: "+357",
      },
      {
        id: 66,
        country_name: "Tailândia",
        country_code: "TH",
        phone_code: "+66",
      },
      {
        id: 67,
        country_name: "Curaçao",
        country_code: "CW",
        phone_code: "+599",
      },
      {
        id: 68,
        country_name: "Togo",
        country_code: "TG",
        phone_code: "+228",
      },
      {
        id: 69,
        country_name: "Capa",
        country_code: "CV",
        phone_code: "+238",
      },
      {
        id: 70,
        country_name: "Cuba",
        country_code: "CU",
        phone_code: "+53",
      },
      {
        id: 71,
        country_name: "Chade",
        country_code: "TD",
        phone_code: "+235",
      },
      {
        id: 72,
        country_name: "Costa Rica",
        country_code: "CR",
        phone_code: "+506",
      },
      {
        id: 73,
        country_name: "Ilhas Turcas e Caicos",
        country_code: "TC",
        phone_code: "+1649",
      },
      {
        id: 74,
        country_name: "Colômbia",
        country_code: "CO",
        phone_code: "+57",
      },
      {
        id: 75,
        country_name: "Camarões",
        country_code: "CM",
        phone_code: "+237",
      },
      {
        id: 76,
        country_name: "Chile",
        country_code: "CL",
        phone_code: "+56",
      },
      {
        id: 77,
        country_name: "Ilha",
        country_code: "CK",
        phone_code: "+682",
      },
      {
        id: 78,
        country_name: "Suazilândia",
        country_code: "SZ",
        phone_code: "+268",
      },
      {
        id: 79,
        country_name: "Síria",
        country_code: "SY",
        phone_code: "+963",
      },
      {
        id: 80,
        country_name: "Costa do Marfim",
        country_code: "CI",
        phone_code: "+225",
      },
      {
        id: 81,
        country_name: "Suíça",
        country_code: "CH",
        phone_code: "+41",
      },
      {
        id: 82,
        country_name: "Sint Maarten (parte holandesa)",
        country_code: "SX",
        phone_code: "+1721",
      },
      {
        id: 83,
        country_name: "A República Do Congo",
        country_code: "CG",
        phone_code: "+242",
      },
      {
        id: 84,
        country_name: "El Salvador",
        country_code: "SV",
        phone_code: "+503",
      },
      {
        id: 85,
        country_name: "República Centro-Africana",
        country_code: "CF",
        phone_code: "+236",
      },
      {
        id: 86,
        country_name: "São Tomé e Príncipe",
        country_code: "ST",
        phone_code: "+239",
      },
      {
        id: 87,
        country_name: "Sudão do Sul",
        country_code: "SS",
        phone_code: "+211",
      },
      {
        id: 88,
        country_name: "Suriname",
        country_code: "SR",
        phone_code: "+597",
      },
      {
        id: 89,
        country_name: "Vietnã",
        country_code: "VN",
        phone_code: "+84",
      },
      {
        id: 90,
        country_name: "Ilhas Virgens Americanas",
        country_code: "VI",
        phone_code: "+1284",
      },
      {
        id: 91,
        country_name: "Ilhas Virgens Britânicas",
        country_code: "VG",
        phone_code: "+1340",
      },
      {
        id: 92,
        country_name: "Venezuela",
        country_code: "VE",
        phone_code: "+58",
      },
      {
        id: 93,
        country_name: "Etiópia",
        country_code: "ET",
        phone_code: "+251",
      },
      {
        id: 94,
        country_name: "Espanha",
        country_code: "ES",
        phone_code: "+34",
      },
      {
        id: 95,
        country_name: "São Vicente e Granadinas",
        country_code: "VC",
        phone_code: "+1784",
      },
      {
        id: 96,
        country_name: "Uzbequistão",
        country_code: "UZ",
        phone_code: "+998",
      },
      {
        id: 97,
        country_name: "Uruguai",
        country_code: "UY",
        phone_code: "+598",
      },
      {
        id: 98,
        country_name: "Egito",
        country_code: "EG",
        phone_code: "+20",
      },
      {
        id: 99,
        country_name: "Estônia",
        country_code: "EE",
        phone_code: "+372",
      },
      {
        id: 100,
        country_name: "Equador",
        country_code: "EC",
        phone_code: "+593",
      },
      {
        id: 101,
        country_name: "Argélia",
        country_code: "DZ",
        phone_code: "+213",
      },
      {
        id: 102,
        country_name: "Uganda",
        country_code: "UG",
        phone_code: "+256",
      },
      {
        id: 103,
        country_name: "Ucrânia",
        country_code: "UA",
        phone_code: "+380",
      },
      {
        id: 104,
        country_name: "República Dominicana",
        country_code: "DO",
        phone_code: "+1809",
      },
      {
        id: 105,
        country_name: "Dominica",
        country_code: "DM",
        phone_code: "+1767",
      },
      {
        id: 106,
        country_name: "Dinamarca",
        country_code: "DK",
        phone_code: "+45",
      },
      {
        id: 107,
        country_name: "Tanzânia",
        country_code: "TZ",
        phone_code: "+255",
      },
      {
        id: 108,
        country_name: "Djibuti",
        country_code: "DJ",
        phone_code: "+253",
      },
      {
        id: 109,
        country_name: "Taiwan, China",
        country_code: "TW",
        phone_code: "+886",
      },
      {
        id: 110,
        country_name: "Alemanha",
        country_code: "DE",
        phone_code: "+49",
      },
      {
        id: 111,
        country_name: "Trindade e Tobago",
        country_code: "TT",
        phone_code: "+1868",
      },
      {
        id: 112,
        country_name: "Türkiye",
        country_code: "TR",
        phone_code: "+90",
      },
      {
        id: 113,
        country_name: "Tonga",
        country_code: "TO",
        phone_code: "+676",
      },
      {
        id: 114,
        country_name: "Tunísia",
        country_code: "TN",
        phone_code: "+216",
      },
      {
        id: 115,
        country_name: "Turquemenistão",
        country_code: "TM",
        phone_code: "+993",
      },
      {
        id: 116,
        country_name: "Níger",
        country_code: "NE",
        phone_code: "+227",
      },
      {
        id: 117,
        country_name: "Nova Caledônia",
        country_code: "NC",
        phone_code: "+687",
      },
      {
        id: 118,
        country_name: "Namíbia",
        country_code: "NA",
        phone_code: "+264",
      },
      {
        id: 119,
        country_name: "Moçambique",
        country_code: "MZ",
        phone_code: "+258",
      },
      {
        id: 120,
        country_name: "Malásia",
        country_code: "MY",
        phone_code: "+60",
      },
      {
        id: 121,
        country_name: "México",
        country_code: "MX",
        phone_code: "+52",
      },
      {
        id: 122,
        country_name: "Malauí",
        country_code: "MW",
        phone_code: "+265",
      },
      {
        id: 123,
        country_name: "Maldivas",
        country_code: "MV",
        phone_code: "+960",
      },
      {
        id: 124,
        country_name: "maurício",
        country_code: "MU",
        phone_code: "+230",
      },
      {
        id: 125,
        country_name: "Malta",
        country_code: "MT",
        phone_code: "+356",
      },
      {
        id: 126,
        country_name: "Montserrat",
        country_code: "MS",
        phone_code: "+1664",
      },
      {
        id: 127,
        country_name: "Mauritânia",
        country_code: "MR",
        phone_code: "+222",
      },
      {
        id: 128,
        country_name: "Martinica",
        country_code: "MQ",
        phone_code: "+596",
      },
      {
        id: 129,
        country_name: "Ilhas Marianas",
        country_code: "MP",
        phone_code: "+1670",
      },
      {
        id: 130,
        country_name: "Macau, China",
        country_code: "MO",
        phone_code: "+853",
      },
      {
        id: 131,
        country_name: "Mongólia",
        country_code: "MN",
        phone_code: "+976",
      },
      {
        id: 132,
        country_name: "Mianmar",
        country_code: "MM",
        phone_code: "+95",
      },
      {
        id: 133,
        country_name: "Mali",
        country_code: "ML",
        phone_code: "+223",
      },
      {
        id: 134,
        country_name: "Macedônia",
        country_code: "MK",
        phone_code: "+389",
      },
      {
        id: 135,
        country_name: "Madagáscar",
        country_code: "MG",
        phone_code: "+261",
      },
      {
        id: 136,
        country_name: "Montenegro",
        country_code: "ME",
        phone_code: "+382",
      },
      {
        id: 137,
        country_name: "Moldávia",
        country_code: "MD",
        phone_code: "+373",
      },
      {
        id: 138,
        country_name: "Mônaco",
        country_code: "MC",
        phone_code: "+377",
      },
      {
        id: 139,
        country_name: "Marrocos",
        country_code: "MA",
        phone_code: "+212",
      },
      {
        id: 140,
        country_name: "Líbia",
        country_code: "LY",
        phone_code: "+218",
      },
      {
        id: 141,
        country_name: "Letônia",
        country_code: "LV",
        phone_code: "+371",
      },
      {
        id: 142,
        country_name: "Luxemburgo",
        country_code: "LU",
        phone_code: "+352",
      },
      {
        id: 143,
        country_name: "Lituânia",
        country_code: "LT",
        phone_code: "+370",
      },
      {
        id: 144,
        country_name: "Lesoto",
        country_code: "LS",
        phone_code: "+266",
      },
      {
        id: 145,
        country_name: "Libéria",
        country_code: "LR",
        phone_code: "+231",
      },
      {
        id: 146,
        country_name: "Sri Lanka",
        country_code: "LK",
        phone_code: "+94",
      },
      {
        id: 147,
        country_name: "Liechtenstein",
        country_code: "LI",
        phone_code: "+423",
      },
      {
        id: 148,
        country_name: "Santa Lúcia",
        country_code: "LC",
        phone_code: "+1758",
      },
      {
        id: 149,
        country_name: "Líbano",
        country_code: "LB",
        phone_code: "+961",
      },
      {
        id: 150,
        country_name: "Laos",
        country_code: "LA",
        phone_code: "+856",
      },
      {
        id: 151,
        country_name: "Cazaquistão",
        country_code: "KZ",
        phone_code: "+7",
      },
      {
        id: 152,
        country_name: "Ilhas Cayman",
        country_code: "KY",
        phone_code: "+1345",
      },
      {
        id: 153,
        country_name: "Kuwait",
        country_code: "KW",
        phone_code: "+965",
      },
      {
        id: 154,
        country_name: "Coreia do Sul",
        country_code: "KR",
        phone_code: "+82",
      },
      {
        id: 155,
        country_name: "São Cristóvão e Nevis",
        country_code: "KN",
        phone_code: "+1869",
      },
      {
        id: 156,
        country_name: "Comores",
        country_code: "KM",
        phone_code: "+269",
      },
      {
        id: 157,
        country_name: "Kiribati",
        country_code: "KI",
        phone_code: "+686",
      },
      {
        id: 158,
        country_name: "Camboja",
        country_code: "KH",
        phone_code: "+855",
      },
      {
        id: 159,
        country_name: "Quirguistão",
        country_code: "KG",
        phone_code: "+996",
      },
      {
        id: 160,
        country_name: "Quênia",
        country_code: "KE",
        phone_code: "+254",
      },
      {
        id: 161,
        country_name: "Japão",
        country_code: "JP",
        phone_code: "+81",
      },
      {
        id: 162,
        country_name: "Jordânia",
        country_code: "JO",
        phone_code: "+962",
      },
      {
        id: 163,
        country_name: "Jamaica",
        country_code: "JM",
        phone_code: "+1876",
      },
      {
        id: 164,
        country_name: "Zimbábue",
        country_code: "ZW",
        phone_code: "+263",
      },
      {
        id: 165,
        country_name: "Zâmbia",
        country_code: "ZM",
        phone_code: "+260",
      },
      {
        id: 166,
        country_name: "Itália",
        country_code: "IT",
        phone_code: "+39",
      },
      {
        id: 167,
        country_name: "Islândia",
        country_code: "IS",
        phone_code: "+354",
      },
      {
        id: 168,
        country_name: "Irã",
        country_code: "IR",
        phone_code: "+98",
      },
      {
        id: 169,
        country_name: "Iraque",
        country_code: "IQ",
        phone_code: "+964",
      },
      {
        id: 170,
        country_name: "África do Sul",
        country_code: "ZA",
        phone_code: "+27",
      },
      {
        id: 171,
        country_name: "Índia",
        country_code: "IN",
        phone_code: "+91",
      },
      {
        id: 172,
        country_name: "Israel",
        country_code: "IL",
        phone_code: "+972",
      },
      {
        id: 173,
        country_name: "Irlanda",
        country_code: "IE",
        phone_code: "+353",
      },
      {
        id: 174,
        country_name: "Indonésia",
        country_code: "ID",
        phone_code: "+62",
      },
      {
        id: 175,
        country_name: "Maiote",
        country_code: "YT",
        phone_code: "+269",
      },
      {
        id: 176,
        country_name: "Hungria",
        country_code: "HU",
        phone_code: "+36",
      },
      {
        id: 177,
        country_name: "Iémen",
        country_code: "YE",
        phone_code: "+967",
      },
      {
        id: 178,
        country_name: "Haiti",
        country_code: "HT",
        phone_code: "+509",
      },
      {
        id: 179,
        country_name: "Croácia",
        country_code: "HR",
        phone_code: "+385",
      },
      {
        id: 180,
        country_name: "Honduras",
        country_code: "HN",
        phone_code: "+504",
      },
      {
        id: 181,
        country_name: "China Hong Kong",
        country_code: "HK",
        phone_code: "+852",
      },
      {
        id: 182,
        country_name: "Guiana",
        country_code: "GY",
        phone_code: "+592",
      },
      {
        id: 183,
        country_name: "República da Guiné-Bissau",
        country_code: "GW",
        phone_code: "+245",
      },
      {
        id: 184,
        country_name: "Guam",
        country_code: "GU",
        phone_code: "+1671",
      },
      {
        id: 185,
        country_name: "Guatemala",
        country_code: "GT",
        phone_code: "+502",
      },
      {
        id: 186,
        country_name: "Grécia",
        country_code: "GR",
        phone_code: "+30",
      },
      {
        id: 187,
        country_name: "Guiné Equatorial",
        country_code: "GQ",
        phone_code: "+240",
      },
      {
        id: 188,
        country_name: "Guadalupe",
        country_code: "GP",
        phone_code: "+590",
      },
      {
        id: 189,
        country_name: "Guiné",
        country_code: "GN",
        phone_code: "+224",
      },
      {
        id: 190,
        country_name: "Gâmbia",
        country_code: "GM",
        phone_code: "+220",
      },
      {
        id: 191,
        country_name: "Groenlândia",
        country_code: "GL",
        phone_code: "+299",
      },
      {
        id: 192,
        country_name: "Gibraltar",
        country_code: "GI",
        phone_code: "+350",
      },
      {
        id: 193,
        country_name: "Gana",
        country_code: "GH",
        phone_code: "+233",
      },
      {
        id: 194,
        country_name: "Guiana Francesa",
        country_code: "GF",
        phone_code: "+594",
      },
      {
        id: 195,
        country_name: "Geórgia",
        country_code: "GE",
        phone_code: "+995",
      },
      {
        id: 196,
        country_name: "Granada",
        country_code: "GD",
        phone_code: "+1473",
      },
      {
        id: 197,
        country_name: "Samoa",
        country_code: "WS",
        phone_code: "+685",
      },
      {
        id: 198,
        country_name: "REINO UNIDO.",
        country_code: "GB",
        phone_code: "+44",
      },
      {
        id: 199,
        country_name: "Gabão",
        country_code: "GA",
        phone_code: "+241",
      },
      {
        id: 200,
        country_name: "França",
        country_code: "FR",
        phone_code: "+33",
      },
      {
        id: 201,
        country_name: "Ilhas Faroe",
        country_code: "FO",
        phone_code: "+298",
      },
      {
        id: 202,
        country_name: "Fiji",
        country_code: "FJ",
        phone_code: "+679",
      },
      {
        id: 203,
        country_name: "Finlândia",
        country_code: "FI",
        phone_code: "+358",
      },
      {
        id: 204,
        country_name: "Vanuatu",
        country_code: "VU",
        phone_code: "+678",
      },
      {
        id: 205,
        country_name: "São Pedro e Miquelon",
        country_code: "PM",
        phone_code: "+508",
      },
      {
        id: 206,
        country_name: "Polônia",
        country_code: "PL",
        phone_code: "+48",
      },
      {
        id: 207,
        country_name: "Paquistão",
        country_code: "PK",
        phone_code: "+92",
      },
      {
        id: 208,
        country_name: "As Filipinas",
        country_code: "PH",
        phone_code: "+63",
      },
      {
        id: 209,
        country_name: "Papua Nova Guiné",
        country_code: "PG",
        phone_code: "+675",
      },
      {
        id: 210,
        country_name: "Polinésia Francesa",
        country_code: "PF",
        phone_code: "+689",
      },
      {
        id: 211,
        country_name: "Peru",
        country_code: "PE",
        phone_code: "+51",
      },
      {
        id: 212,
        country_name: "Panamá",
        country_code: "PA",
        phone_code: "+507",
      },
      {
        id: 213,
        country_name: "Omã",
        country_code: "OM",
        phone_code: "+968",
      },
      {
        id: 214,
        country_name: "Nova Zelândia",
        country_code: "NZ",
        phone_code: "+64",
      },
      {
        id: 215,
        country_name: "Nepal",
        country_code: "NP",
        phone_code: "+977",
      },
      {
        id: 216,
        country_name: "Noruega",
        country_code: "NO",
        phone_code: "+47",
      },
      {
        id: 217,
        country_name: "Holanda",
        country_code: "NL",
        phone_code: "+31",
      },
      {
        id: 218,
        country_name: "Nicarágua",
        country_code: "NI",
        phone_code: "+505",
      },
      {
        id: 219,
        country_name: "Continente Chinês",
        country_code: "CN",
        phone_code: "+86",
      },
    ],
    es_ES: [
      {
        id: 1,
        country_name: "Nigeria",
        country_code: "NG",
        phone_code: "+234",
      },
      {
        id: 2,
        country_name: "Canadá",
        country_code: "CA",
        phone_code: "+1",
      },
      {
        id: 3,
        country_name: "República Democrática del Congo",
        country_code: "CD",
        phone_code: "+243",
      },
      {
        id: 4,
        country_name: "Rusia",
        country_code: "RU",
        phone_code: "+7",
      },
      {
        id: 5,
        country_name: "Estados Unidos",
        country_code: "US",
        phone_code: "+1",
      },
      {
        id: 6,
        country_name: "Bélgica",
        country_code: "BE",
        phone_code: "+32",
      },
      {
        id: 7,
        country_name: "Bangladesh",
        country_code: "BD",
        phone_code: "+880",
      },
      {
        id: 8,
        country_name: "Serbia",
        country_code: "RS",
        phone_code: "+381",
      },
      {
        id: 9,
        country_name: "Barbados",
        country_code: "BB",
        phone_code: "+1246",
      },
      {
        id: 10,
        country_name: "Bosnia y Herzegovina",
        country_code: "BA",
        phone_code: "+387",
      },
      {
        id: 11,
        country_name: "Rumania",
        country_code: "RO",
        phone_code: "+40",
      },
      {
        id: 12,
        country_name: "Azerbaiyán",
        country_code: "AZ",
        phone_code: "+994",
      },
      {
        id: 13,
        country_name: "Aruba",
        country_code: "AW",
        phone_code: "+297",
      },
      {
        id: 14,
        country_name: "Australia",
        country_code: "AU",
        phone_code: "+61",
      },
      {
        id: 15,
        country_name: "Austria",
        country_code: "AT",
        phone_code: "+43",
      },
      {
        id: 16,
        country_name: "Reunión",
        country_code: "RE",
        phone_code: "+262",
      },
      {
        id: 17,
        country_name: "Samoa Americana",
        country_code: "AS",
        phone_code: "+1684",
      },
      {
        id: 18,
        country_name: "Argentina",
        country_code: "AR",
        phone_code: "+54",
      },
      {
        id: 19,
        country_name: "Angola",
        country_code: "AO",
        phone_code: "+244",
      },
      {
        id: 20,
        country_name: "Armenia",
        country_code: "AM",
        phone_code: "+374",
      },
      {
        id: 21,
        country_name: "Albania",
        country_code: "AL",
        phone_code: "+355",
      },
      {
        id: 22,
        country_name: "Anguila",
        country_code: "AI",
        phone_code: "+1264",
      },
      {
        id: 23,
        country_name: "Antigua y Barbuda",
        country_code: "AG",
        phone_code: "+1268",
      },
      {
        id: 24,
        country_name: "Afganistán",
        country_code: "AF",
        phone_code: "+93",
      },
      {
        id: 25,
        country_name: "Emiratos Árabes Unidos",
        country_code: "AE",
        phone_code: "+971",
      },
      {
        id: 26,
        country_name: "Andorra",
        country_code: "AD",
        phone_code: "+376",
      },
      {
        id: 27,
        country_name: "Isla Ascencion",
        country_code: "AC",
        phone_code: "+247",
      },
      {
        id: 28,
        country_name: "Katar",
        country_code: "QA",
        phone_code: "+974",
      },
      {
        id: 29,
        country_name: "Paraguay",
        country_code: "PY",
        phone_code: "+595",
      },
      {
        id: 30,
        country_name: "Palaos",
        country_code: "PW",
        phone_code: "+680",
      },
      {
        id: 31,
        country_name: "Portugal",
        country_code: "PT",
        phone_code: "+351",
      },
      {
        id: 32,
        country_name: "Territorios palestinos",
        country_code: "PS",
        phone_code: "+970",
      },
      {
        id: 33,
        country_name: "Puerto Rico",
        country_code: "PR",
        phone_code: "+1787",
      },
      {
        id: 34,
        country_name: "Somalia",
        country_code: "SO",
        phone_code: "+252",
      },
      {
        id: 35,
        country_name: "Senegal",
        country_code: "SN",
        phone_code: "+221",
      },
      {
        id: 36,
        country_name: "San Marino",
        country_code: "SM",
        phone_code: "+378",
      },
      {
        id: 37,
        country_name: "Sierra Leona",
        country_code: "SL",
        phone_code: "+232",
      },
      {
        id: 38,
        country_name: "Eslovaquia",
        country_code: "SK",
        phone_code: "+421",
      },
      {
        id: 39,
        country_name: "Belice",
        country_code: "BZ",
        phone_code: "+501",
      },
      {
        id: 40,
        country_name: "Bielorrusia",
        country_code: "BY",
        phone_code: "+375",
      },
      {
        id: 41,
        country_name: "Eslovenia",
        country_code: "SI",
        phone_code: "+386",
      },
      {
        id: 42,
        country_name: "Botsuana",
        country_code: "BW",
        phone_code: "+267",
      },
      {
        id: 43,
        country_name: "Singapur",
        country_code: "SG",
        phone_code: "+65",
      },
      {
        id: 44,
        country_name: "Suecia",
        country_code: "SE",
        phone_code: "+46",
      },
      {
        id: 45,
        country_name: "Bután",
        country_code: "BT",
        phone_code: "+975",
      },
      {
        id: 46,
        country_name: "Sudán",
        country_code: "SD",
        phone_code: "+249",
      },
      {
        id: 47,
        country_name: "bahamas",
        country_code: "BS",
        phone_code: "+1242",
      },
      {
        id: 48,
        country_name: "Brasil",
        country_code: "BR",
        phone_code: "+55",
      },
      {
        id: 49,
        country_name: "Seychelles",
        country_code: "SC",
        phone_code: "+248",
      },
      {
        id: 50,
        country_name: "Islas Salomón",
        country_code: "SB",
        phone_code: "+677",
      },
      {
        id: 51,
        country_name: "Bonaire, San Eustaquio Y Saba",
        country_code: "BQ",
        phone_code: "+599",
      },
      {
        id: 52,
        country_name: "Arabia Saudita",
        country_code: "SA",
        phone_code: "+966",
      },
      {
        id: 53,
        country_name: "Bolivia",
        country_code: "BO",
        phone_code: "+591",
      },
      {
        id: 54,
        country_name: "Brunéi",
        country_code: "BN",
        phone_code: "+673",
      },
      {
        id: 55,
        country_name: "Islas Bermudas",
        country_code: "BM",
        phone_code: "+1441",
      },
      {
        id: 56,
        country_name: "Benín",
        country_code: "BJ",
        phone_code: "+229",
      },
      {
        id: 57,
        country_name: "Burundi",
        country_code: "BI",
        phone_code: "+257",
      },
      {
        id: 58,
        country_name: "Baréin",
        country_code: "BH",
        phone_code: "+973",
      },
      {
        id: 59,
        country_name: "Bulgaria",
        country_code: "BG",
        phone_code: "+359",
      },
      {
        id: 60,
        country_name: "Ruanda",
        country_code: "RW",
        phone_code: "+250",
      },
      {
        id: 61,
        country_name: "Burkina Faso",
        country_code: "BF",
        phone_code: "+226",
      },
      {
        id: 62,
        country_name: "Timor Oriental",
        country_code: "TL",
        phone_code: "+670",
      },
      {
        id: 63,
        country_name: "República Checa",
        country_code: "CZ",
        phone_code: "+420",
      },
      {
        id: 64,
        country_name: "Tayikistán",
        country_code: "TJ",
        phone_code: "+992",
      },
      {
        id: 65,
        country_name: "Chipre",
        country_code: "CY",
        phone_code: "+357",
      },
      {
        id: 66,
        country_name: "Tailandia",
        country_code: "TH",
        phone_code: "+66",
      },
      {
        id: 67,
        country_name: "Curazao",
        country_code: "CW",
        phone_code: "+599",
      },
      {
        id: 68,
        country_name: "Ir",
        country_code: "TG",
        phone_code: "+228",
      },
      {
        id: 69,
        country_name: "Capa",
        country_code: "CV",
        phone_code: "+238",
      },
      {
        id: 70,
        country_name: "Cuba",
        country_code: "CU",
        phone_code: "+53",
      },
      {
        id: 71,
        country_name: "Chad",
        country_code: "TD",
        phone_code: "+235",
      },
      {
        id: 72,
        country_name: "Costa Rica",
        country_code: "CR",
        phone_code: "+506",
      },
      {
        id: 73,
        country_name: "Islas Turcas y Caicos",
        country_code: "TC",
        phone_code: "+1649",
      },
      {
        id: 74,
        country_name: "Colombia",
        country_code: "CO",
        phone_code: "+57",
      },
      {
        id: 75,
        country_name: "Camerún",
        country_code: "CM",
        phone_code: "+237",
      },
      {
        id: 76,
        country_name: "Chile",
        country_code: "CL",
        phone_code: "+56",
      },
      {
        id: 77,
        country_name: "Isla",
        country_code: "CK",
        phone_code: "+682",
      },
      {
        id: 78,
        country_name: "Suazilandia",
        country_code: "SZ",
        phone_code: "+268",
      },
      {
        id: 79,
        country_name: "Siria",
        country_code: "SY",
        phone_code: "+963",
      },
      {
        id: 80,
        country_name: "Costa de Marfil",
        country_code: "CI",
        phone_code: "+225",
      },
      {
        id: 81,
        country_name: "Suiza",
        country_code: "CH",
        phone_code: "+41",
      },
      {
        id: 82,
        country_name: "Sint Maarten (parte holandesa)",
        country_code: "SX",
        phone_code: "+1721",
      },
      {
        id: 83,
        country_name: "La republica del congo",
        country_code: "CG",
        phone_code: "+242",
      },
      {
        id: 84,
        country_name: "El Salvador",
        country_code: "SV",
        phone_code: "+503",
      },
      {
        id: 85,
        country_name: "República Centroafricana",
        country_code: "CF",
        phone_code: "+236",
      },
      {
        id: 86,
        country_name: "Santo Tomé y Príncipe",
        country_code: "ST",
        phone_code: "+239",
      },
      {
        id: 87,
        country_name: "Sudán del Sur",
        country_code: "SS",
        phone_code: "+211",
      },
      {
        id: 88,
        country_name: "Surinam",
        country_code: "SR",
        phone_code: "+597",
      },
      {
        id: 89,
        country_name: "Vietnam",
        country_code: "VN",
        phone_code: "+84",
      },
      {
        id: 90,
        country_name: "Islas Vírgenes de los Estados Unidos",
        country_code: "VI",
        phone_code: "+1284",
      },
      {
        id: 91,
        country_name: "Islas Vírgenes Británicas",
        country_code: "VG",
        phone_code: "+1340",
      },
      {
        id: 92,
        country_name: "Venezuela",
        country_code: "VE",
        phone_code: "+58",
      },
      {
        id: 93,
        country_name: "Etiopía",
        country_code: "ET",
        phone_code: "+251",
      },
      {
        id: 94,
        country_name: "España",
        country_code: "ES",
        phone_code: "+34",
      },
      {
        id: 95,
        country_name: "San Vicente y las Granadinas",
        country_code: "VC",
        phone_code: "+1784",
      },
      {
        id: 96,
        country_name: "Uzbekistán",
        country_code: "UZ",
        phone_code: "+998",
      },
      {
        id: 97,
        country_name: "Uruguay",
        country_code: "UY",
        phone_code: "+598",
      },
      {
        id: 98,
        country_name: "Egipto",
        country_code: "EG",
        phone_code: "+20",
      },
      {
        id: 99,
        country_name: "Estonia",
        country_code: "EE",
        phone_code: "+372",
      },
      {
        id: 100,
        country_name: "Ecuador",
        country_code: "EC",
        phone_code: "+593",
      },
      {
        id: 101,
        country_name: "Argélia",
        country_code: "DZ",
        phone_code: "+213",
      },
      {
        id: 102,
        country_name: "Uganda",
        country_code: "UG",
        phone_code: "+256",
      },
      {
        id: 103,
        country_name: "Ucrânia",
        country_code: "UA",
        phone_code: "+380",
      },
      {
        id: 104,
        country_name: "República Dominicana",
        country_code: "DO",
        phone_code: "+1809",
      },
      {
        id: 105,
        country_name: "Dominica",
        country_code: "DM",
        phone_code: "+1767",
      },
      {
        id: 106,
        country_name: "Dinamarca",
        country_code: "DK",
        phone_code: "+45",
      },
      {
        id: 107,
        country_name: "Tanzânia",
        country_code: "TZ",
        phone_code: "+255",
      },
      {
        id: 108,
        country_name: "Djibuti",
        country_code: "DJ",
        phone_code: "+253",
      },
      {
        id: 109,
        country_name: "Taiwan, China",
        country_code: "TW",
        phone_code: "+886",
      },
      {
        id: 110,
        country_name: "Alemanha",
        country_code: "DE",
        phone_code: "+49",
      },
      {
        id: 111,
        country_name: "Trindade e Tobago",
        country_code: "TT",
        phone_code: "+1868",
      },
      {
        id: 112,
        country_name: "Türkiye",
        country_code: "TR",
        phone_code: "+90",
      },
      {
        id: 113,
        country_name: "Tonga",
        country_code: "TO",
        phone_code: "+676",
      },
      {
        id: 114,
        country_name: "Tunísia",
        country_code: "TN",
        phone_code: "+216",
      },
      {
        id: 115,
        country_name: "Turquemenistão",
        country_code: "TM",
        phone_code: "+993",
      },
      {
        id: 116,
        country_name: "Níger",
        country_code: "NE",
        phone_code: "+227",
      },
      {
        id: 117,
        country_name: "Nova Caledônia",
        country_code: "NC",
        phone_code: "+687",
      },
      {
        id: 118,
        country_name: "Namíbia",
        country_code: "NA",
        phone_code: "+264",
      },
      {
        id: 119,
        country_name: "Moçambique",
        country_code: "MZ",
        phone_code: "+258",
      },
      {
        id: 120,
        country_name: "Malásia",
        country_code: "MY",
        phone_code: "+60",
      },
      {
        id: 121,
        country_name: "México",
        country_code: "MX",
        phone_code: "+52",
      },
      {
        id: 122,
        country_name: "Malauí",
        country_code: "MW",
        phone_code: "+265",
      },
      {
        id: 123,
        country_name: "Maldivas",
        country_code: "MV",
        phone_code: "+960",
      },
      {
        id: 124,
        country_name: "maurício",
        country_code: "MU",
        phone_code: "+230",
      },
      {
        id: 125,
        country_name: "Malta",
        country_code: "MT",
        phone_code: "+356",
      },
      {
        id: 126,
        country_name: "Montserrat",
        country_code: "MS",
        phone_code: "+1664",
      },
      {
        id: 127,
        country_name: "Mauritânia",
        country_code: "MR",
        phone_code: "+222",
      },
      {
        id: 128,
        country_name: "Martinica",
        country_code: "MQ",
        phone_code: "+596",
      },
      {
        id: 129,
        country_name: "Ilhas Marianas",
        country_code: "MP",
        phone_code: "+1670",
      },
      {
        id: 130,
        country_name: "Macau, China",
        country_code: "MO",
        phone_code: "+853",
      },
      {
        id: 131,
        country_name: "Mongólia",
        country_code: "MN",
        phone_code: "+976",
      },
      {
        id: 132,
        country_name: "Mianmar",
        country_code: "MM",
        phone_code: "+95",
      },
      {
        id: 133,
        country_name: "Mali",
        country_code: "ML",
        phone_code: "+223",
      },
      {
        id: 134,
        country_name: "Macedônia",
        country_code: "MK",
        phone_code: "+389",
      },
      {
        id: 135,
        country_name: "Madagáscar",
        country_code: "MG",
        phone_code: "+261",
      },
      {
        id: 136,
        country_name: "Montenegro",
        country_code: "ME",
        phone_code: "+382",
      },
      {
        id: 137,
        country_name: "Moldávia",
        country_code: "MD",
        phone_code: "+373",
      },
      {
        id: 138,
        country_name: "Mônaco",
        country_code: "MC",
        phone_code: "+377",
      },
      {
        id: 139,
        country_name: "Marrocos",
        country_code: "MA",
        phone_code: "+212",
      },
      {
        id: 140,
        country_name: "Líbia",
        country_code: "LY",
        phone_code: "+218",
      },
      {
        id: 141,
        country_name: "Letônia",
        country_code: "LV",
        phone_code: "+371",
      },
      {
        id: 142,
        country_name: "Luxemburgo",
        country_code: "LU",
        phone_code: "+352",
      },
      {
        id: 143,
        country_name: "Lituânia",
        country_code: "LT",
        phone_code: "+370",
      },
      {
        id: 144,
        country_name: "Lesoto",
        country_code: "LS",
        phone_code: "+266",
      },
      {
        id: 145,
        country_name: "Libéria",
        country_code: "LR",
        phone_code: "+231",
      },
      {
        id: 146,
        country_name: "Sri Lanka",
        country_code: "LK",
        phone_code: "+94",
      },
      {
        id: 147,
        country_name: "Liechtenstein",
        country_code: "LI",
        phone_code: "+423",
      },
      {
        id: 148,
        country_name: "Santa Lúcia",
        country_code: "LC",
        phone_code: "+1758",
      },
      {
        id: 149,
        country_name: "Líbano",
        country_code: "LB",
        phone_code: "+961",
      },
      {
        id: 150,
        country_name: "Laos",
        country_code: "LA",
        phone_code: "+856",
      },
      {
        id: 151,
        country_name: "Cazaquistão",
        country_code: "KZ",
        phone_code: "+7",
      },
      {
        id: 152,
        country_name: "Ilhas Cayman",
        country_code: "KY",
        phone_code: "+1345",
      },
      {
        id: 153,
        country_name: "Kuwait",
        country_code: "KW",
        phone_code: "+965",
      },
      {
        id: 154,
        country_name: "Coreia do Sul",
        country_code: "KR",
        phone_code: "+82",
      },
      {
        id: 155,
        country_name: "São Cristóvão e Nevis",
        country_code: "KN",
        phone_code: "+1869",
      },
      {
        id: 156,
        country_name: "Comores",
        country_code: "KM",
        phone_code: "+269",
      },
      {
        id: 157,
        country_name: "Kiribati",
        country_code: "KI",
        phone_code: "+686",
      },
      {
        id: 158,
        country_name: "Camboja",
        country_code: "KH",
        phone_code: "+855",
      },
      {
        id: 159,
        country_name: "Quirguistão",
        country_code: "KG",
        phone_code: "+996",
      },
      {
        id: 160,
        country_name: "Quênia",
        country_code: "KE",
        phone_code: "+254",
      },
      {
        id: 161,
        country_name: "Japão",
        country_code: "JP",
        phone_code: "+81",
      },
      {
        id: 162,
        country_name: "Jordânia",
        country_code: "JO",
        phone_code: "+962",
      },
      {
        id: 163,
        country_name: "Jamaica",
        country_code: "JM",
        phone_code: "+1876",
      },
      {
        id: 164,
        country_name: "Zimbábue",
        country_code: "ZW",
        phone_code: "+263",
      },
      {
        id: 165,
        country_name: "Zâmbia",
        country_code: "ZM",
        phone_code: "+260",
      },
      {
        id: 166,
        country_name: "Itália",
        country_code: "IT",
        phone_code: "+39",
      },
      {
        id: 167,
        country_name: "Islândia",
        country_code: "IS",
        phone_code: "+354",
      },
      {
        id: 168,
        country_name: "Irã",
        country_code: "IR",
        phone_code: "+98",
      },
      {
        id: 169,
        country_name: "Iraque",
        country_code: "IQ",
        phone_code: "+964",
      },
      {
        id: 170,
        country_name: "África do Sul",
        country_code: "ZA",
        phone_code: "+27",
      },
      {
        id: 171,
        country_name: "Índia",
        country_code: "IN",
        phone_code: "+91",
      },
      {
        id: 172,
        country_name: "Israel",
        country_code: "IL",
        phone_code: "+972",
      },
      {
        id: 173,
        country_name: "Irlanda",
        country_code: "IE",
        phone_code: "+353",
      },
      {
        id: 174,
        country_name: "Indonésia",
        country_code: "ID",
        phone_code: "+62",
      },
      {
        id: 175,
        country_name: "Maiote",
        country_code: "YT",
        phone_code: "+269",
      },
      {
        id: 176,
        country_name: "Hungria",
        country_code: "HU",
        phone_code: "+36",
      },
      {
        id: 177,
        country_name: "Iémen",
        country_code: "YE",
        phone_code: "+967",
      },
      {
        id: 178,
        country_name: "Haiti",
        country_code: "HT",
        phone_code: "+509",
      },
      {
        id: 179,
        country_name: "Croácia",
        country_code: "HR",
        phone_code: "+385",
      },
      {
        id: 180,
        country_name: "Honduras",
        country_code: "HN",
        phone_code: "+504",
      },
      {
        id: 181,
        country_name: "China Hong Kong",
        country_code: "HK",
        phone_code: "+852",
      },
      {
        id: 182,
        country_name: "Guiana",
        country_code: "GY",
        phone_code: "+592",
      },
      {
        id: 183,
        country_name: "República da Guiné-Bissau",
        country_code: "GW",
        phone_code: "+245",
      },
      {
        id: 184,
        country_name: "Guam",
        country_code: "GU",
        phone_code: "+1671",
      },
      {
        id: 185,
        country_name: "Guatemala",
        country_code: "GT",
        phone_code: "+502",
      },
      {
        id: 186,
        country_name: "Grécia",
        country_code: "GR",
        phone_code: "+30",
      },
      {
        id: 187,
        country_name: "Guiné Equatorial",
        country_code: "GQ",
        phone_code: "+240",
      },
      {
        id: 188,
        country_name: "Guadalupe",
        country_code: "GP",
        phone_code: "+590",
      },
      {
        id: 189,
        country_name: "Guiné",
        country_code: "GN",
        phone_code: "+224",
      },
      {
        id: 190,
        country_name: "Gâmbia",
        country_code: "GM",
        phone_code: "+220",
      },
      {
        id: 191,
        country_name: "Groenlândia",
        country_code: "GL",
        phone_code: "+299",
      },
      {
        id: 192,
        country_name: "Gibraltar",
        country_code: "GI",
        phone_code: "+350",
      },
      {
        id: 193,
        country_name: "Gana",
        country_code: "GH",
        phone_code: "+233",
      },
      {
        id: 194,
        country_name: "Guiana Francesa",
        country_code: "GF",
        phone_code: "+594",
      },
      {
        id: 195,
        country_name: "Geórgia",
        country_code: "GE",
        phone_code: "+995",
      },
      {
        id: 196,
        country_name: "Granada",
        country_code: "GD",
        phone_code: "+1473",
      },
      {
        id: 197,
        country_name: "Samoa",
        country_code: "WS",
        phone_code: "+685",
      },
      {
        id: 198,
        country_name: "REINO UNIDO.",
        country_code: "GB",
        phone_code: "+44",
      },
      {
        id: 199,
        country_name: "Gabão",
        country_code: "GA",
        phone_code: "+241",
      },
      {
        id: 200,
        country_name: "França",
        country_code: "FR",
        phone_code: "+33",
      },
      {
        id: 201,
        country_name: "Islas Faroe",
        country_code: "FO",
        phone_code: "+298",
      },
      {
        id: 202,
        country_name: "Fiyi",
        country_code: "FJ",
        phone_code: "+679",
      },
      {
        id: 203,
        country_name: "Finlandia",
        country_code: "FI",
        phone_code: "+358",
      },
      {
        id: 204,
        country_name: "Vanuatu",
        country_code: "VU",
        phone_code: "+678",
      },
      {
        id: 205,
        country_name: "San Pedro y Miquelón",
        country_code: "PM",
        phone_code: "+508",
      },
      {
        id: 206,
        country_name: "Polonia",
        country_code: "PL",
        phone_code: "+48",
      },
      {
        id: 207,
        country_name: "Pakistán",
        country_code: "PK",
        phone_code: "+92",
      },
      {
        id: 208,
        country_name: "Las Filipinas",
        country_code: "PH",
        phone_code: "+63",
      },
      {
        id: 209,
        country_name: "Papúa Nueva Guinea",
        country_code: "PG",
        phone_code: "+675",
      },
      {
        id: 210,
        country_name: "Polinesia francés",
        country_code: "PF",
        phone_code: "+689",
      },
      {
        id: 211,
        country_name: "Perú",
        country_code: "PE",
        phone_code: "+51",
      },
      {
        id: 212,
        country_name: "Panamá",
        country_code: "PA",
        phone_code: "+507",
      },
      {
        id: 213,
        country_name: "Omán",
        country_code: "OM",
        phone_code: "+968",
      },
      {
        id: 214,
        country_name: "Nueva Zelanda",
        country_code: "NZ",
        phone_code: "+64",
      },
      {
        id: 215,
        country_name: "Nepal",
        country_code: "NP",
        phone_code: "+977",
      },
      {
        id: 216,
        country_name: "Noruega",
        country_code: "NO",
        phone_code: "+47",
      },
      {
        id: 217,
        country_name: "Países Bajos",
        country_code: "NL",
        phone_code: "+31",
      },
      {
        id: 218,
        country_name: "Nicaragua",
        country_code: "NI",
        phone_code: "+505",
      },
      {
        id: 219,
        country_name: "China continental",
        country_code: "CN",
        phone_code: "+86",
      },
    ],
  };
  
  export default country;
  