import * as React from "react";
import { Box, Menu, MenuItem, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import { useSelector } from "react-redux";

const RowCss = {
  height: 48,
  paddingLeft: 16,
  paddingRight: 16,
  display: 'flex',
  alignItems: 'center'
}

function SoftWareSelect() {
  let { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const skin = useSelector((state) => state.skin.value);
  let textColor = { color: skin.color };
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = [
    t("mainMenu.type1"),
    t("mainMenu.type2"),
    t("mainMenu.type3"),
    t("mainMenu.type4"),
    t("mainMenu.type5"),
    t("mainMenu.type6"),
    t("mainMenu2.type7"),
    t("mainMenu2.type8"),
    t("mainMenu2.type9"),
  ];
  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    switch (index) {
      case 0:
        navigate("/type/pos");
        break;
      case 1:
        navigate("/type/admin");
        break;
      case 2:
        navigate("/type/sale");
        break;
      case 3:
        navigate("/type/kitchen");
        break;
      case 4:
        navigate("/type/shop");
        break;
      case 5:
        navigate("/type/selfhelp");
        break;
      case 6:
        navigate("/type/diningCar");
        break;
      case 7:
        navigate("/type/scan");
        break;
      case 8:
        navigate("/type/customer");
        break;
      default:
        navigate("/type/pos");
        break;
    }
  };
  return (
    <div style={RowCss}>
        <Button
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="text"
          disableElevation
          onClick={handleClickListItem}
          endIcon={open ? <ArrowDropUpSharpIcon /> : <ArrowDropDownSharpIcon />}
          sx={{...textColor, padding: 0, minWidth: 'auto', fontSize: 16}}
        >
          {t("mainMenu.software")}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === selectedIndex}
              onClick={() => handleMenuItemClick(index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
  )
}

function HeaderMenuMini() {
  let { t } = useTranslation();
  const navigate = useNavigate();
  
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const options2 = [
    t("mainMenu.sceneType1"),
    t("mainMenu.sceneType2"),
    t("mainMenu.sceneType3"),
    t("mainMenu.sceneType4"),
    t("mainMenu.sceneType5"),
  ];
  const handleClickListItem2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleMenuItemClick2 = (index) => {
    setSelectedIndex2(index);
    setAnchorEl2(null);
    switch (index) {
      case 0:
        navigate("/scene/restaurant");
        break;
      case 1:
        navigate("/scene/bar");
        break;
      case 2:
        navigate("/scene/minimart");
        break;
      case 3:
        navigate("/scene/clothing");
        break;
      case 4:
        navigate("/scene/cafe");
        break;
      default:
        navigate("/scene/restaurant");
        break;
    }
  };

  const [selectedIndex2, setSelectedIndex2] = React.useState(0);

  const skin = useSelector((state) => state.skin.value);

  let textColor = { color: skin.color };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: skin.menuBg,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: 'column'
      }}
    >
      <Link href="#/feature/index" sx={textColor} underline="none" style={RowCss}>
        {t("mainMenu.feature")}
      </Link>
      <SoftWareSelect />
      <Link href="#/hardware/index" sx={textColor} underline="none" style={RowCss}>
        {t("mainMenu.hardware")}
      </Link>
      <div style={RowCss}>
        <Button
          aria-controls={open2 ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open2 ? "true" : undefined}
          variant="text"
          disableElevation
          onClick={handleClickListItem2}
          endIcon={
            open2 ? <ArrowDropUpSharpIcon /> : <ArrowDropDownSharpIcon />
          }
          sx={{...textColor, padding: 0, minWidth: 'auto', fontSize: 16}}
        >
          {t("mainMenu.businessType")}
        </Button>
        <Menu
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
        >
          {options2.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === selectedIndex2}
              onClick={() => handleMenuItemClick2(index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <Link href="#/price/index" sx={textColor} underline="none" style={RowCss}>
        {t("order.text")}
      </Link>
      <Link href="#/partners/index" sx={textColor} underline="none" style={RowCss}>
        {t("partners.text")}
      </Link>
      <Link href="#/contactUs/index" sx={textColor} underline="none" style={RowCss}>
        {t("mainMenu.contactUs")}
      </Link>
      <Link href="#/help/index" sx={textColor} underline="none" style={RowCss}>
        {t("mainMenu.help")}
      </Link>
    </Box>
  );
}

export default HeaderMenuMini;
