import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import down from "../assets/logo/down.png";
import DownBoxSvg from "../components/downBoxSvg";
import { useState } from "react";
import MacOS from "./svg/macOS";
import Windows from "./svg/windows";
import Android from "./svg/android";
import QRCode from 'qrcode.react';

const Text = styled("div")(() => ({
  marginTop: 20,
  color: "#fff",
  fontSize: 29.09,
  fontWeight: 700,
  textAlign: 'center'
}));

const downBoxItem = (theme)=> {
  return {
    [theme.breakpoints.up("xl")]: {
      transform: 'scale(1)'
    },
    [theme.breakpoints.down("xl")]: {
      transform: 'scale(1)'
    },
    [theme.breakpoints.down("lg")]: {
      transform: 'scale(0.75)'
    },
    [theme.breakpoints.down("md")]: {
      transform: 'scale(0.75)'
    },
    [theme.breakpoints.down("sm")]: {
      transform: 'scale(0.5)'
    },
    [theme.breakpoints.down("xs")]: {
      transform: 'scale(0.5)',
      '& > div': {
        marginRight: 4
      }
    },
  }
}

const BoxOuter = styled(Box)(({theme}) => ({
  position: "relative",
  ...downBoxItem(theme)
}));

const DownBox = styled("div")(({theme}) => ({
  position: "relative"
}));

const DownBoxItem = styled("div")(({theme}) => ({
  position: "relative"
}));

const flexCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const downLogCss = {
  width: 24,
  height: 24,
  position: "absolute",
  right: 20,
  top: 20,
};

const systemBoxCss = {
  ...flexCenter,
  flexDirection: "column",
};

const systemPicCss = {
  width: 71,
  height: 71,
};

function DownFooterWeb({
  bgColor,
  qrBgColor,
  qrcode,
  showMac = true,
  showWin = true,
  showAndriod = true,
  urlName
}) {
  const DonwBoxCss = {
    width: 240,
    height: 240,
    bgcolor: bgColor,
    borderRadius: "24px",
    position: "relative",
    ...flexCenter,
  };
  let { t } = useTranslation();
  let [zIndex, setZIndex] = useState(-6);
  const showDownScanCode = () => {
    setZIndex(10);
  };
  const hideDownScanCode = () => {
    setZIndex(-6);
  };
  const baseUrl = 'https://download.toposfy.com/devpackage/' + urlName
  const macUrl = baseUrl + '.ipa'
  const winUrl = baseUrl + '.exe'
  const andUrl = baseUrl + '.apk'
  return (
    <BoxOuter sx={{ display: "flex" }}>
      {showMac ? (
        <DownBoxItem sx={{ ...DonwBoxCss, mr: 4, display: { xs: "none", md: "flex" } }}>
          <a href={macUrl} target="downloadFile" download><img src={down} style={downLogCss} alt="" /></a>
          <Box sx={systemBoxCss}>
            <div style={systemPicCss}>
              <MacOS color={bgColor} />
            </div>
            <Text>macOS</Text>
          </Box>
        </DownBoxItem>
      ) : null}
      {showWin ? (
        <DownBoxItem sx={{ ...DonwBoxCss, mr: 4, display: { xs: "none", md: "flex" } }}>
          <a href={winUrl} target="downloadFile" download><img src={down} style={downLogCss} alt="" /></a>
          <Box sx={systemBoxCss}>
            <div style={systemPicCss}>
              <Windows color={bgColor} />
            </div>
            <Text>Windows</Text>
          </Box>
        </DownBoxItem>
      ) : null}
      {showAndriod ? (
        <DownBox onMouseMove={showDownScanCode} onMouseLeave={hideDownScanCode}>
          <Box
            sx={{
              borderRadius: "24px",
              width: 240,
              height: 240,
              position: "relative",
              ...flexCenter,
            }}
          >
            <Box sx={systemBoxCss}>
              <div style={{ position: "absolute", top: -4, zIndex: 8 }}>
                <DownBoxSvg {...{ bgColor: bgColor }} />
              </div>
              <div style={{ ...systemBoxCss, position: "absolute", zIndex: 8 }}>
                <div style={systemPicCss}>
                  <Android color={bgColor} />
                </div>
                <Text>Android</Text>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: qrBgColor,
              borderRadius: "24px",
              width: 240,
              height: 240,
              position: "absolute",
              ...flexCenter,
              top: 0,
            }}
          >
            <img
              src={qrcode}
              style={{ position: "absolute", top: 12, right: 6 }}
              alt=""
            />
          </Box>
          <Box
            sx={{ ...DonwBoxCss, position: "absolute", top: 0, zIndex: zIndex }}
          >
            <Box sx={systemBoxCss}>
              <div
                style={{
                  width: 130,
                  height: 130,
                  backgroundColor: "#fff",
                  ...flexCenter,
                }}
              >
                <QRCode value={andUrl} style={{ ...systemPicCss, width: 110, height: 110 }} />
              </div>
              <div style={systemBoxCss}>
                <Text style={{ fontSize: 19.39, fontWeight: 700 }}>
                  {t("businessType.scanDown")}
                </Text>
                <Text
                  style={{ fontSize: 12.93, fontWeight: 400, marginTop: "8px" }}
                >
                  {t("businessType.version")}: 3.21.2.23
                </Text>
              </div>
            </Box>
          </Box>
        </DownBox>
      ) : null}
    </BoxOuter>
  );
}
export default DownFooterWeb;
