import * as React from "react";
import { styled } from "@mui/material/styles";
import Header from "./layout/header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./layout/footer/index";
import { useDispatch } from "react-redux";
import { changeSkin } from "./store/skinSlice";
import { useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import editInfoImg from "./assets/logo/editInfo.png";
import { useNavigate } from "react-router-dom";

const ProductHeroLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.black,
  height: "100vh",
  width: "100%",
  minHeight: 500,
  position: "relative",
}));

const FixedBtn = styled("img")(({ theme }) => ({
  cursor: "pointer",
  position: "fixed",
  right: 30,
  bottom: 50,
  width: 48,
  '&:hover': {
    width: 52,
    transition: 'all 0.25s'
  }
}));

const layoutTheme = createTheme({
  breakpoints: {
    values: {
      xs: 250,
      xm: 520,
      sm: 760,
      md: 960,
      mg: 961,
      lg: 1280,
      lgUp: 1281,
      gx: 1360,
      xl: 1440,
    },
  },
});

function App() {
  const dispatch = useDispatch();
  let loc = useLocation();
  useEffect(() => {
    let data = { light: true, barBg: "#eee", menuBg: "#fff", color: "#000" };
    if (loc.pathname.includes("/scene") || loc.pathname.includes("/partners")) {
      data = {
        light: false,
        barBg: "rgba(244, 244, 245, 1)",
        menuBg: "rgb(227 227 227)",
        color: "#000",
      };
    }
    dispatch(changeSkin(data));
  });

  const navigate = useNavigate();
  const toPage = () => {
    navigate("/contactUs/index")
  }
  return (
    <ThemeProvider theme={layoutTheme}>
      <ProductHeroLayoutRoot>
        <Header />
        <Outlet />
        <Footer />
        <FixedBtn src={editInfoImg} alt="" onClick={toPage} />
      </ProductHeroLayoutRoot>
    </ThemeProvider>
  );
}

export default App;
