import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ContainerBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("xl")]: {},
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    width: 634,
  },
  [theme.breakpoints.down("xl")]: {
    width: "40%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "50%",
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
    paddingRight: 16,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

export const PicOuterBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    width: 634,
  },
  [theme.breakpoints.down("xl")]: {
    width: "40%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "50%",
    paddingRight: 16,
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
    paddingRight: 16,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const Title = styled("div")(() => ({
  fontSize: 48,
  fontWeight: 700,
  lineHeight: "52PX",
  color: "rgba(49, 55, 61, 1)",
  position: "relative",
}));

const TextInfo = styled("div")(() => ({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "20px",
  color: "rgba(49, 55, 61, 1)",
}));

const ImgBox = styled("img")(() => ({
  maxHeight: 470,
  width: "100%",
}));

function Overview(props) {
  let {
    title,
    content,
    pic,
    bgColor,
    linearGradient,
    textColor = "rgba(49, 55, 61, 1)",
  } = props;

  const BorderCss = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("xl")]: {
      display: "block",
    },
    [theme.breakpoints.down("xl")]: {
      display: "block",
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  }));

  return (
    <>
      <ContainerBox
        sx={{
          height: 527,
          background: bgColor,
          justifyContent: "center",
          overflowX: "hidden",
          position: "relative",
          display: { xs: "none", md: "flex" },
        }}
      >
        {linearGradient ? (
          <BorderCss
            style={{
              width: 150,
              height: "100%",
              position: "absolute",
              left: 0,
              clipPath: "polygon(0 0, 60% 0, 100% 100%, 0% 100%)",
              background: linearGradient,
            }}
          ></BorderCss>
        ) : null}

        <ContentBox>
          <Title sx={{ pt: 8, color: textColor }}>
            {/* <div style={TriangleCss}></div> */}
            <div>{title}</div>
          </Title>
          <TextInfo sx={{ pt: 3, color: textColor }}>{content}</TextInfo>
        </ContentBox>
        <PicOuterBox sx={{ mt: 2, width: 490 }}>
          <ImgBox src={pic} alt="toposfy Device" />
        </PicOuterBox>
        {linearGradient ? (
          <BorderCss
            style={{
              width: 150,
              height: "100%",
              position: "absolute",
              right: 0,
              clipPath: "polygon(0px 0px, 100% 0px, 100% 100%, 40% 100%)",
              background: linearGradient,
            }}
          ></BorderCss>
        ) : null}
      </ContainerBox>
      <ContainerBox
        sx={{
          background: bgColor,
          display: { xs: "flex", md: "none" },
          flexDirection: 'column',
          padding: 3
        }}
      >
        <Box sx={{ pt: 8, fontSize: 20, fontWeight: 500, padding: 0, margin: '0 auto 20px auto' }}>{title}</Box>
        <img sx={{width: '100%'}} src={pic} alt="toposfy Device" />
        <TextInfo sx={{ pt: 3, color: textColor }}>{content}</TextInfo>
      </ContainerBox>
    </>
  );
}
export default Overview;
