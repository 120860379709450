import { configureStore } from '@reduxjs/toolkit'
import langReducer from './langSlice';
import skinReducer from './skinSlice';
import langIndexReducer from './langIndexSlice';
import featureSliceReducer from './featureSlice';
import handwareSliceReducer from './handwareSlice';

export default configureStore({
  reducer: {
    lang: langReducer,
    skin: skinReducer,
    langIndex: langIndexReducer,
    feature: featureSliceReducer,
    handware: handwareSliceReducer
  }
})