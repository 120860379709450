import Page from "../page";
import { useTranslation } from "react-i18next";
import pos from "../../../assets/sence/bar/bg.jpg";
import mainPic from '../../../assets/sence/bar/main.png';
import Pic1 from '../../../assets/sence/bar/1.png';
import Pic2 from '../../../assets/sence/bar/2.png';
import Pic3 from '../../../assets/sence/bar/3.png';
import logo from '../../../assets/sence/logo.png';
function Bar() {
  let { t } = useTranslation();
  const datas = [
    {
      pic: Pic1,
      logo: logo,
      title: t("barShop.title1"),
      infos: [
        t("barShop.info1"),
      ],
      bgcolor: "rgb(245,244,245)",
    },
    {
      pic: Pic2,
      logo: logo,
      title: t("barShop.title2"),
      infos: [
        t("barShop.info2"),
      ],
    },
    {
      pic: Pic3,
      logo: logo,
      title: t("barShop.title3"),
      infos: [
        t("barShop.info3"),
      ],
    }
  ]
  return (
    <Page
      {...{
        title: t("barShop.title"),
        content: t("barShop.info"),
        btnText: t("barShop.button"),
        subtitle1: t("barShop.subtitle1"),
        subtitle2: t("barShop.subtitle2"),
        subtitle3: t("barShop.subtitle3"),
        pic: pos,
        bodyDatas: datas,
        mainPic: mainPic
      }}
    />
  );
}
export default Bar;
