import Overview from "./Overview";
import Body from "../../components/contentBody";
import DownFooterWeb from "../../components/down";
import { Box } from "@mui/material";

function BusinessType(props) {
  return (
    <>
      <Overview {...props} />

      {props.hideDown ? null : (
        <Box
          sx={{
            margin: {
              xs: "-36px auto",
              md: "60px 0"
            },
            display: "flex",
            justifyContent: "center",
            backgroundColor: {
              xs: props.bgColor,
              md: "unset"
            },
          }}
        >
          <DownFooterWeb {...props.downInfo} />
        </Box>
      )}
      <Body {...{ bodyDatas: props.bodyDatas }} />
    </>
  );
}
export default BusinessType;
