import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReactImageZoom from 'react-image-zoom';
import {getDatas} from './datas';

const ItemTitle = styled("div")(({ theme }) => ({
  fontSize: 28,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  marginBottom: "11px",
}));

const ItemInfo = styled("div")(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  marginBottom: "11px",
}));

const BreadcrumbsBox = styled(Box)(() => ({
  fontSize: 20,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
}));

const CarBoxItem = styled(Box)(({ theme }) => ({
  float: "left",
  width: "46%",
  padding: "32px 48px 0 0",
  "&:nth-of-type(2n+1)": {
    marginRight: "48px",
  },
}));

function Main1({ name, link, info, pics }) {
  // let { t } = useTranslation();
  // const toYamason = function () {
  //   window.open(link, "_blank");
  // };
  let [targetIndex, setTargetIndex] = useState(0);
  const setPicsIndex = function (i) {
    setTargetIndex(i);
  };
  const props = {width: 500, height: 500, zoomWidth: 700, img: pics[targetIndex]};
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CarBoxItem sx={{ display: "flex" }}>
          <Box sx={{ width: 60 }}>
            {pics.map((v, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    position: 'relative',
                    width: 44,
                    height: 40,
                    marginBottom: 1.5,
                    "&:hover": {
                      border: "2px solid rgba(211, 242, 226, 1)",
                      borderRadius: 1,
                    },
                  }}
                >
                  <img
                    src={v}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "0.5px solid rgba(0,0,0, 0.5)",
                      borderRadius: 4,
                      cursor: "pointer",
                    }}
                    onMouseMove={() => setPicsIndex(i)}
                  />
                </Box>
              );
            })}
          </Box>
          <Box sx={{height: 500, width: 500}}  className="imgssss">
            <ReactImageZoom {...props} />
          </Box>
        </CarBoxItem>
        <CarBoxItem>
          <Box>
            <ItemTitle>{name}</ItemTitle>
            <ItemInfo>
              {/* <Box sx={{ fontSize: 20, fontWeight: 400, color: "rgb(0,0,0)" }}>
                {t("hardInfo.buyLink")}:
              </Box> */}
              <Box
                sx={{
                  marginTop: 1,
                  paddingBottom: 1,
                  borderBottom: "1.5px solid rgba(204, 214, 210, 1)",
                  cursor: "pointer",
                  color: "rgb(39,67,214)",
                }}
                // onClick={toYamason}
              >
                {/* {link} */}
              </Box>
              {info.map((v, index) => {
                return (
                  <ul key={index} style={{ paddingInlineStart: "20px" }}>
                    <li style={{ fontSize: 20 }}>{v}</li>
                  </ul>
                );
              })}
            </ItemInfo>
          </Box>
        </CarBoxItem>
      </Box>
    </div>
  );
}

function Main() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const title = query.get("title");
  const name = query.get("name");
  let { t } = useTranslation();
  const datas = getDatas(t)
  const { id } = useParams();
  const targetData = datas.find((v) => (+v.id) === (+id));
  return (
    <Box sx={{ width: "100%" }}>
      <BreadcrumbsBox>
        {t("mainMenu.hardware")}
        <span>{">"}</span>
        {title}
        <span>{">"}</span>
        {name}
      </BreadcrumbsBox>
      <Main1 {...targetData} />
    </Box>
  );
}

const BoxContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1296,
  display: "flex",
  [theme.breakpoints.down("lgUp")]: {
    margin: "0 24px",
  },
  [theme.breakpoints.up("lgUp")]: {
    margin: "0 auto",
  },
}));

function Body() {
  return (
    <Box sx={{ my: 8, display: { xs: "none", md: "flex" } }} id="bodyOuter">
      <BoxContainer>
        <Main />
      </BoxContainer>
    </Box>
  );
}

function Web() {
  return (
    <>
      <Body />
    </>
  );
}
export default Web;
