import Page from "./page";
import { useTranslation } from "react-i18next";
import qrcode from '../../assets/software/qrcodeOrange.png';
import Pic1 from '../../assets/software/scan/1.png';
import Pic2 from '../../assets/software/scan/2.png';
import Pic3 from '../../assets/software/scan/3.png';
import logo1 from '../../assets/software/scan/logo1.png';
import logo2 from '../../assets/software/scan/logo2.png';
import logo3 from '../../assets/software/scan/logo3.png';
import main from '../../assets/software/scan/main.png';
function Selfhelp() {
  let { t } = useTranslation();

  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("scanType.reportTitle"),
      infos: [
        t("scanType.reportInfo1"),
      ],
      bgcolor: "rgb(255,248,240)",
    },
    {
      pic: Pic2,
      logo: logo2,
      title: t("scanType.goodsTitle"),
      infos: [
        t("scanType.goodsInfo1"),
      ],
    },
    {
      pic: Pic3,
      logo: logo3,
      title: t("scanType.goodsTitle2"),
      infos: [
        t("scanType.goodsInfo12"),
      ],
    }
  ]
  return (
    <Page
      {...{
        title: t("scanType.title"),
        content: t("scanType.info"),
        pic: main,
        bgColor: "rgba(255, 142, 43, 1)",
        linearGradient: 'linear-gradient(161.37deg, rgba(255, 106, 0, 1) 0%, rgba(255, 153, 64, 1) 100%)',
        downInfo: {
          bgColor: "#ff8122",
          qrBgColor: '#ffebd1',
          qrcode: qrcode,
          showMac: false,
          urlName: 'toposify_marchand'
        },
        bodyDatas: datas,
        textColor: '#fff'
      }}
    />
  );
}

export default Selfhelp;

