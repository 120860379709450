import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { Button, Box } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import Logo1 from "../../assets/feature/1.png";
import Logo2 from "../../assets/feature/2.png";
import Logo3 from "../../assets/feature/3.png";
import Logo4 from "../../assets/feature/4.png";
import Logo5 from "../../assets/feature/5.png";
import Logo6 from "../../assets/feature/6.png";
import Logo7 from "../../assets/feature/7.png";
import Logo8 from "../../assets/feature/8.png";
import Logo9 from "../../assets/feature/9.png";
import Logo10 from "../../assets/feature/10.png";
import Logo11 from "../../assets/feature/11.png";
import Logo12 from "../../assets/feature/12.png";
import Logo13 from "../../assets/feature/13.png";
import Logo14 from "../../assets/feature/14.png";
import Logo15 from "../../assets/feature/15.png";
import Logo16 from "../../assets/feature/16.png";
import Logo17 from "../../assets/feature/17.png";
import Logo18 from "../../assets/feature/18.png";
import Logo19 from "../../assets/feature/19.png";
import Logo20 from "../../assets/feature/20.png";
import Logo21 from "../../assets/feature/21.png";
import Logo22 from "../../assets/feature/22.png";
import Logo23 from "../../assets/feature/23.png";
import Logo24 from "../../assets/feature/24.png";
import Logo25 from "../../assets/feature/25.png";
import Logo26 from "../../assets/feature/26.png";
import Logo27 from "../../assets/feature/27.png";
import Logo28 from "../../assets/feature/28.png";
import Logo29 from "../../assets/feature/29.png";
import Logo30 from "../../assets/feature/30.png";
import Logo31 from "../../assets/feature/31.png";
import Logo32 from "../../assets/feature/32.png";
import Logo33 from "../../assets/feature/33.png";
import Logo34 from "../../assets/feature/34.png";
import Logo35 from "../../assets/feature/35.png";
import Logo36 from "../../assets/feature/36.png";
import Logo37 from "../../assets/feature/37.png";
import DownFooterWeb from "../../components/downFooterWeb";
import React, { useRef, useEffect, useState } from "react";
import { changeIndex } from "../../store/featureSlice";
import { useDispatch, useSelector } from "react-redux";

const MenuLink = styled(Link)(() => ({
  cursor: "pointer",
  fontSize: 16,
  fontWeight: 400,
  display: "block",
  width: 250,
  padding: "14px 16px",
}));

const ContainerTop = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(244, 244, 245, 1)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.up("xl")]: {
    height: 334,
  },
  [theme.breakpoints.down("xl")]: {
    height: 334,
  },
  [theme.breakpoints.down("lg")]: {
    height: 334,
  },
  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {
    height: "auto",
  },
}));

const PTitle = styled("div")(() => ({
  fontSize: 30,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  paddingTop: "32px",
  paddingBottom: "24px",
}));

const PInfo = styled("div")(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  paddingBottom: "24px",
  [theme.breakpoints.up("xl")]: {
    width: 800,
  },
  [theme.breakpoints.down("xl")]: {
    width: 800,
  },
  [theme.breakpoints.down("lg")]: {
    width: 800,
  },
  [theme.breakpoints.down("md")]: {
    width: "auto",
    padding: "0 8px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    padding: "0 8px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "auto",
    padding: "0 8px",
  },
}));

const BtnOuter = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    padding: "16px 0",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "16px 0",
  },
}));

const BeginButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "rgba(23, 144, 82, 1)",
  "&:hover": {
    backgroundColor: green[700],
  },
  height: 52,
  minWidth: 154,
  fontSize: 20,
  fontWeight: 400,
  marginBottom: 16,
}));

const ContactButton = styled(Button)(({ theme }) => ({
  color: "rgba(23, 144, 82, 1)",
  backgroundColor: "#fff",
  borderColor: "rgba(23, 144, 82, 1)",
  "&:hover": {
    backgroundColor: grey[100],
    borderColor: "rgba(23, 144, 82, 1)",
  },
  height: 52,
  minWidth: 154,
  fontSize: 20,
  fontWeight: 400,
  marginBottom: 16,
}));

const MainTitle = styled("div")(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  paddingTop: "30px",
  paddingBottom: "12px",
  marginBottom: "24px",
  borderBottom: "1px solid rgba(204, 204, 204, 1)",
  [theme.breakpoints.up("xl")]: {
    display: "block",
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {
    display: "flex",
    justifyContent: "center",
  },
}));

const ItemTitle = styled("div")(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  marginBottom: "11px",
}));

const ItemInfo = styled("div")(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  marginBottom: "11px",
}));

function Top() {
  let { t } = useTranslation();
  return (
    <ContainerTop id="featureTop">
      <PTitle>{t("software.text")}</PTitle>
      <PInfo>{t("software.info")}</PInfo>
      <BtnOuter>
        <BeginButton variant="contained" sx={{ mr: 2 }}>
          {t("software.btn1")}
        </BeginButton>
        <ContactButton variant="outlined">{t("software.btn2")}</ContactButton>
      </BtnOuter>
    </ContainerTop>
  );
}

const SliderBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("mg")]: {
    display: "block",
  },
  [theme.breakpoints.down("mg")]: {
    display: "none",
  },
}));

function SliderMenus({ fixedClassName }) {
  let { t } = useTranslation();
  const menus = [
    t("software.menu1"),
    t("software.menu2"),
    t("software.menu3"),
    t("software.menu4"),
    t("software.menu5"),
    t("software.menu6"),
  ];

  const dispatch = useDispatch();
  const feature = useSelector((state) => state.feature.value);
  let [selectIndex, setSelectIndex] = useState(feature.index);
  const clickBarItem = function (index) {
    setSelectIndex(index);
    dispatch(changeIndex({ index: index }));
    localStorage.setItem("feartureIndex", index);
  };

  return (
    <SliderBox className={fixedClassName}>
      {menus.map((v, index) => {
        return (
          <MenuLink
            to={"item" + index}
            smooth={true}
            duration={200}
            key={index}
            onClick={() => clickBarItem(index)}
            activeClass="sideActive"
            index={selectIndex}
            spy={true}
            offset={-40}
          >
            {v}
          </MenuLink>
        );
      })}
    </SliderBox>
  );
}

const CarBoxItem = styled(Box)(({ theme }) => ({
  float: "left",
  width: "46%",
  padding: "32px 48px 0 0",
  "&:nth-of-type(2n+1)": {
    marginRight: "48px",
  },
}));

function CarBox({ logo, title, content }) {
  return (
    <CarBoxItem>
      <img
        src={logo}
        alt=""
        style={{ width: "32px", height: "32px", marginRight: "14px" }}
      />
      <div>
        <ItemTitle>{title}</ItemTitle>
        <ItemInfo>{content}</ItemInfo>
      </div>
    </CarBoxItem>
  );
}

function Main1() {
  let { t } = useTranslation();
  const arr1 = [
    {
      logo: Logo1,
      title: t("software.subTitle101"),
      content: t("software.subInfo101"),
    },
    {
      logo: Logo2,
      title: t("software.subTitle102"),
      content: t("software.subInfo102"),
    },
    {
      logo: Logo3,
      title: t("software.subTitle103"),
      content: t("software.subInfo103"),
    },
    {
      logo: Logo4,
      title: t("software.subTitle104"),
      content: t("software.subInfo104"),
    },
    {
      logo: Logo5,
      title: t("software.subTitle105"),
      content: t("software.subInfo105"),
    },
    {
      logo: Logo6,
      title: t("software.subTitle106"),
      content: t("software.subInfo106"),
    },
    {
      logo: Logo7,
      title: t("software.subTitle107"),
      content: t("software.subInfo107"),
    },
    {
      logo: Logo8,
      title: t("software.subTitle108"),
      content: t("software.subInfo108"),
    },
    {
      logo: Logo9,
      title: t("software.subTitle109"),
      content: t("software.subInfo109"),
    },
    {
      logo: Logo10,
      title: t("software.subTitle110"),
      content: t("software.subInfo110"),
    },
    {
      logo: Logo11,
      title: t("software.subTitle111"),
      content: t("software.subInfo111"),
    },
    {
      logo: Logo12,
      title: t("software.subTitle112"),
      content: t("software.subInfo112"),
    },
    {
      logo: Logo13,
      title: t("software.subTitle113"),
      content: t("software.subInfo113"),
    },
    {
      logo: Logo14,
      title: t("software.subTitle114"),
      content: t("software.subInfo114"),
    },
  ];
  return (
    <div id="item0">
      <MainTitle>{t("software.title1")}</MainTitle>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {arr1.map((v, index) => {
          return <CarBox {...v} key={index} />;
        })}
      </Box>
    </div>
  );
}

function Main2() {
  let { t } = useTranslation();
  const arr1 = [
    {
      logo: Logo15,
      title: t("software.subTitle201"),
      content: t("software.subInfo201"),
    },
    {
      logo: Logo16,
      title: t("software.subTitle202"),
      content: t("software.subInfo202"),
    },
    {
      logo: Logo17,
      title: t("software.subTitle203"),
      content: t("software.subInfo203"),
    },
    {
      logo: Logo18,
      title: t("software.subTitle204"),
      content: t("software.subInfo204"),
    },
    {
      logo: Logo19,
      title: t("software.subTitle205"),
      content: t("software.subInfo205"),
    },
    {
      logo: Logo20,
      title: t("software.subTitle206"),
      content: t("software.subInfo206"),
    },
    {
      logo: Logo21,
      title: t("software.subTitle207"),
      content: t("software.subInfo207"),
    },
    {
      logo: Logo22,
      title: t("software.subTitle208"),
      content: t("software.subInfo208"),
    },
    {
      logo: Logo23,
      title: t("software.subTitle209"),
      content: t("software.subInfo209"),
    },
    {
      logo: Logo24,
      title: t("software.subTitle210"),
      content: t("software.subInfo210"),
    },
    {
      logo: Logo25,
      title: t("software.subTitle211"),
      content: t("software.subInfo211"),
    },
  ];
  return (
    <div id="item1">
      <MainTitle>{t("software.title2")}</MainTitle>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {arr1.map((v, index) => {
          return <CarBox {...v} key={index} />;
        })}
      </Box>
    </div>
  );
}

function Main3() {
  let { t } = useTranslation();
  const arr1 = [
    {
      logo: Logo26,
      title: t("software.subTitle301"),
      content: t("software.subInfo301"),
    },
    {
      logo: Logo27,
      title: t("software.subTitle302"),
      content: t("software.subInfo302"),
    },
    {
      logo: Logo28,
      title: t("software.subTitle303"),
      content: t("software.subInfo303"),
    },
    {
      logo: Logo29,
      title: t("software.subTitle304"),
      content: t("software.subInfo304"),
    },
  ];
  return (
    <div id="item2">
      <MainTitle>{t("software.title3")}</MainTitle>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {arr1.map((v, index) => {
          return <CarBox {...v} key={index} />;
        })}
      </Box>
    </div>
  );
}

function Main4() {
  let { t } = useTranslation();
  const arr1 = [
    {
      logo: Logo30,
      title: t("software.subTitle401"),
      content: t("software.subInfo401"),
    },
    {
      logo: Logo31,
      title: t("software.subTitle402"),
      content: t("software.subInfo402"),
    },
    {
      logo: Logo32,
      title: t("software.subTitle403"),
      content: t("software.subInfo403"),
    },
    {
      logo: Logo33,
      title: t("software.subTitle404"),
      content: t("software.subInfo404"),
    },
    {
      logo: Logo34,
      title: t("software.subTitle405"),
      content: t("software.subInfo405"),
    },
    {
      logo: Logo35,
      title: t("software.subTitle406"),
      content: t("software.subInfo406"),
    },
  ];
  return (
    <div id="item3">
      <MainTitle>{t("software.title4")}</MainTitle>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {arr1.map((v, index) => {
          return <CarBox {...v} key={index} />;
        })}
      </Box>
    </div>
  );
}

function Main5() {
  let { t } = useTranslation();
  const arr1 = [
    {
      logo: Logo36,
      title: t("software.subTitle501"),
      content: t("software.subInfo501"),
    },
  ];
  return (
    <div id="item4">
      <MainTitle>{t("software.title5")}</MainTitle>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {arr1.map((v, index) => {
          return <CarBox {...v} key={index} />;
        })}
      </Box>
    </div>
  );
}

function Main6() {
  let { t } = useTranslation();
  const arr1 = [
    {
      logo: Logo37,
      title: t("software.subTitle601"),
      content: t("software.subInfo601"),
    },
  ];
  return (
    <div id="item5">
      <MainTitle>{t("software.title6")}</MainTitle>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {arr1.map((v, index) => {
          return <CarBox {...v} key={index} />;
        })}
      </Box>
    </div>
  );
}

function Main({ fixedClassName }) {
  let cNames = fixedClassName ? "leftBody" : "";
  return (
    <Box sx={{ width: "100%" }} className={cNames}>
      <Main1 />
      <Main2 />
      <Main3 />
      <Main4 />
      <Main5 />
      <Main6 />
    </Box>
  );
}

const BoxContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1296,
  display: "flex",
  [theme.breakpoints.down("lgUp")]: {
    margin: "0 24px",
  },
  [theme.breakpoints.up("lgUp")]: {
    margin: "0 auto",
  },
}));

function Body() {
  let [fixedClassName, setFixedClassName] = useState("");
  const fixedRef = useRef(null);
  const handleScroll = () => {
    let height = fixedRef.current.getBoundingClientRect();
    let menu = document.getElementById("HeaderMenu").getBoundingClientRect();
    let bodyOuter = document
      .getElementById("bodyOuter")
      .getBoundingClientRect();
    let win = document.documentElement.scrollTop || document.body.scrollTop;
    let basicHight = menu.height + bodyOuter.height + 64;
    let bol = height.top < 64 && win - basicHight < 0;
    let names = bol ? "sidFixed" : "";
    setFixedClassName(names);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <Box sx={{ my: 8, display: { xs: "none", md: "flex" } }} id="bodyOuter">
      <BoxContainer ref={fixedRef} id="bodyContainer">
        <SliderMenus fixedClassName={fixedClassName} />
        <Main fixedClassName={fixedClassName} />
      </BoxContainer>
    </Box>
  );
}

function BodyMini() {
  let { t } = useTranslation();
  const arr1 = [
    {
      logo: Logo1,
      title: t("software.subTitle101"),
      content: t("software.subInfo101"),
    },
    {
      logo: Logo2,
      title: t("software.subTitle102"),
      content: t("software.subInfo102"),
    },
    {
      logo: Logo3,
      title: t("software.subTitle103"),
      content: t("software.subInfo103"),
    },
    {
      logo: Logo4,
      title: t("software.subTitle104"),
      content: t("software.subInfo104"),
    },
    {
      logo: Logo5,
      title: t("software.subTitle105"),
      content: t("software.subInfo105"),
    },
    {
      logo: Logo6,
      title: t("software.subTitle106"),
      content: t("software.subInfo106"),
    },
    {
      logo: Logo7,
      title: t("software.subTitle107"),
      content: t("software.subInfo107"),
    },
    {
      logo: Logo8,
      title: t("software.subTitle108"),
      content: t("software.subInfo108"),
    },
    {
      logo: Logo9,
      title: t("software.subTitle109"),
      content: t("software.subInfo109"),
    },
    {
      logo: Logo10,
      title: t("software.subTitle110"),
      content: t("software.subInfo110"),
    },
    {
      logo: Logo11,
      title: t("software.subTitle111"),
      content: t("software.subInfo111"),
    },
    {
      logo: Logo12,
      title: t("software.subTitle112"),
      content: t("software.subInfo112"),
    },
    {
      logo: Logo13,
      title: t("software.subTitle113"),
      content: t("software.subInfo113"),
    },
    {
      logo: Logo14,
      title: t("software.subTitle114"),
      content: t("software.subInfo114"),
    },
  ];
  const arr2 = [
    {
      logo: Logo15,
      title: t("software.subTitle201"),
      content: t("software.subInfo201"),
    },
    {
      logo: Logo16,
      title: t("software.subTitle202"),
      content: t("software.subInfo202"),
    },
    {
      logo: Logo17,
      title: t("software.subTitle203"),
      content: t("software.subInfo203"),
    },
    {
      logo: Logo18,
      title: t("software.subTitle204"),
      content: t("software.subInfo204"),
    },
    {
      logo: Logo19,
      title: t("software.subTitle205"),
      content: t("software.subInfo205"),
    },
    {
      logo: Logo20,
      title: t("software.subTitle206"),
      content: t("software.subInfo206"),
    },
    {
      logo: Logo21,
      title: t("software.subTitle207"),
      content: t("software.subInfo207"),
    },
    {
      logo: Logo22,
      title: t("software.subTitle208"),
      content: t("software.subInfo208"),
    },
    {
      logo: Logo23,
      title: t("software.subTitle209"),
      content: t("software.subInfo209"),
    },
    {
      logo: Logo24,
      title: t("software.subTitle210"),
      content: t("software.subInfo210"),
    },
    {
      logo: Logo25,
      title: t("software.subTitle211"),
      content: t("software.subInfo211"),
    },
  ];
  const arr3 = [
    {
      logo: Logo26,
      title: t("software.subTitle301"),
      content: t("software.subInfo301"),
    },
    {
      logo: Logo27,
      title: t("software.subTitle302"),
      content: t("software.subInfo302"),
    },
    {
      logo: Logo28,
      title: t("software.subTitle303"),
      content: t("software.subInfo303"),
    },
    {
      logo: Logo29,
      title: t("software.subTitle304"),
      content: t("software.subInfo304"),
    },
  ];
  const arr4 = [
    {
      logo: Logo30,
      title: t("software.subTitle401"),
      content: t("software.subInfo401"),
    },
    {
      logo: Logo31,
      title: t("software.subTitle402"),
      content: t("software.subInfo402"),
    },
    {
      logo: Logo32,
      title: t("software.subTitle403"),
      content: t("software.subInfo403"),
    },
    {
      logo: Logo33,
      title: t("software.subTitle404"),
      content: t("software.subInfo404"),
    },
    {
      logo: Logo34,
      title: t("software.subTitle405"),
      content: t("software.subInfo405"),
    },
    {
      logo: Logo35,
      title: t("software.subTitle406"),
      content: t("software.subInfo406"),
    },
  ];
  const arr5 = [
    {
      logo: Logo36,
      title: t("software.subTitle501"),
      content: t("software.subInfo501"),
    },
  ];
  const arr6 = [
    {
      logo: Logo37,
      title: t("software.subTitle601"),
      content: t("software.subInfo601"),
    },
  ];
  let arr = [
    {
      name: t("software.title1"),
      body: arr1,
    },
    {
      name: t("software.title2"),
      body: arr2,
    },
    {
      name: t("software.title3"),
      body: arr3,
    },
    {
      name: t("software.title4"),
      body: arr4,
    },
    {
      name: t("software.title5"),
      body: arr5,
    },
    {
      name: t("software.title6"),
      body: arr6,
    },
  ];
  return (
    <Box sx={{ my: 8, display: { xs: "flex", md: "none" } }}>
      <BoxContainer
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        {arr.map((a, i) => {
          return (
            <Box key={i}>
              <Box
                sx={{
                  borderBottom: "1px solid rgba(204, 204, 204, 1)",
                  paddingBottom: 1,
                  marginBottom: 1,
                  marginTop: 1.5,
                  fontSize: 22,
                  fontWeight: 800
                }}
              >
                {a.name}
              </Box>
              {a.body.map((item, index) => {
                return (
                  <Box sx={{ width: "100%", marginBottom: 2, display: 'flex' }} key={index}>
                    <img
                      src={item.logo}
                      alt=""
                      style={{ width: "32px", height: "32px" }}
                    />
                    <Box sx={{marginLeft: 2.5}}>
                      <ItemTitle>{item.title}</ItemTitle>
                      <ItemInfo>{item.content}</ItemInfo>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </BoxContainer>
    </Box>
  );
}

function Web() {
  return (
    <>
      <Top />
      <Body />
      <BodyMini />
      <DownFooterWeb />
    </>
  );
}
export default Web;
