import Page from "../page";
import { useTranslation } from "react-i18next";
import pos from "../../../assets/sence/diningCar/bg.jpg";
import mainPic from '../../../assets/sence/diningCar/main.png';
import Pic1 from '../../../assets/sence/diningCar/1.png';
import Pic2 from '../../../assets/sence/diningCar/2.png';
import Pic3 from '../../../assets/sence/diningCar/3.png';
import logo1 from '../../../assets/sence/diningCar/logo1.png';
import logo2 from '../../../assets/sence/diningCar/logo2.png';
import logo3 from '../../../assets/sence/diningCar/logo3.png';
function Restaurant() {
  let { t } = useTranslation();
  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("diningCarShop.title1"),
      infos: [
        t("diningCarShop.info1"),
      ],
      bgcolor: "rgb(245,244,245)",
    },
    {
      pic: Pic2,
      logo: logo2,
      title: t("diningCarShop.title2"),
      infos: [
        t("diningCarShop.info2"),
      ],
    },
    {
      pic: Pic3,
      logo: logo3,
      title: t("diningCarShop.title3"),
      infos: [
        t("diningCarShop.info3"),
      ],
      bgcolor: "rgb(245,244,245)",
    }
  ]
  return (
    <Page
      {...{
        title: t("diningCarShop.title"),
        content: t("diningCarShop.info"),
        btnText: t("diningCarShop.button"),
        subtitle1: t("diningCarShop.subtitle1"),
        subtitle2: t("diningCarShop.subtitle2"),
        subtitle3: t("diningCarShop.subtitle3"),
        pic: pos,
        mainPic: mainPic,
        bodyDatas: datas
      }}
    />
  );
}

export default Restaurant;
