import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getDatas } from "./datas";

const ItemTitle = styled("div")(() => ({
  fontSize: 18,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  marginBottom: "12px",
}));

const ItemInfo = styled("div")(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  marginBottom: 8,
}));

function PicShow({ name, link, info, pics }) {
  // let { t } = useTranslation();
  // const toYamason = function () {
  //   window.open(link, "_blank");
  // };
  let [targetIndex, setTargetIndex] = useState(0);
  const setPicsIndex = function (i) {
    setTargetIndex(i);
  };
  return (
    <>
      <Box className="imgssss">
        <img src={pics[targetIndex]} alt="" style={{ width: "100%" }} />
      </Box>
      <Box sx={{ display: "flex", overflowX: "auto", overflowY: "hidden" }}>
        {pics.map((v, i) => {
          return (
            <Box
              key={i}
              sx={{
                minWidth: 44,
                height: 40,
                marginRight: 1.5,
                "&:hover": {
                  border: "2px solid rgba(211, 242, 226, 1)",
                  borderRadius: 1,
                },
              }}
            >
              <img
                src={v}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0.5px solid rgba(0,0,0, 0.5)",
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onMouseMove={() => setPicsIndex(i)}
              />
            </Box>
          );
        })}
      </Box>
      <Box sx={{marginTop: 2}}>
        <ItemTitle>{name}</ItemTitle>
        <ItemInfo>
          {/* <Box sx={{ fontSize: 20, fontWeight: 400, color: "rgb(0,0,0)" }}>
            {t("hardInfo.buyLink")}:
          </Box> */}
          <Box
            sx={{
              marginTop: 1,
              paddingBottom: 1,
              borderBottom: "1.5px solid rgba(204, 214, 210, 1)",
              cursor: "pointer",
              color: "rgb(39,67,214)",
              wordBreak: 'break-all'
            }}
            // onClick={toYamason}
          >
            {/* {link} */}
          </Box>
          {info.map((v, index) => {
            return (
              <ul key={index} style={{ paddingInlineStart: "20px" }}>
                <li style={{ fontSize: 20 }}>{v}</li>
              </ul>
            );
          })}
        </ItemInfo>
      </Box>
    </>
  );
}

const BreadcrumbsBox = styled(Box)(() => ({
  fontSize: 20,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  marginTop: 16,
  marginBottom: 16,
}));

function Mini() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const title = query.get("title");
  const mainName = query.get("name");
  let { t } = useTranslation();
  const datas = getDatas(t);
  const { id } = useParams();
  const targetData = datas.find((v) => +v.id === +id);

  const BoxContainer = styled(Box)(({ theme }) => ({
    maxWidth: "100%",
    display: "flex",
    [theme.breakpoints.down("lgUp")]: {
      margin: "0 24px",
    },
    [theme.breakpoints.up("lgUp")]: {
      margin: "0 auto",
    },
  }));

  return (
    <Box sx={{ display: { xs: "flex", md: "none" }, flexDirection: "column" }}>
      <Box>
        <BoxContainer sx={{ display: "flex", flexDirection: "column" }}>
          <BreadcrumbsBox>
            {t("mainMenu.hardware")}
            <span>{">"}</span>
            {title}
            <span>{">"}</span>
            {mainName}
          </BreadcrumbsBox>
          <PicShow {...targetData} />
        </BoxContainer>
      </Box>
    </Box>
  );
}
export default Mini;
