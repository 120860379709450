import Web from "./Web";
import Mini from "./Mini";
function Hardware() {
  return (
    <>
      <Web />
      <Mini />
    </>
  );
}
export default Hardware;
