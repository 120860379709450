import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { green, grey } from "@mui/material/colors";

export const ContainerBox = styled(Box)(({ theme }) => ({
  minHeight: 464,
  backgroundColor: "rgba(211, 242, 226, 1)",
  display: "flex",
  justifyContent: "center"
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  flex: '1 0 57%'
}));

export const PicOuterBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.up("xl")]: {
    flex: '0 0 50%',
  },
  [theme.breakpoints.down("xl")]: {
    flex: '0 0 50%',
  },
  [theme.breakpoints.down("gx")]: {
    flex: '0 0 44%',
  },
  [theme.breakpoints.down("lg")]: {
    flex: '0 0 40%',
    marginRight: 24
  },
}));

export const Title = styled("div")(({ theme }) => ({
  fontSize: 42,
  fontWeight: 600,
  color: "rgba(49, 55, 61, 1)",
  padding: '64px 0 24px',
  lineHeight: 1.2
}));

export const TextInfo = styled("div")(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.8,
  color: "rgba(49, 55, 61, 1)",
}));

export const BeginButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "rgba(23, 144, 82, 1)",
  "&:hover": {
    backgroundColor: green[700],
  },
  height: 52,
  minWidth: 154,
  fontSize: 20,
  fontWeight: 400,
}));

export const ContactButton = styled(Button)(({ theme }) => ({
  color: "rgba(23, 144, 82, 1)",
  backgroundColor: "#fff",
  borderColor: "rgba(23, 144, 82, 1)",
  "&:hover": {
    backgroundColor: grey[100],
    borderColor: "rgba(23, 144, 82, 1)",
  },
  height: 52,
  minWidth: 154,
  fontSize: 20,
  fontWeight: 400,
}));

export const PlayText = styled("span")(({ theme }) => ({
  color: "rgba(23, 144, 82, 1)",
  fontSize: 20,
  fontWeight: 400,
  marginLeft: 8,
  width: "fit-content",
}));

export const ImgBox = styled("img")(({theme}) => ({
  position: 'absolute',
  top: 48,
  maxWidth: '100%',
  height: 'auto'
}));


export const ContainerBoxMini = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: "rgba(211, 242, 226, 1)",
  justifyContent: "center",
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: 24
}));

export const ImgBoxMini = styled("img")(({theme}) => ({
  width: '100%'
}));