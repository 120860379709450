import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./config/i18n/index.js";
import { RouterProvider } from "react-router-dom";
import router from "./route/index";
import store from './store/index';
import { Provider } from 'react-redux';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);