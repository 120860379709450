/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Box } from "@mui/material";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import Pos from "../../assets/home/1.png";
import PosLogo from "../../assets/home/posLogo.png";
import Area from "../../assets/home/area.png";
import AreaLogo from "../../assets/home/areaLogo.png";
import Crm from "../../assets/home/crm.png";
import CrmLogo from "../../assets/home/crmLogo.png";
import Pay from "../../assets/home/pay.png";
import PayLogo from "../../assets/home/payLogo.png";
import Sale from "../../assets/home/4.png";
import SaleLogo from "../../assets/home/saleLogo.png";
import Staff from "../../assets/home/5.png";
import StaffLogo from "../../assets/home/staffLogo.png";
import Stock from "../../assets/home/stock.png";
import StockLogo from "../../assets/home/stockLogo.png";

const LogoImg = styled("img")(() => ({
  width: 48,
  height: 48,
}));

export const ContainerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: "auto",
  padding: "64px 0",
  justifyContent: "center",
}));

export const CardBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    width: 634,
  },
  [theme.breakpoints.down("xl")]: {
    width: "40%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "48%",
  },
  [theme.breakpoints.down("md")]: {
    width: "48%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60%",
    height: "60%",
    marginTop: 16,
  },
}));

function Selections({ id, pic, logo, title, infos, targetInfo, bgcolor }) {
  const InfoBox = {
    fontSize: 18,
    fontWeight: 400,
    mt: 2,
    color: "rgb(49, 55, 61)",
    display: "flex",
  };
  const TitleBox = {
    fontSize: 36,
    fontWeight: 700,
    mt: 2.5,
  };
  const CircleCss = { position: "relative", top: 7, fontSize: 12, mr: 1 };
  return (
    <ContainerBox id={id} sx={{ bgcolor: bgcolor }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: 1200,
          margin: "0 auto",
        }}
      >
        <CardBox>
          <img src={pic} alt="" style={{ width: "100%" }} />
        </CardBox>
        <Box sx={{ width: 40 }} ></Box>
        <CardBox sx={{ display: "flex", flexDirection: "column" }}>
          <LogoImg src={logo} />
          <Box sx={TitleBox}>{title}</Box>
          {infos.map((v, index) => {
            return (
              <Box sx={InfoBox} key={index}>
                <CircleIcon sx={CircleCss} />
                {v}
              </Box>
            );
          })}
        </CardBox>
      </Box>
    </ContainerBox>
  );
}

const MenuLink = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  fontSize: 16,
  fontWeight: 400,
  height: 92,
  lineHeight: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "14%",
  textAlign: "center",
  padding: "12px 16px",
  margin: "0 4px",
  "&:first-of-type": {
    color: "rgba(23, 144, 82, 1)",
    borderBottom: "2px solid rgba(23, 144, 82, 1)",
  },
  "&:not(:first-of-type)": {
    borderBottom: "1px solid rgba(0,0,0,.12)",
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  padding: "0 20px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

function BodyWeb() {
  let { t } = useTranslation();
  const menus = [
    t("home.pos"),
    t("home.pay"),
    t("home.stock"),
    t("home.sale"),
    t("home.staff"),
    t("home.crm"),
    t("home.area"),
  ];
  const datas = [
    {
      pic: Pos,
      logo: PosLogo,
      title: t("home.posTitle"),
      infos: [t("home.posInfo1"), t("home.posInfo2"), t("home.posInfo3")],
      targetInfo: t("home.posDetail"),
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Pay,
      logo: PayLogo,
      title: t("home.payTitle"),
      infos: [t("home.payInfo1"), t("home.payInfo2"), t("home.payInfo3")],
      targetInfo: t("home.payDetail"),
    },
    {
      pic: Stock,
      logo: StockLogo,
      title: t("home.stockTitle"),
      infos: [t("home.stockInfo1"), t("home.stockInfo2"), t("home.stockInfo3")],
      targetInfo: t("home.stockDetail"),
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Sale,
      logo: SaleLogo,
      title: t("home.saleTitle"),
      infos: [t("home.saleInfo1"), t("home.saleInfo2"), t("home.saleInfo3")],
      targetInfo: t("home.saleDetail"),
    },
    {
      pic: Staff,
      logo: StaffLogo,
      title: t("home.staffTitle"),
      infos: [t("home.staffInfo1"), t("home.staffInfo2"), t("home.staffInfo3")],
      targetInfo: t("home.staffDetail"),
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Crm,
      logo: CrmLogo,
      title: t("home.crmTitle"),
      infos: [t("home.crmInfo1"), t("home.crmInfo2"), t("home.crmInfo3")],
      targetInfo: t("home.crmDetail"),
    },
    {
      pic: Area,
      logo: AreaLogo,
      title: t("home.areaTitle"),
      infos: [t("home.areaInfo1"), t("home.areaInfo2"), t("home.areaInfo3")],
      targetInfo: t("home.areaDetail"),
      bgcolor: "rgb(245,255,250)",
    },
  ];
  const MainBoxCss = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  };
  const SubBoxCss = {
    width: "100%",
    textAlign: "center",
    fontSize: 30,
    fontWeight: 700,
    mb: 4,
  };
  return (
    <>
      <Box sx={MainBoxCss}>
        <Box sx={SubBoxCss}>{t("home.introduce")}</Box>
        <BoxContainer>
          <Box sx={{ display: "flex", marigin: "0 auto" }}>
            {menus.map((v, index) => {
              return (
                <MenuLink
                  to={"item" + index}
                  smooth={true}
                  duration={500}
                  key={index}
                >
                  {v}
                </MenuLink>
              );
            })}
          </Box>
        </BoxContainer>
      </Box>
      {datas.map((v, index) => {
        return (
          <Selections
            {...{
              pic: v.pic,
              logo: v.logo,
              title: v.title,
              infos: v.infos,
              targetInfo: v.targetInfo,
              bgcolor: v.bgcolor,
              id: "item" + index,
            }}
            key={index}
          />
        );
      })}
    </>
  );
}

const SdBox = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  overflow: "hidden",
  marginTop: 16,
  marginInlineStart: "24px",
  width: 200,
  [theme.breakpoints.down("xm")]: {
    display: "none",
  },
  [theme.breakpoints.up("xm")]: {
    display: "flex",
  },
}));

const BarLink = styled(Link)(() => ({
  cursor: "pointer",
  fontSize: 16,
  fontWeight: 400,
  display: "block",
  width: 200,
  padding: "14px 16px",
  "&:first-of-type": {
    borderLeft: "2px solid rgba(23, 144, 82, 1)",
  },
}));

function BodyMini() {
  let { t } = useTranslation();
  const menus = [
    t("home.pos"),
    t("home.pay"),
    t("home.stock"),
    t("home.sale"),
    t("home.staff"),
    t("home.crm"),
    t("home.area"),
  ];
  const datas = [
    {
      pic: Pos,
      logo: PosLogo,
      title: t("home.posTitle"),
      infos: [t("home.posInfo1"), t("home.posInfo2"), t("home.posInfo3")],
      targetInfo: t("home.posDetail"),
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Pay,
      logo: PayLogo,
      title: t("home.payTitle"),
      infos: [t("home.payInfo1"), t("home.payInfo2"), t("home.payInfo3")],
      targetInfo: t("home.payDetail"),
    },
    {
      pic: Stock,
      logo: StockLogo,
      title: t("home.stockTitle"),
      infos: [t("home.stockInfo1"), t("home.stockInfo2"), t("home.stockInfo3")],
      targetInfo: t("home.stockDetail"),
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Sale,
      logo: SaleLogo,
      title: t("home.saleTitle"),
      infos: [t("home.saleInfo1"), t("home.saleInfo2"), t("home.saleInfo3")],
      targetInfo: t("home.saleDetail"),
    },
    {
      pic: Staff,
      logo: StaffLogo,
      title: t("home.staffTitle"),
      infos: [t("home.staffInfo1"), t("home.staffInfo2"), t("home.staffInfo3")],
      targetInfo: t("home.staffDetail"),
      bgcolor: "rgb(245,255,250)",
    },
    {
      pic: Crm,
      logo: CrmLogo,
      title: t("home.crmTitle"),
      infos: [t("home.crmInfo1"), t("home.crmInfo2"), t("home.crmInfo3")],
      targetInfo: t("home.crmDetail"),
    },
    {
      pic: Area,
      logo: AreaLogo,
      title: t("home.areaTitle"),
      infos: [t("home.areaInfo1"), t("home.areaInfo2"), t("home.areaInfo3")],
      targetInfo: t("home.areaDetail"),
      bgcolor: "rgb(245,255,250)",
    },
  ];
  const MainBoxCss = {
    display: "flex",
    position: "relative",
  };
  const SubBoxCss = {
    width: "100%",
    textAlign: "center",
    fontSize: 30,
    fontWeight: 700,
    mb: 4,
  };
  const InfoBox = {
    fontSize: 18,
    fontWeight: 400,
    mt: 2,
    color: "rgb(49, 55, 61)",
    display: "flex",
  };
  const CircleCss = { position: "relative", top: 7, fontSize: 12, mr: 1 };
  return (
    <>
      <Box sx={MainBoxCss}>
        <Box sx={SubBoxCss}>{t("home.introduce")}</Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <SdBox>
          {menus.map((v, index) => {
            return (
              <BarLink
                key={index}
                to={"bar" + index}
                index={index}
              >
                {v}
              </BarLink>
            );
          })}
        </SdBox>
        <Box sx={{ flex: 1 }}>
          {datas.map((v, index) => {
            return (
              <Box
                id={v.id}
                key={index}
                sx={{ marginBottom: 6, padding: "0 24px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <img src={v.pic} alt="" style={{ width: "100%" }} />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={v.TitleBox}>{v.title}</Box>
                    {v.infos.map((k, index) => {
                      return (
                        <Box sx={InfoBox} key={index}>
                          <CircleIcon sx={CircleCss} />
                          {k}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

const BoxOuter = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const BoxOuterMini = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

function Body() {
  return (
    <>
      <BoxOuter>
        <BodyWeb />
      </BoxOuter>
      <BoxOuterMini>
        <BodyMini />
      </BoxOuterMini>
    </>
  );
}

export default Body;
