import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { Box } from "@mui/material";
import Pic11 from "../../assets/hardware/11.png";
import Pic12 from "../../assets/hardware/12.png";
import Pic21 from "../../assets/hardware/21.png";
import Pic22 from "../../assets/hardware/22.png";
import Pic31 from "../../assets/hardware/31.png";
import Pic32 from "../../assets/hardware/32.png";
import Pic33 from "../../assets/hardware/33.png";
import Pic41 from "../../assets/hardware/41.png";
import Pic42 from "../../assets/hardware/42.png";
import Pic51 from "../../assets/hardware/51.png";
import Pic61 from "../../assets/hardware/61.png";
import Pic71 from "../../assets/hardware/71.png";
import Pic81 from "../../assets/hardware/81.png";
import bluetoothLogo from "../../assets/hardware/bluetoothLogo.png";
import USBLogo from "../../assets/hardware/USBLogo.png";
import androidLogo from "../../assets/hardware/androidLogo.png";
import appleLogo from "../../assets/hardware/appleLogo.png";
import winLogo from "../../assets/hardware/winLogo.png";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const BarLink = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  fontSize: 16,
  fontWeight: 400,
  height: "100%",
  lineHeight: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "20%",
  textAlign: "center",
  padding: "12px 16px",

  [theme.breakpoints.down("mg")]: {
    margin: "0 8px",
  },
  [theme.breakpoints.up("mg")]: {
    margin: "0 16px",
  },
}));

const PTitle = styled("div")(() => ({
  fontSize: 30,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  paddingTop: 6,
  paddingBottom: 6,
  textAlign: "center",
  width: '100%',
}));

const PInfo = styled("div")(() => ({
  fontSize: 20,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  textAlign: "center",
  width: '100%',
}));

const MainTitle = styled("div")(() => ({
  fontSize: 24,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  paddingTop: "30px",
  paddingBottom: "12px",
  marginBottom: "24px",
  borderBottom: "1px solid rgba(204, 204, 204, 1)",
}));

const ItemTitle = styled("div")(() => ({
  fontSize: 18,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  marginBottom: "12px",
}));

const ItemInfo = styled("div")(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  marginBottom: 8,
}));

function Pic({ pic }) {
  return (
    <img
      src={pic}
      alt=""
      style={{ width: 120, height: 120, display: "table-cell", verticalAlign: "top" }}
    />
  );
}

function LogoText({ Logo }) {
  return (
    <img
      src={Logo}
      alt=""
      style={{
        width: "24px",
        height: "24px",
        marginRight: 4,
        top: 7,
        position: "relative",
      }}
    />
  );
}

function LogoText1({ Logo }) {
  return (
    <img
      src={Logo}
      alt=""
      style={{
        width: "24px",
        height: "24px",
        marginRight: 8,
        top: 7,
        position: "relative",
      }}
    />
  );
}

function CarBox({
  id,
  logo,
  title,
  content,
  showBluetooth,
  showUSB,
  showAndoid,
  showApple,
  showWin,
  goupTitle
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toPage = () => {
    navigate(`/hardware/goods/info/${id}?title=${goupTitle}&name=${title}`)
  }
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        marginBottom: 3,
      }}
    >
      <Pic {...{ pic: logo }} />
      <div style={{ display: "table-cell", verticalAlign: "top", flex: 1, marginInlineStart: 16 }}>
        <ItemTitle>{title}</ItemTitle>
        <ItemInfo>
          {content}
          {["11", "12", "21", "22", "31", "32", "33", "51", "61", "71"].includes(id) ? (
            <Box
              onClick={toPage}
              sx={{
                color: "rgb(39,68,214)",
                display: "inline",
                cursor: "pointer",
              }}
            >
              {t("hardInfo.seeInfo")}
            </Box>
          ) : null}
        </ItemInfo>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            {showBluetooth ? (
              <div style={{ marginRight: 12, fontSize: 12 }}>
                <LogoText {...{ Logo: bluetoothLogo }} />
                Bluetooth
              </div>
            ) : null}
            {showUSB ? (
              <div style={{ marginRight: 25, fontSize: 12 }}>
                <LogoText {...{ Logo: USBLogo }} />
                USB
              </div>
            ) : null}
          </div>
          <div style={{ display: "flex" }}>
            {showWin ? <LogoText1 {...{ Logo: winLogo }} /> : null}
            {showAndoid ? <LogoText1 {...{ Logo: androidLogo }} /> : null}
            {showApple ? <LogoText1 {...{ Logo: appleLogo }} /> : null}
          </div>
        </div>
      </div>
    </Box>
  );
}

function Web() {
  const { t } = useTranslation();
  const a1 = {
    title: t("hardwareMenu.menu1"),
    datas: [
      {
        id: "11",
        logo: Pic11,
        title: t("hardInfo.title11"),
        content: t("hardInfo.info11"),
        showBluetooth: false,
        showUSB: true,
        showAndoid: true,
        showApple: false,
        showWin: true,
      },
      {
        id: "12",
        logo: Pic12,
        title: t("hardInfo.title12"),
        content: t("hardInfo.info12"),
        showBluetooth: false,
        showUSB: true,
        showAndoid: true,
        showApple: true,
        showWin: false,
      },
    ],
  };
  const a2 = {
    title: t("hardwareMenu.menu2"),
    datas: [
      {
        id: "21",
        logo: Pic21,
        title: t("hardInfo.title21"),
        content: t("hardInfo.info21"),
        showBluetooth: true,
        showUSB: true,
        showAndoid: true,
        showApple: false,
        showWin: false,
      },
      {
        id: "22",
        logo: Pic22,
        title: t("hardInfo.title22"),
        content: t("hardInfo.info22"),
        showBluetooth: true,
        showUSB: true,
        showAndoid: true,
        showApple: false,
        showWin: false,
      },
    ],
  };
  const a3 = {
    title: t("hardwareMenu.menu3"),
    datas: [
      {
        id: "31",
        logo: Pic31,
        title: t("hardInfo.title31"),
        content: t("hardInfo.info31"),
        showBluetooth: false,
        showUSB: true,
        showAndoid: true,
        showApple: false,
        showWin: false,
      },
      {
        id: "32",
        logo: Pic32,
        title: t("hardInfo.title32"),
        content: t("hardInfo.info32"),
        showBluetooth: false,
        showUSB: true,
        showAndoid: true,
        showApple: false,
        showWin: false,
      },
      {
        id: "33",
        logo: Pic33,
        title: t("hardInfo.title33"),
        content: t("hardInfo.info33"),
        showBluetooth: false,
        showUSB: true,
        showAndoid: true,
        showApple: false,
        showWin: false,
      },
    ],
  };
  const a4 = {
    title: t("hardwareMenu.menu4"),
    datas: [
      {
        id: "41",
        logo: Pic41,
        title: t("hardInfo.title41"),
        content: t("hardInfo.info41"),
        showBluetooth: false,
        showUSB: true,
        showAndoid: true,
        showApple: true,
        showWin: false,
      },
      {
        id: "42",
        logo: Pic42,
        title: t("hardInfo.title42"),
        content: t("hardInfo.info42"),
        showBluetooth: false,
        showUSB: true,
        showAndoid: true,
        showApple: true,
        showWin: false,
      },
    ],
  };
  const a5 = {
    title: t("hardwareMenu.menu5"),
    datas: [
      {
        id: "51",
        logo: Pic51,
        title: t("hardInfo.title51"),
        content: t("hardInfo.info51"),
        showBluetooth: false,
        showUSB: false,
        showAndoid: true,
        showApple: false,
        showWin: true,
      },
    ],
  };
  const a6 = {
    title: t("hardwareMenu.menu6"),
    datas: [
      {
        id: "61",
        logo: Pic61,
        title: t("hardInfo.title61"),
        content: t("hardInfo.info61"),
        showBluetooth: false,
        showUSB: true,
        showAndoid: true,
        showApple: false,
        showWin: false,
      },
    ],
  };
  const a7 = {
    title: t("hardwareMenu.menu7"),
    datas: [
      {
        id: "71",
        logo: Pic71,
        title: t("hardInfo.title71"),
        content: t("hardInfo.info71"),
        showBluetooth: false,
        showUSB: true,
        showAndoid: true,
        showApple: false,
        showWin: true,
      },
    ],
  };
  const a8 = {
    title: t("hardwareMenu.menu8"),
    datas: [
      {
        id: "81",
        logo: Pic81,
        title: t("hardInfo.title81"),
        content: t("hardInfo.info81"),
        showBluetooth: true,
        showUSB: true,
        showAndoid: true,
        showApple: true,
        showWin: false,
      },
    ],
  };
  const menus = [t("hardware.tab1"), "Android", "IOS", "Windows"];
  let arr1 = [a1, a2, a3, a4, a5, a6, a7, a8];
  let arr2 = [
    {
      title: a1.title,
      datas: a1.datas.filter((v) => v.showApple === true),
    }
  ];
  let arr3 = [
    {
      title: a1.title,
      datas: a1.datas.filter((v) => v.showWin === true),
    },
    {
      title: a2.title,
      datas: a2.datas.filter((v) => v.showWin === true),
    }
  ];
  const clickBarItem = function (index) {
    setTargetIndex(index);
  };

  let [targetIndex, setTargetIndex] = useState(0);

  const BoxContainer = styled(Box)(({ theme }) => ({
    maxWidth: '100%',
    display: "flex",
    [theme.breakpoints.down("lgUp")]: {
      margin: "0 24px",
    },
    [theme.breakpoints.up("lgUp")]: {
      margin: "0 auto",
    },
  }));

  const TopBarBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("mg")]: {
      display: "flex",
    },
    [theme.breakpoints.down("mg")]: {
      display: "none",
    },
  }));

  return (
    <Box sx={{display: {xs: 'flex', md: 'none'}, flexDirection: 'column'}}>
      <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <PTitle>{t("hardware.text")}</PTitle>
        <PInfo>{t("hardware.info")}</PInfo>
        <TopBarBox sx={{ height: 34, display: "flex" }}>
          {menus.map((v, index) => {
            return (
              <BarLink
                key={index}
                to={"bar" + index}
                onClick={() => clickBarItem(index)}
                activeClass="active"
                index={index}
                className={index === targetIndex ? "active" : ""}
              >
                {v}
              </BarLink>
            );
          })}
        </TopBarBox>
      </Box>
      <Box>
        <BoxContainer sx={{ display: "flex" }}>
          <Box
            sx={{ width: "100%" }}
          >
            {targetIndex === 2
              ? arr2.map((a, i) => <List key={i} {...a} i={i} />)
              : targetIndex === 3
              ? arr3.map((a, i) => <List key={i} {...a} i={i} />)
              : arr1.map((a, i) => <List key={i} {...a} i={i} />)}
          </Box>
        </BoxContainer>
      </Box>
    </Box>
  );
}

function List({ title, datas, i }) {
  return datas.length > 0 ? (
    <div id={"item" + i}>
      <MainTitle>{title}</MainTitle>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {datas.map((v) => {
          return <CarBox {...v} goupTitle={title} key={v.title} />;
        })}
      </Box>
    </div>
  ) : null;
}
export default Web;
