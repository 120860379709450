import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  Grid,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import React from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Textarea = styled('textarea')(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default function Info() {
  const [email, setEmail] = React.useState("");
  const [pwd, setPwd] = React.useState("");
  const [memo, setMemo] = React.useState("");
  const [telephone, setTelephone] = React.useState("");
  let { t } = useTranslation();

  const [showInfo, setShowInfo] = React.useState(false);
  const [showError, setShowError] = React.useState('');

  const disabled =
    !Boolean(email) ||
    !Boolean(pwd) ||
    !Boolean(memo) ||
    !Boolean(telephone);

  const submitForm = () => {
    if (disabled) {
      return;
    }

    const data = {
      email: email,
      memo: memo,
      pwd: pwd,
      telephone: telephone,
    };

    axios
      .post("https://m.toposfy.com/m/deleteApply/save", data)
      .then(function (response) {
        const { status, data } = response;
        if (status === 200 && data.code === 200) {
          setShowInfo(true);
          setShowError(t("deleteUser.successInfo"))
          setEmail('')
          setPwd('')
          setMemo('')
          setTelephone('')
          navigate(`/deleteAccountIndex?lang=${localStorage.getItem('lang') || 'en_GB'}`)
        } else {
          setShowInfo(true);
          setShowError(data.msg)
        }
        setTimeout(() => {
          setShowInfo(false);
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const navigate = useNavigate();
  const toNext = function () {
    navigate(`/privacyPolic?lang=${localStorage.getItem('lang') || 'en_GB'}`)
  }

  return (
    <>
      <Grid container sx={{ padding: '0 16px' }}>
        <Grid item xs={12} sx={{
          color: '#957ec2',
          fontSize: 22,
          textAlign: 'center',
          fontWeight: 600,
          marginTop: '11px',
          marginBottom: '16px'
        }}>
          {t("deleteUser.info1")}
        </Grid>
        <Grid item xs={12} sx={{
          fontSize: 15,
          fontWeight: 400,
          boxSizing: 'border-box',
          textIndent: '25px'
        }}>
          {t("deleteUser.info7")}
        </Grid>
        <Grid item xs={12} sx={{
          color: '#957ec2',
          fontSize: 17,
          textAlign: 'end',
          fontWeight: 700,
          marginTop: '11px'
        }} onClick={toNext}>
          {t("deleteUser.info8")}
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: '0 16px' }}>
        <Snackbar
          open={showInfo}
          autoHideDuration={6000}
          message={showError}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
        <Grid item xs={12} sx={{
          fontSize: 15,
          fontWeight: 400
        }}>
          {t("deleteUser.info9")}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("deleteUser.info10")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("deleteUser.info11")}
            value={pwd}
            onChange={(e) => setPwd(e.target.value)}
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Textarea
            style={{ width: "100%", resize: "none" }}
            minRows={5}
            placeholder={t("deleteUser.info12")}
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("deleteUser.info13")}
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginBottom: '55px' }}>
          {disabled ? (
            <Button color="success" variant="contained" disabled={disabled}>
              {t("deleteUser.info14")}
            </Button>
          ) : (
            <Button
              color="success"
              variant="contained"
              style={{ backgroundColor: "#957ec2" }}
              onClick={submitForm}
            >
              {t("deleteUser.info14")}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', fontSize: 13, alignContent: 'flex-end'}}>
          {t("deleteUser.info6")}
        </Grid>
      </Grid>
    </>
  );
}
