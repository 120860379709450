function DownBoxSvg({bgColor}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="264" height="264" viewBox="0 0 264 264" fill={bgColor}>
        <g filter="url(#filter_51_289)">
            <path fillRule="evenodd"  fill={bgColor}  d="M244.971 67.4661L188.534 11.0295C184.034 6.52856 177.929 4 171.564 4L36 4C22.7452 4 12 14.7452 12 28L12 220C12 233.255 22.7452 244 36 244L228 244C241.255 244 252 233.255 252 220L252 84.4366C252 78.0714 249.471 71.9669 244.971 67.4661Z">
            </path>
        </g>
        <defs>
        <filter id="filter_51_289" x="0" y="0" width="264" height="264" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="feFloodId_51_289"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha_51_289"/>
            <feOffset dx="0" dy="8"/>
            <feGaussianBlur stdDeviation="6"/>
            <feComposite in2="hardAlpha_51_289" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.00784313725490196 0 0 0 0 0.45098039215686275 0 0 0 0 0.23137254901960785 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="feFloodId_51_289" result="dropShadow_1_51_289"/>
            <feBlend mode="normal" in="SourceGraphic" in2="dropShadow_1_51_289" result="shape_51_289"/>
        </filter>
        </defs>
    </svg>
  )  
} 

export default DownBoxSvg