import Page from "./page";
import { useTranslation } from "react-i18next";
import qrcode from "../../assets/software/qrcodeBlue.png";
import { Box } from "@mui/material";
import Pic1 from "../../assets/software/kitchen/1.png";
import logo1 from "../../assets/software/kitchen/logo1.png";
import infoPic from "../../assets/software/kitchen/info.png";
import KitchenPic from '../../assets/software/kitchen.png';

function Kitchen() {
  let { t } = useTranslation();

  const datas = [
    {
      pic: Pic1,
      logo: logo1,
      title: t("kitchenType.reportTitle"),
      infos: [
        t("kitchenType.reportInfo1"),
        t("kitchenType.reportInfo2"),
        t("kitchenType.reportInfo3"),
        t("kitchenType.reportInfo4"),
      ],
      bgcolor:
        "linear-gradient(90deg, rgba(64, 158, 184, 0.31) 0%, rgba(16, 222, 115, 0.05) 100%)",
    },
  ];

  return (
    <>
      <Page
        {...{
          title: t("kitchenType.title"),
          content: t("kitchenType.info"),
          pic: KitchenPic,
          bgColor:
            "linear-gradient(90deg, rgba(16, 222, 115, 0.27) 0%, rgba(64, 158, 184, 1) 100%)",
          downInfo: {
            bgColor: "#31b077",
            qrBgColor: "#e3faec",
            qrcode: qrcode,
            showMac: false,
            urlName: 'toposify_kds'
          },
          bodyDatas: datas
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: "60px",
          height: 500,
          width: "100%",
        }}
      >
        <div
          style={{
            fontSize: 36,
            fontWeight: 700,
            color: "rgba(49, 55, 61, 1)",
            paddingBottom: 44
          }}
        >
          {t("kitchenType.title2")}
        </div>
        <div style={{ width: 934, height: 344, padding: '30px 16px' }}>
          <img src={infoPic} alt="" style={{ width: '100%', height: '100%' }}/>
        </div>
      </Box>
    </>
  );
}

export default Kitchen;
