export const datas = {
    "en_GB": [
        "User Agreement",
        "Before registering as a user of Toposfy , please read this User Agreement (hereinafter referred to as the \"Agreement\") carefully to ensure that you fully understand the terms of this Agreement. Please read carefully and choose to accept or not accept this Agreement. Unless you accept all the terms of this Agreement, you have no right to register, log in or use the services involved in this Agreement. Your registration, login, use and other actions will be deemed as acceptance of this Agreement and agreement to be bound by the terms of this Agreement.",
        "This Agreement stipulates the rights and obligations of this platform and user software services (hereinafter referred to as \"services\"). \"User\" refers to individuals who register, log in, and use this platform . This Agreement may be updated by this platform at any time. Once the updated agreement terms are announced, they will replace the original agreement terms without further notice. Users can check the latest version of the agreement terms on this platform . After modifying the terms of the agreement, if the user does not accept the modified terms, please stop using the services provided by this platform immediately . The user's continued use of the services provided by this platform will be deemed to have accepted the modified agreement.",
        "1. Account Registration",
        "1. Users may need to register an account when using this platform . This platform may change the account registration and binding method according to user needs or product needs without prior notice to users.",
        "2. In view of the account binding registration method, you agree that the mobile phone number , email address and automatic extraction of your mobile device identification code and other information you provide will be used for registration.",
        "2. Service Content",
        "functions of this platform are provided according to actual conditions , including cashier, statistical analysis, self-service ordering, online shopping mall, but not limited to these functions . This platform can change the services it provides, and the content of the services provided may change at any time .",
        "3. Protection of User Personal Privacy Information",
        "1. When registering an account or using this platform , users may need to fill in or submit some necessary information, such as identity information required by laws, regulations, and normative documents (hereinafter referred to as \"laws and regulations\"). If the information submitted by the user is incomplete or does not comply with the provisions of laws and regulations, the user may not be able to use this platform or may be restricted in the process of using this platform .",
        "2. Personal privacy information refers to information related to the user's personal identity or privacy, such as the user's real name, ID number, mobile phone number, mobile device identification code, and IP address. Non-personal privacy information refers to the basic record information of the user 's operation status and usage habits of this platform that is clearly and objectively reflected on the server side of this platform , other general information outside the scope of personal privacy information, and the above-mentioned privacy information that the user agrees to disclose.",
        "3. Respecting the privacy of users' personal privacy information is a consistent system of this platform . This platform will take technical measures and other necessary measures to ensure the security of users' personal privacy information and prevent the leakage, damage or loss of users' personal privacy information collected on this platform . When the aforementioned situation occurs or this platform finds that there is a possibility of the aforementioned situation, it will take remedial measures in a timely manner.",
        "4. This platform will not disclose or disclose any user's personal privacy information to any third party without the user's consent, except for the following specific circumstances:",
        "(1) Providing users’ personal privacy information in accordance with laws and regulations or instructions from competent authorities;",
        "(2) Any leakage of personal information caused by the user telling others his/her user password or sharing the registered account and password with others, or other leakage of personal privacy information caused by reasons not caused by the Platform ;",
        "(3) Users disclose their personal privacy information to third parties on their own initiative;",
        "(4) The user reaches an agreement with the Platform and its partners on the use and disclosure of the user's personal privacy information, and the Platform therefore discloses the user's personal privacy information to its partners;",
        "(5) Any leakage of user’s personal privacy information due to hacker attacks, computer virus intrusion or other force majeure events.",
        "5. The user agrees that the platform may use the user's personal privacy information in the following matters:",
        "(1) This platform sends important notifications or reminders to users in a timely manner , such as the software will use the user's personal privacy information for any other purpose.",
        "6. This platform attaches great importance to the protection of the personal privacy information of minors. It will rely on the personal information provided by the user to determine whether the user is a minor.",
        "7. The user confirms that his/her geographic location information is non-personal privacy information. The user's successful registration of an account is deemed to confirm the authorization for this platform to extract, disclose and use the user's geographic location information.",
        "8. In order to improve technology and services and provide users with a better service experience, this platform may collect and use users' non-personal privacy information on its own or provide it to third parties.",
        "IV. Content Standards",
        "1. The content referred to in this article refers to any content produced, uploaded, copied, published, and disseminated by users in the process of using this platform , including but not limited to account avatar, name, user description and other registration information and authentication information, or text, voice, picture, video, graphic, etc., reply or automatic reply message and related link pages, as well as other content generated by the use of the account or this platform .",
        "2. Users may not use their accounts or this platform to create, upload, copy, publish, or disseminate content prohibited by laws, regulations, and policies:",
        "(1) Opposing the basic principles established by the Constitution;",
        "(2) endangering national security, leaking state secrets, subverting state power, or undermining national unity;",
        "",
        "Modification and Termination",
        "Revise",
        "This Agreement is subject to change. If there are any substantial changes to this Agreement, we will notify you by email. Continued use after the change notification means that you are aware of such changes and agree to be bound by the terms;",
        "",
        "We reserve the right to modify, reserve or terminate any service at any time without notice.",
        "",
        "You agree that we do not have the right to modify, reserve or shut down any service;",
        "",
        "You agree that we shall not be liable to you or any third party for any modification, retention or closure of any service.",
        "",
        "termination",
        "This Agreement shall take effect from the date you accept it and shall remain in effect during your use until terminated in accordance with this Agreement;",
        "",
        "Notwithstanding the above provisions, if you use the Service earlier than the time you accept this Agreement, you hereby acknowledge and agree that this Agreement will take effect at the time you accept this Agreement, unless terminated earlier in accordance with this Agreement;",
        "",
        "We may retain your right to use or this account as required by law; we may terminate this Agreement at any time for any reason, with or without notice, including if we believe in good faith that you have violated our Acceptable Use Policy or other provisions of this Agreement;",
        "",
        "Notwithstanding the provisions of the preceding paragraph, if a user infringes upon the copyright of a third party and we receive notice from the copyright owner or the copyright owner's legal agent, we reserve the right to terminate this Agreement;",
        "",
        "Once this Agreement is terminated, your right to use will terminate. You should be aware that termination of your product means that your user content will be deleted from our active database. We will not be liable to you for any termination of this Agreement, including termination of your user account and deletion of your user content.",
    ],
    "zh_CN": [
        "用户协议",
        "在注册Toposfy成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。",
        "本协议约定本平台与用户软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本平台的个人。本协议可由本平台随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本平台查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用本平台提供的服务，用户继续使用本平台提供的服务将被视为接受修改后的协议。",
        "一、账号注册",
        "1、用户在使用本平台时可能需要注册一个账号。本平台可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。",
        "2、鉴于账号的绑定注册方式，您同意在注册时将使用您提供的手机号码、邮件及自动提取您的手机设备识别码等信息用于注册。",
        "二、服务内容",
        "本平台的具体功能根据实际情况提供，包括收银、统计分析、自助点餐、在线商城，但不限于这些功能。本平台可以对其提供的服务予以变更，且提供的服务内容可能随时变更。",
        "三、用户个人隐私信息保护",
        "1、用户在注册账号或使用本平台的过程中，可能需要填写或提交一些必要的信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本平台或在使用本平台的过程中受到限制。",
        "2、个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址。非个人隐私信息是指用户对本平台的操作状态以及使用习惯等明确且客观反映在本平台服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。",
        "3、尊重用户个人隐私信息的私有性是本平台的一贯制度，本平台将采取技术措施和其他必要措施，确保用户个人隐私信息安全，防止在本平台中收集的用户个人隐私信息泄露、毁损或丢失。在发生前述情形或者本平台发现存在发生前述情形的可能时，将及时采取补救措施。",
        "4、本平台未经用户同意不向任何第三方公开、 透露用户个人隐私信息。但以下特定情形除外：",
        "(1)根据法律法规规定或有权机关的指示提供用户的个人隐私信息；",
        "(2) 由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因本平台原因导致的个人隐私信息的泄露；",
        "(3) 用户自行向第三方公开其个人隐私信息；",
        "(4) 用户与本平台及合作方之间就用户个人隐私信息的使用公开达成约定，本平台因此向合作方公开用户个人隐私信息；",
        "(5) 任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。",
        "5、用户同意本平台可在以下事项中使用用户的个人隐私信息：",
        "(1) 本平台向用户及时发送重要通知或提示，如软件会将用户个人隐私信息使用于任何其他用途。",
        "6、本平台重视对未成年人个人隐私信息的保护。将依赖用户提供的个人信息判断用户是否为未成年人。",
        "7、用户确认，其地理位置信息为非个人隐私信息，用户成功注册账号视为确认授权本平台提取、公开及使用用户的地理位置信息。",
        "8、为了改善技术和服务，向用户提供更好的服务体验，本平台可能会自行收集使用或向第三方提供用户的非个人隐私信息。",
        "四、内容规范",
        "1、本条所述内容是指用户使用本平台过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等、回复或自动回复消息和相关链接页面，以及其他使用账号或本平台所产生的内容。",
        "2、用户不得利用账号或本平台制作、上载、复制、发布、传播法律、法规和政策禁止的内容：",
        "(1) 反对宪法所确定的基本原则的；",
        "(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；",
        "",
        "修改和终止",
        "修改",
        "本协议容许变更。如果本协议有任何实质性变更，我们将通过电子邮件来通知您。变更通知之后，继续使用则为您已知晓此类变更并同意条款约束；",
        "",
        "我们保留在任何时候无需通知而修改、保留或关闭任何服务之权利；",
        "",
        "您已同意我们无需因修改、保留或关闭任何服务之权利；",
        "",
        "您已同意我们无需因修改、保留或关闭任何服务的行为对您或第三方承担责任。",
        "",
        "终止",
        "本协议自您接受之日起生效，在您使用的过程中持续有效，直至依据本协议终止；",
        "",
        "尽管有上述规定，如果您使用的时间早于您接受本协议的时间，您在此知晓并同意本协议于您接受本协议的时间，您在此知晓并同意本协议于您第一次使用时生效，除非依据本协议提前终止；",
        "",
        "我们可能会依据法律的规定，保留您使用或者本账户的权利；无论是否通知，我们将在任何时间以任何原因终止本协议，包括出于善意的相信您违反了我们可接受使用政策或本协议的其他规定；",
        "",
        "不受前款规定所限，如果用户侵犯第三人的版权且我们接到版权所有人或版权所有人的合法代理人的通知后，我们保留终止本协议的权利；",
        "",
        "一旦本协议终止，您使用的权利即告终止。您应当知晓您的产品终止意味着您的用户内容将从我们的活动数据库中删除。我们不因终止本协议对您承担任何责任，包括终止您的用户账户和删除您的用户内容。",
    ],
    "zh_TW": [
        "使用者協定",
        "在註冊Toposfy成為使用者之前，請認真閱讀本《使用者協議》（以下簡稱「協議」），確保您充分理解本協議中各條款。請您審慎閱讀並選擇接受或不接受本協議。除非您接受本協議所有條款，否則您無權註冊、登入或使用本協議所涉服務。您的註冊、登入、使用等行為將視為對本協議的接受，並同意接受本協議各項條款的約束。",
        "本協議約定本平台與使用者軟體服務（以下簡稱「服務」）的權利義務。 「用戶」指註冊、登入、使用本平台的個人。本協議可由本平台隨時更新，更新後的協議條款一旦公佈即代替原來的協議條款，恕不再另行通知，用戶可在本平台查閱最新版協議條款。在修改協議條款後，如果使用者不接受修改後的條款，請立即停止使用本平台提供的服務，使用者繼續使用本平台提供的服務將被視為接受修改後的協議。",
        "一、帳號註冊",
        "1、用戶在使用本平台時可能需要註冊一個帳號。本平台可根據使用者需求或產品需求對帳號註冊和綁定的方式進行變更，而無須事先通知使用者。",
        "2.鑑於帳號的綁定註冊方式，您同意在註冊時將使用您提供的手機號碼、郵件及自動提取您的手機設備識別碼等資訊用於註冊。",
        "二、服務內容",
        "本平台的具體功能根據實際情況提供，包括收銀、統計分析、自助點餐、線上商城，但不限於這些功能。本平台可對其提供的服務予以變更，且提供的服務內容可能隨時變更。",
        "三、用戶個人隱私資訊保護",
        "1、使用者在註冊帳號或使用本平台的過程中，可能需要填寫或提交一些必要的信息，如法律法規、規章規範性文件（以下稱「法律法規」）規定的需要填寫的身份資訊。如使用者提交的資訊不完整或不符合法律法規的規定，則使用者可能無法使用本平台或在使用本平台的過程中受到限制。",
        "2.個人隱私資訊是指涉及使用者個人身分或個人隱私的訊息，例如，使用者真實姓名、身分證號碼、手機號碼、手機設備識別碼、IP位址。非個人隱私資訊是指使用者對本平台的操作狀態以及使用習慣等明確且客觀反映在本平台伺服器端的基本記錄資訊、個人隱私資訊範圍外的其它普通信息，以及使用者同意公開的上述隱私資訊。",
        "3.尊重使用者個人隱私資訊的私有性是本平台的一貫制度，本平台將採取技術措施和其他必要措施，確保使用者個人隱私資訊安全，防止在本平台中收集的使用者個人隱私資訊外洩、毀損或丟失。在發生前述情形或本平台發現有發生前述情形的可能時，將及時採取補救措施。",
        "4、本平台未經用戶同意不向任何第三方公開、 透露用戶個人隱私資訊。但以下特定情形除外：",
        "(1)依法令規定或有權機關的指示提供使用者的個人隱私資訊；",
        "(2) 由於使用者將其使用者密碼告知他人或與他人共享註冊帳戶與密碼，由此導致的任何個人資訊的洩漏，或其他非因本平台原因導致的個人隱私資訊的洩露；",
        "(3) 用戶自行向第三方公開其個人隱私資訊；",
        "(4) 用戶與本平台及合作方之間就用戶個人隱私資訊的使用公開達成約定，本平台因此向合作方公開用戶個人隱私資訊；",
        "(5) 任何因駭客攻擊、電腦病毒侵入及其他不可抗力事件導致使用者個人隱私資訊的外洩。",
        "5、使用者同意本平台可在下列事項中使用使用者的個人隱私資訊：",
        "(1)本平台及時向使用者發送重要通知或提示，如軟體會將使用者個人隱私資訊使用於任何其他用途。",
        "6.本平台重視對未成年人個人隱私資訊的保護。將依賴用戶提供的個人資訊判斷用戶是否為未成年人。",
        "7.使用者確認，其地理位置資訊為非個人隱私訊息，使用者成功註冊帳號視為確認授權本平台擷取、公開及使用使用者的地理位置資訊。",
        "8.為了改善技術和服務，提供使用者更好的服務體驗，本平台可能會自行收集使用或向第三方提供使用者的非個人隱私資訊。",
        "四、內容規範",
        "1、本條所述內容是指用戶使用本平台過程中所製作、上載、複製、發布、傳播的任何內容，包括但不限於帳號頭像、名稱、用戶說明等註冊資訊及認證資料，或文字、語音、圖片、影片、圖文等、回覆或自動回覆訊息和相關連結頁面，以及其他使用帳號或本平台所產生的內容。",
        "2、使用者不得利用帳號或本平台製作、上載、複製、發布、傳播法律、法規及政策禁止的內容：",
        "(1) 反對憲法所確定的基本原則的；",
        "(2) 危害國家安全，洩漏國家秘密，顛覆國家政權，破壞國家統一的；",
        "",
        "修改和終止",
        "修改",
        "本協議容許變更。如果本協議有任何實質變更，我們將透過電子郵件通知您。變更通知之後，繼續使用則為您已知曉此類變更並同意條款約束；",
        "",
        "我們保留在任何時候無需通知而修改、保留或關閉任何服務之權利；",
        "",
        "您已同意我們無須因修改、保留或關閉任何服務之權利；",
        "",
        "您已同意我們無須因修改、保留或關閉任何服務的行為而對您或第三方承擔責任。",
        "",
        "終止",
        "本協議自您接受之日起生效，在您使用的過程中持續有效，直至依據本協議終止；",
        "",
        "儘管有上述規定，如果您使用的時間早於您接受本協議的時間，您在此知曉並同意本協議於您接受本協議的時間，您在此知曉並同意本協議於您第一次使用時生效，除非依據本協議提前終止；",
        "",
        "我們可能會依據法律的規定，保留您使用或本帳戶的權利；無論是否通知，我們將在任何時間以任何原因終止本協議，包括出於善意的相信您違反了我們可接受使用政策或本協議的其他規定；",
        "",
        "不受前項規定所限，如果使用者侵犯第三人的版權且我們接到版權所有人或版權所有人的合法代理人的通知後，我們保留終止本協議的權利；",
        "",
        "一旦本協議終止，您使用的權利即告終止。您應知道您的產品終止意味著您的使用者內容將從我們的活動資料庫中刪除。我們不因終止本協議對您承擔任何責任，包括終止您的使用者帳戶和刪除您的使用者內容。",
    ],
    "fr_FR": [
        "Contrat d'utilisation",
        "Avant de vous inscrire en tant qu'utilisateur sur Toposfy , veuillez lire attentivement cet « Accord d'utilisation » (ci-après dénommé « l'Accord ») pour vous assurer que vous comprenez parfaitement les termes de cet Accord. Veuillez lire attentivement et choisir d'accepter ou de ne pas accepter cet accord. Sauf si vous acceptez tous les termes du présent accord, vous n'avez pas le droit de vous inscrire, de vous connecter ou d'utiliser les services couverts par le présent accord. Votre inscription, connexion, utilisation, etc. sera considérée comme votre acceptation du présent accord et votre accord d'être lié par les termes du présent accord.",
        "Cet accord stipule les droits et obligations de cette plateforme et des services logiciels utilisateurs (ci-après dénommés « services »). « Utilisateur » fait référence à une personne physique qui s'inscrit, se connecte et utilise cette plateforme . Cet accord peut être mis à jour par cette plateforme à tout moment. Une fois les termes de l'accord mis à jour annoncés, ils remplaceront les termes de l'accord d'origine sans autre préavis. Les utilisateurs peuvent consulter la dernière version des termes de l'accord sur cette plateforme . Après avoir modifié les termes de l'accord, si l'utilisateur n'accepte pas les conditions modifiées, veuillez cesser immédiatement d'utiliser les services fournis par cette plateforme . L'utilisation continue par l'utilisateur des services fournis par cette plateforme sera réputée accepter l'accord modifié.",
        "1. Enregistrement du compte",
        "1. Les utilisateurs devront peut-être créer un compte lorsqu'ils utilisent cette plateforme . Cette plate-forme peut modifier la méthode d'enregistrement et de liaison du compte en fonction des besoins des utilisateurs ou des besoins du produit sans en informer les utilisateurs à l'avance.",
        "2. Compte tenu de la méthode d'enregistrement contraignant le compte, vous acceptez d'utiliser le numéro de téléphone mobile , l'e-mail et d'extraire automatiquement le code d'identification de votre appareil mobile et les autres informations que vous avez fournies lors de votre inscription.",
        "2. Contenu des services",
        "fonctions spécifiques de cette plateforme sont fournies en fonction des conditions réelles , notamment la caisse, l'analyse statistique, la commande en libre-service et le centre commercial en ligne, mais ne se limitent pas à ces fonctions . Cette plateforme peut apporter des modifications aux services qu'elle propose, et le contenu des services fournis peut changer à tout moment .",
        "3. Protection des informations personnelles de la vie privée des utilisateurs",
        "1. Au cours du processus d'enregistrement d'un compte ou d'utilisation de cette plateforme , les utilisateurs peuvent avoir besoin de remplir ou de soumettre certaines informations nécessaires, telles que des informations d'identité qui doivent être renseignées comme stipulé dans les lois, réglementations et documents réglementaires (ci-après dénommés comme « lois et règlements »). Si les informations soumises par l'utilisateur sont incomplètes ou ne sont pas conformes aux lois et réglementations, l'utilisateur pourrait ne pas être en mesure d'utiliser cette plateforme ou pourrait être limité dans le processus d' utilisation de cette plateforme .",
        "2. Les informations personnelles sur la confidentialité font référence aux informations impliquant l'identité personnelle ou la vie privée de l'utilisateur, telles que le vrai nom de l'utilisateur, son numéro d'identification, son numéro de téléphone mobile, le code d'identification de l'appareil mobile et son adresse IP. Les informations privées non personnelles font référence à l'état de fonctionnement de l'utilisateur et aux habitudes d'utilisation de cette plate-forme , qui sont clairement et objectivement reflétées dans les informations d'enregistrement de base côté serveur de cette plate-forme , d'autres informations générales en dehors du champ d'application des informations de confidentialité personnelles, et les informations privées mentionnées ci-dessus que les utilisateurs acceptent de divulguer.",
        "3. Le respect de la confidentialité des informations personnelles des utilisateurs est un système cohérent de cette plate-forme . Cette plate-forme prendra des mesures techniques et d'autres mesures nécessaires pour garantir la sécurité des informations personnelles des utilisateurs et empêcher la collecte des informations personnelles des utilisateurs sur cette plate-forme. contre les fuites, les dommages ou la perte. Lorsque la situation ci-dessus se produit ou que la plateforme découvre qu'il existe une possibilité que la situation ci-dessus se produise, des mesures correctives seront prises en temps opportun.",
        "4. Cette plateforme ne divulguera ni ne divulguera les informations personnelles des utilisateurs à des tiers sans le consentement de l'utilisateur. Toutefois, les circonstances particulières suivantes sont exclues :",
        "(1) Fournir les informations personnelles relatives à la confidentialité des utilisateurs conformément aux lois et réglementations ou aux instructions des autorités compétentes ;",
        "(2) Toute fuite d'informations personnelles causée par le fait que les utilisateurs communiquent à d'autres leurs mots de passe d'utilisateur ou partagent des comptes enregistrés et des mots de passe avec d'autres, ou d'autres fuites d'informations personnelles non causées par cette plateforme ;",
        "(3) Les utilisateurs divulguent leurs informations personnelles à des tiers de leur propre initiative ;",
        "(4) L'utilisateur a conclu un accord avec la plateforme et ses partenaires sur l'utilisation et la divulgation des informations personnelles de l'utilisateur, et la plateforme divulgue donc les informations personnelles de l'utilisateur au partenaire ;",
        "(5) Toute fuite des informations personnelles des utilisateurs en raison d’attaques de pirates informatiques, d’intrusions de virus informatiques et d’autres événements de force majeure.",
        "5. L'utilisateur accepte que cette plateforme puisse utiliser les informations personnelles de confidentialité de l'utilisateur dans les domaines suivants :",
        "(1) Cette plate-forme envoie des avis ou des rappels importants aux utilisateurs en temps opportun . Par exemple, le logiciel utilisera les informations personnelles des utilisateurs à toute autre fin.",
        "6. Cette plateforme attache une grande importance à la protection des informations personnelles des mineurs. Les informations personnelles fournies par l'utilisateur seront utilisées pour déterminer si l'utilisateur est mineur.",
        "7. L'utilisateur confirme que ses informations de localisation géographique sont des informations privées non personnelles. L'enregistrement réussi d'un compte par l'utilisateur est réputé confirmer que la plateforme est autorisée à extraire, divulguer et utiliser les informations de localisation géographique de l'utilisateur.",
        "8. Afin d'améliorer la technologie et les services et d'offrir aux utilisateurs une meilleure expérience de service, cette plateforme peut collecter et utiliser elle-même les informations non personnelles relatives à la confidentialité des utilisateurs ou les fournir à des tiers.",
        "4. Spécifications du contenu",
        "1. Le contenu mentionné dans cet article fait référence à tout contenu produit, téléchargé, copié, publié et diffusé par les utilisateurs lors de l'utilisation de cette plateforme , y compris, mais sans s'y limiter, l'avatar du compte, le nom, la description de l'utilisateur et d'autres informations d'inscription et informations d'authentification. , ou du texte, de la voix, des images, des vidéos, des graphiques, etc., des messages de réponse ou de réponse automatique et des pages de liens associées, ainsi que d'autres contenus générés par l'utilisation du compte ou de cette plateforme .",
        "2. Les utilisateurs ne sont pas autorisés à utiliser leurs comptes ou cette plateforme pour créer, télécharger, copier, publier ou diffuser du contenu interdit par les lois, réglementations et politiques :",
        "(1) S'opposer aux principes fondamentaux établis par la Constitution ;",
        "(2) Mettre en danger la sécurité nationale, divulguer des secrets d'État, renverser le pouvoir de l'État et saper l'unité nationale ;",
        "",
        "Modification et résiliation",
        "Réviser",
        "Cet accord est sujet à changement. Si des modifications importantes sont apportées au présent Accord, nous vous en informerons par e-mail. Après notification des modifications, la poursuite de l'utilisation signifie que vous êtes au courant de ces modifications et acceptez d'être lié par les conditions ;",
        "",
        "Nous nous réservons le droit de modifier, conserver ou fermer tout service à tout moment et sans préavis ;",
        "",
        "Vous acceptez que nous n'avons pas le droit de modifier, conserver ou fermer un service ;",
        "",
        "Vous acceptez que nous ne serons pas responsables envers vous ou des tiers de la modification, de la conservation ou de la fermeture de tout Service.",
        "",
        "terminaison",
        "Le présent Contrat prend effet à la date à laquelle vous l'acceptez et restera en vigueur pendant votre utilisation jusqu'à sa résiliation conformément au présent Contrat ;",
        "",
        "Nonobstant ce qui précède, si votre utilisation est antérieure au moment où vous acceptez le présent Contrat, vous reconnaissez et acceptez par la présente que le présent Contrat entrera en vigueur au moment où vous accepterez le présent Contrat. Vous reconnaissez et acceptez par la présente que le présent Contrat entrera en vigueur au moment où vous l'accepterez pour la première fois. l'utiliser en vigueur à moins qu'il ne soit résilié plus tôt conformément au présent Accord ;",
        "",
        "Nous pouvons réserver votre droit d'utiliser ce compte conformément aux dispositions de la loi ; nous résilierons cet accord à tout moment et pour quelque raison que ce soit avec ou sans préavis, y compris en croyant de bonne foi que vous avez violé notre politique d'utilisation acceptable ou d'autres dispositions du présent Accord ;",
        "",
        "Non limité par les dispositions du paragraphe précédent, si un utilisateur enfreint le droit d'auteur d'un tiers et que nous recevons une notification du propriétaire du droit d'auteur ou de l'agent légal du propriétaire du droit d'auteur, nous nous réservons le droit de résilier cet accord ;",
        "",
        "À la résiliation du présent Contrat, votre droit de l’utiliser prendra fin. Vous devez savoir que la résiliation de votre produit signifiera que votre contenu utilisateur sera supprimé de nos bases de données actives. Nous n'aurons aucune responsabilité envers vous en cas de résiliation du présent Contrat, y compris la résiliation de votre compte utilisateur et la suppression de votre Contenu utilisateur.",

    ],
    "es_ES": [
        "Acuerdo de usuario",
        "Antes de registrarse como usuario en Toposfy , lea atentamente este \"Acuerdo de usuario\" (en adelante, el \"Acuerdo\") para asegurarse de que comprende completamente los términos de este Acuerdo. Lea atentamente y elija aceptar o no este acuerdo. A menos que acepte todos los términos de este Acuerdo, no tiene derecho a registrarse, iniciar sesión o utilizar los servicios cubiertos por este Acuerdo. Su registro, inicio de sesión, uso, etc. se considerará como su aceptación de este Acuerdo y su acuerdo de estar sujeto a los términos de este Acuerdo.",
        "Este acuerdo estipula los derechos y obligaciones de esta plataforma y los servicios de software del usuario (en adelante, \"servicios\"). \"Usuario\" se refiere a una persona física que se registra, inicia sesión y utiliza esta plataforma . Esta plataforma puede actualizar este acuerdo en cualquier momento. Una vez que se anuncien los términos del acuerdo actualizados, reemplazarán los términos del acuerdo original sin previo aviso. Los usuarios pueden consultar la última versión de los términos del acuerdo en esta plataforma . Después de modificar los términos del acuerdo, si el usuario no acepta los términos modificados, deje de usar inmediatamente los servicios proporcionados por esta plataforma . Se considerará que el usuario continúa usando los servicios proporcionados por esta plataforma .",
        "1. Registro de cuenta",
        "1. Es posible que los usuarios necesiten registrar una cuenta cuando utilicen esta plataforma . Esta plataforma puede cambiar el método de registro y vinculación de la cuenta según las necesidades del usuario o las necesidades del producto sin notificar a los usuarios con anticipación.",
        "2. En vista del método de registro vinculante de la cuenta, usted acepta utilizar el número de teléfono móvil , el correo electrónico y extraer automáticamente el código de identificación de su dispositivo móvil y otra información que proporcionó al registrarse.",
        "2. Contenido del servicio",
        "funciones específicas de esta plataforma se proporcionan en función de las condiciones reales , incluido el cajero, el análisis estadístico, los pedidos de autoservicio y el centro comercial en línea, pero no se limitan a estas funciones . Esta plataforma puede realizar cambios en los servicios que brinda, y el contenido de los servicios brindados puede cambiar en cualquier momento .",
        "3. Protección de la información de privacidad personal de los usuarios",
        "1. Durante el proceso de registro de una cuenta o uso de esta plataforma , es posible que los usuarios deban completar o enviar cierta información necesaria, como información de identidad que debe completarse según lo estipulado en las leyes, regulaciones y documentos reglamentarios (en adelante denominado como \"leyes y reglamentos\"). Si la información enviada por el usuario está incompleta o no cumple con las leyes y regulaciones, es posible que el usuario no pueda usar esta plataforma o que se le restrinja el proceso de uso de esta plataforma .",
        "2. La información de privacidad personal se refiere a información que involucra la identidad personal o la privacidad personal del usuario, como el nombre real del usuario, el número de identificación, el número de teléfono móvil, el código de identificación del dispositivo móvil y la dirección IP. La información privada no personal se refiere al estado operativo del usuario y los hábitos de uso de esta plataforma , que se reflejan clara y objetivamente en la información de registro básica en el lado del servidor de esta plataforma , otra información general fuera del alcance de la información de privacidad personal, y la información privada antes mencionada que los usuarios aceptan revelar.",
        "3. Respetar la privacidad de la información de privacidad personal de los usuarios es un sistema consistente de esta plataforma . Esta plataforma tomará medidas técnicas y otras medidas necesarias para garantizar la seguridad de la información de privacidad personal de los usuarios y evitar que la información de privacidad personal de los usuarios se recopile en esta plataforma. de que se filtre, dañe o pierda. Cuando ocurra la situación anterior o la plataforma descubra que existe la posibilidad de que ocurra, se tomarán medidas correctivas de manera oportuna.",
        "4. Esta plataforma no divulgará ni revelará la información de privacidad personal de los usuarios a ningún tercero sin el consentimiento del usuario. Sin embargo, quedan excluidas las siguientes circunstancias específicas:",
        "(1) Proporcionar información de privacidad personal de los usuarios de acuerdo con las leyes y regulaciones o instrucciones de las autoridades competentes;",
        "(2) Cualquier filtración de información personal causada por usuarios que dicen a otros sus contraseñas de usuario o comparten cuentas registradas y contraseñas con otros, u otras fugas de información de privacidad personal no causadas por esta plataforma ;",
        "(3) Los usuarios revelan su información de privacidad personal a terceros por iniciativa propia;",
        "(4) El usuario ha llegado a un acuerdo con la plataforma y sus socios sobre el uso y divulgación de la información de privacidad personal del usuario y, por lo tanto, la plataforma divulga la información de privacidad personal del usuario al socio;",
        "(5) Cualquier filtración de información de privacidad personal de los usuarios debido a ataques de piratas informáticos, intrusiones de virus informáticos y otros eventos de fuerza mayor.",
        "5. El usuario acepta que esta plataforma puede utilizar la información de privacidad personal del usuario en los siguientes asuntos:",
        "(1) Esta plataforma envía avisos o recordatorios importantes a los usuarios de manera oportuna . Por ejemplo, el software utilizará la información de privacidad personal de los usuarios para cualquier otro propósito.",
        "6. Esta plataforma concede gran importancia a la protección de la información de privacidad personal de los menores. Se confiará en la información personal proporcionada por el usuario para determinar si el usuario es menor de edad.",
        "7. El usuario confirma que su información de ubicación geográfica es información privada no personal. Se considera que el registro exitoso de una cuenta por parte del usuario confirma que la plataforma está autorizada para extraer, revelar y utilizar la información de ubicación geográfica del usuario.",
        "8. Para mejorar la tecnología y los servicios y brindar a los usuarios una mejor experiencia de servicio, esta plataforma puede recopilar y utilizar información de privacidad no personal de los usuarios por sí misma o proporcionársela a terceros.",
        "4. Especificaciones de contenido",
        "1. El contenido mencionado en este artículo se refiere a cualquier contenido producido, subido, copiado, publicado y difundido por los usuarios durante el uso de esta plataforma , incluidos, entre otros, el avatar de la cuenta, el nombre, la descripción del usuario y otra información de registro e información de autenticación. , o texto, voz, imágenes, videos, gráficos, etc., mensajes de respuesta o respuesta automática y páginas de enlaces relacionados, y otro contenido generado mediante el uso de la cuenta o esta plataforma .",
        "2. Los usuarios no pueden utilizar sus cuentas o esta plataforma para crear, cargar, copiar, publicar o difundir contenido prohibido por leyes, regulaciones y políticas:",
        "(1) Oponerse a los principios básicos establecidos por la Constitución;",
        "(2) Poner en peligro la seguridad nacional, filtrar secretos de Estado, subvertir el poder del Estado y socavar la unidad nacional;",
        "",
        "Modificación y terminación",
        "Revisar",
        "Este Acuerdo está sujeto a cambios. Si hay algún cambio material en este Acuerdo, se lo notificaremos por correo electrónico. Después de la notificación de los cambios, el uso continuado significa que usted está al tanto de dichos cambios y acepta estar sujeto a los términos;",
        "",
        "Nos reservamos el derecho de modificar, conservar o cerrar cualquier servicio en cualquier momento sin previo aviso;",
        "",
        "Usted acepta que no tenemos derecho a modificar, conservar o cerrar ningún servicio;",
        "",
        "Usted acepta que no seremos responsables ante usted ni ante terceros por modificar, conservar o cerrar cualquier Servicio.",
        "",
        "terminación",
        "Este Acuerdo entra en vigor a partir de la fecha en que lo acepta y seguirá vigente durante su uso hasta que se rescinda de conformidad con este Acuerdo;",
        "",
        "Sin perjuicio de lo anterior, si su uso es anterior al momento en que acepta este Acuerdo, por la presente reconoce y acepta que este Acuerdo entrará en vigor en el momento en que lo acepte. Por la presente reconoce y acepta que este Acuerdo entrará en vigor cuando lo acepte por primera vez. utilizarlo con vigencia a menos que se rescinda antes de conformidad con este Acuerdo;",
        "",
        "Podemos reservarnos su derecho a usar esta cuenta de acuerdo con las disposiciones de la ley; rescindiremos este Acuerdo en cualquier momento y por cualquier motivo, con o sin previo aviso, incluso si cree de buena fe que ha violado nuestra Política de uso aceptable o este Acuerdo otras disposiciones;",
        "",
        "Sin estar limitado por las disposiciones del párrafo anterior, si un usuario infringe los derechos de autor de un tercero y recibimos una notificación del propietario de los derechos de autor o del agente legal del propietario de los derechos de autor, nos reservamos el derecho de rescindir este acuerdo;",
        "",
        "Tras la terminación de este Acuerdo, su derecho a utilizarlo terminará. Debe tener en cuenta que la cancelación de su Producto significará que su Contenido de usuario se eliminará de nuestras bases de datos activas. No seremos responsables ante usted por la terminación de este Acuerdo, incluida la terminación de su cuenta de usuario y la eliminación de su Contenido de usuario.",
    ],
    "pt_PT": [
        "Contrato do usuário",
        "Antes de se registrar como usuário no Toposfy , leia este \"Contrato do Usuário\" (doravante denominado \"Contrato\") cuidadosamente para garantir que você compreende totalmente os termos deste Contrato. Por favor, leia atentamente e escolha aceitar ou não este acordo. A menos que aceite todos os termos deste Contrato, você não tem o direito de se registrar, fazer login ou usar os serviços cobertos por este Contrato. Seu registro, login, uso, etc. serão considerados como sua aceitação deste Contrato e sua concordância em ficar vinculado aos termos deste Contrato.",
        "Este acordo estipula os direitos e obrigações desta plataforma e dos serviços de software do usuário (doravante denominados \"serviços\"). \"Usuário\" refere-se a um indivíduo que se registra, faz login e utiliza esta plataforma . Este contrato pode ser atualizado por esta plataforma a qualquer momento. Assim que os termos do contrato atualizados forem anunciados, eles substituirão os termos do contrato original sem aviso prévio. Os usuários podem verificar a versão mais recente dos termos do contrato nesta plataforma . Após a modificação dos termos do contrato, caso o usuário não aceite os termos modificados, pare imediatamente de utilizar os serviços prestados por esta plataforma . O uso continuado do usuário dos serviços prestados por esta plataforma será considerado como aceitação do contrato modificado.",
        "1. Registro de conta",
        "1. Os usuários podem precisar registrar uma conta ao usar esta plataforma . Esta plataforma pode alterar o método de registro e vinculação da conta de acordo com as necessidades do usuário ou do produto, sem notificar os usuários com antecedência.",
        "2. Tendo em vista o método de registro de vinculação de conta, você concorda em usar o número de telefone celular , e-mail e extrair automaticamente o código de identificação do seu dispositivo móvel e outras informações fornecidas durante o registro.",
        "2. Conteúdo do serviço",
        "funções específicas desta plataforma são fornecidas com base nas condições reais , incluindo caixa, análise estatística, pedidos de autoatendimento e shopping online, mas não estão limitadas a essas funções . Esta plataforma pode efetuar alterações nos serviços que presta, podendo o conteúdo dos serviços prestados ser alterado a qualquer momento .",
        "3. Proteção das informações de privacidade pessoal dos usuários",
        "1. Durante o processo de registro de uma conta ou utilização desta plataforma , os usuários podem precisar preencher ou enviar algumas informações necessárias, como informações de identidade que precisam ser preenchidas conforme estipulado em leis, regulamentos e documentos regulamentares (doravante denominados como \"leis e regulamentos\"). Se as informações enviadas pelo usuário estiverem incompletas ou não cumprirem as leis e regulamentos, o usuário poderá não conseguir usar esta plataforma ou poderá ser restringido no processo de utilização desta plataforma .",
        "2. Informações de privacidade pessoal referem-se a informações que envolvem a identidade pessoal ou privacidade pessoal do usuário, como nome real do usuário, número de identificação, número de telefone celular, código de identificação do dispositivo móvel e endereço IP. Informações privadas não pessoais referem-se ao status operacional e hábitos de uso do usuário desta plataforma , que são refletidos de forma clara e objetiva nas informações básicas de registro no lado do servidor desta plataforma , outras informações gerais fora do escopo das informações de privacidade pessoal, e as informações privadas acima mencionadas que os usuários concordam em divulgar.",
        "3. Respeitar a privacidade das informações pessoais dos usuários é um sistema consistente desta plataforma . Esta plataforma tomará medidas técnicas e outras medidas necessárias para garantir a segurança das informações pessoais dos usuários e evitar que as informações pessoais dos usuários sejam coletadas nesta plataforma. de ser vazado, danificado ou perdido. Quando a situação acima ocorrer ou a plataforma descobrir que existe a possibilidade de a situação acima acontecer, medidas corretivas serão tomadas em tempo hábil.",
        "4. Esta plataforma não divulgará ou divulgará informações de privacidade pessoal dos usuários a terceiros sem o consentimento do usuário. No entanto, estão excluídas as seguintes circunstâncias específicas:",
        "(1) Fornecer informações de privacidade pessoal dos usuários de acordo com as leis e regulamentos ou instruções das autoridades competentes;",
        "(2) Qualquer vazamento de informações pessoais causado por usuários informando a terceiros suas senhas de usuário ou compartilhando contas e senhas registradas com terceiros, ou outros vazamentos de informações de privacidade pessoal não causados por esta plataforma ;",
        "(3) Os usuários divulgam suas informações pessoais de privacidade a terceiros por sua própria iniciativa;",
        "(4) O usuário chegou a um acordo com a plataforma e seus parceiros sobre o uso e divulgação das informações de privacidade pessoal do usuário e, portanto, a plataforma divulga as informações de privacidade pessoal do usuário ao parceiro;",
        "(5) Qualquer vazamento de informações de privacidade pessoal dos usuários devido a ataques de hackers, invasões de vírus de computador e outros eventos de força maior.",
        "5. O usuário concorda que esta plataforma pode utilizar as informações de privacidade pessoal do usuário nos seguintes assuntos:",
        "(1) Esta plataforma envia avisos ou lembretes importantes aos usuários em tempo hábil . Por exemplo, o software usará as informações de privacidade pessoal dos usuários para qualquer outra finalidade.",
        "6. Esta plataforma atribui grande importância à proteção das informações de privacidade pessoal dos menores. As informações pessoais fornecidas pelo usuário serão utilizadas para determinar se o usuário é menor de idade.",
        "7. O usuário confirma que suas informações de localização geográfica são informações privadas não pessoais. O registro bem-sucedido de uma conta pelo usuário é considerado uma confirmação de que a plataforma está autorizada a extrair, divulgar e usar as informações de localização geográfica do usuário.",
        "8. A fim de melhorar a tecnologia e os serviços e proporcionar aos usuários uma melhor experiência de serviço, esta plataforma pode coletar e usar informações de privacidade não pessoais dos usuários por conta própria ou fornecê-las a terceiros.",
        "4. Especificações de conteúdo",
        "1. O conteúdo mencionado neste artigo refere-se a qualquer conteúdo produzido, carregado, copiado, publicado e divulgado pelos usuários durante o uso desta plataforma , incluindo, mas não se limitando a avatar da conta, nome, descrição do usuário e outras informações de registro e autenticação. , ou texto, voz, imagens, vídeos, gráficos, etc., mensagens de resposta ou resposta automática e páginas de links relacionados e outros conteúdos gerados pelo uso da conta ou desta plataforma .",
        "2. Os usuários não estão autorizados a usar suas contas ou esta plataforma para criar, fazer upload, copiar, publicar ou disseminar conteúdo proibido por leis, regulamentos e políticas:",
        "(1) Oposição aos princípios básicos estabelecidos pela Constituição;",
        "(2) Colocar em perigo a segurança nacional, divulgar segredos de Estado, subverter o poder do Estado e minar a unidade nacional;",
        "",
        "Modificação e rescisão",
        "Rever",
        "Este Contrato está sujeito a alterações. Se houver alguma alteração material neste Contrato, iremos notificá-lo por e-mail. Após a notificação das alterações, o uso continuado significa que você está ciente de tais alterações e concorda em ficar vinculado aos termos;",
        "",
        "Reservamo-nos o direito de modificar, reter ou encerrar qualquer serviço a qualquer momento sem aviso prévio;",
        "",
        "Você concorda que não temos o direito de modificar, reter ou encerrar qualquer serviço;",
        "",
        "Você concorda que não seremos responsáveis perante você ou terceiros pela modificação, retenção ou encerramento de quaisquer Serviços.",
        "",
        "rescisão",
        "Este Contrato entra em vigor a partir da data em que você o aceita e continuará em vigor durante seu uso até ser rescindido de acordo com este Contrato;",
        "",
        "Não obstante o acima exposto, se a sua utilização for anterior ao momento em que você aceita este Contrato, você reconhece e concorda que este Contrato entrará em vigor no momento em que você aceitar este Contrato. Você reconhece e concorda que este Contrato entrará em vigor quando você o aceitar pela primeira vez. usá-lo em vigor, a menos que seja rescindido antecipadamente de acordo com este Contrato;",
        "",
        "Podemos reservar o seu direito de usar esta conta de acordo com as disposições da lei; rescindiremos este Contrato a qualquer momento e por qualquer motivo, com ou sem aviso prévio, inclusive se acreditarmos de boa fé que você violou nossa Política de Uso Aceitável ou este Acordo outras disposições;",
        "",
        "Não limitado pelas disposições do parágrafo anterior, se um usuário infringir os direitos autorais de terceiros e recebermos uma notificação do proprietário dos direitos autorais ou do representante legal do proprietário dos direitos autorais, reservamo-nos o direito de rescindir este contrato;",
        "",
        "Após a rescisão deste Contrato, seu direito de usá-lo será encerrado. Você deve estar ciente de que o encerramento do seu Produto significará que o seu Conteúdo do Usuário será excluído de nossos bancos de dados ativos. Não teremos nenhuma responsabilidade perante você por qualquer rescisão deste Contrato, incluindo rescisão de sua conta de usuário e exclusão de seu Conteúdo de Usuário.",
    ]
}