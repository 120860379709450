import React, { useEffect } from "react";
import { Box, Menu, MenuItem, Button, Link } from "@mui/material";
import MainLogo from "../assets/logo/main-logo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import HeaderMini from "../layout/headerMini";

function SoftMenu(props) {
  const navigate = useNavigate();
  let { t } = useTranslation();
  const options = [
    t("mainMenu.type1"),
    t("mainMenu.type2"),
    t("mainMenu.type3"),
    t("mainMenu.type4"),
    t("mainMenu.type5"),
    t("mainMenu.type6"),
    t("mainMenu2.type7"),
    t("mainMenu2.type8"),
    t("mainMenu2.type9"),
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (index) => {
    setAnchorEl(null);
    switch (index) {
      case 0:
        navigate("/type/pos");
        break;
      case 1:
        navigate("/type/admin");
        break;
      case 2:
        navigate("/type/sale");
        break;
      case 3:
        navigate("/type/kitchen");
        break;
      case 4:
        navigate("/type/shop");
        break;
      case 5:
        navigate("/type/selfhelp");
        break;
      case 6:
        navigate("/type/diningCar");
        break;
      case 7:
        navigate("/type/scan");
        break;
      case 8:
        navigate("/type/customer");
        break;
      default:
        navigate("/type/pos");
        break;
    }
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpSharpIcon /> : <ArrowDropDownSharpIcon />}
        sx={{...props.textColor, fontSize: 16, position: 'relative', top: -1}}
      >
        {t("mainMenu.software")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={option} onClick={() => handleMenuItemClick(index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

function BusinessTypeMenu(props) {
  const navigate = useNavigate();
  let { t } = useTranslation();
  const options = [
    t("mainMenu.sceneType1"),
    t("mainMenu.sceneType2"),
    t("mainMenu.sceneType3"),
    t("mainMenu.sceneType4"),
    t("mainMenu.sceneType5"),
    t("mainMenu2.sceneType6"),
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (index) => {
    setAnchorEl(null);
    switch (index) {
      case 0:
        navigate("/scene/restaurant");
        break;
      case 1:
        navigate("/scene/bar");
        break;
      case 2:
        navigate("/scene/minimart");
        break;
      case 3:
        navigate("/scene/clothing");
        break;
      case 4:
        navigate("/scene/cafe");
        break;
      case 5:
        navigate("/scene/diningCar");
        break;
      default:
        navigate("/scene/restaurant");
        break;
    }
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpSharpIcon /> : <ArrowDropDownSharpIcon />}
        sx={{...props.textColor, fontSize: 16, position: 'relative', top: -1}}
      >
        {t("mainMenu.businessType")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={option} onClick={() => handleMenuItemClick(index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const BoxContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1312,
  height: 64,
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("lgUp")]: {
    margin: "0 8px",
  },
  [theme.breakpoints.up("lgUp")]: {
    margin: "0 auto",
  },
}));

const BeginButton = styled(Button)(() => ({
  color: "rgba(23, 144, 82, 1)",
  border: "2px solid rgba(23, 144, 82, 1)",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#e6e0e0",
  },
  height: 38,
  fontSize: 14,
  fontWeight: 400,
  maxWidth: 200,
  minWidth: 100,
  width: "inherit",
  marginRight: "0 !important",
  boxSizing: "border-box",
}));

function HeaderMenu() {
  let { t } = useTranslation();

  const skin = useSelector((state) => state.skin.value);

  let textColor = { color: skin.color };

  const [fixedClassName, setFixedClassName] = React.useState(0);
  const handleScroll = () => {
    let height = document.documentElement.scrollTop || document.body.scrollTop;
    setFixedClassName(height !== 0 ? "fixed" : "");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const BoxBarContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    bgcolor: skin.menuBg,
    "& div, & a": {
      paddingRight: "32px",
    },
    [theme.breakpoints.down("mg")]: {
      display: "none",
    },
    [theme.breakpoints.up("mg")]: {
      display: "flex",
    },
  }));

  const LogoLink = styled(Link)(({ theme }) => ({
    [theme.breakpoints.down("mg")]: {
      display: "none",
    },
    [theme.breakpoints.up("mg")]: {
      display: "flex",
      marginRight: 48,
    },
  }));

  return (
    <Box
      sx={{
        bgcolor: skin.menuBg,
      }}
      className={fixedClassName}
      id="HeaderMenu"
    >
      <BoxContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            lineHeight: "64px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <HeaderMini />
            <LogoLink href="#/" underline="none">
              <img
                src={MainLogo}
                alt="Toposfy"
                style={{ width: 148, height: 48 }}
              />
            </LogoLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <BoxBarContainer>
              <Link href="#/feature/index" sx={textColor} underline="none">
                {t("mainMenu.feature")}
              </Link>
              <SoftMenu {...{ textColor: textColor }} />
              <Link href="#/hardware/index" sx={textColor} underline="none">
                {t("mainMenu.hardware")}
              </Link>
              <BusinessTypeMenu {...{ textColor: textColor }} />
              <Link href="#/price/index" sx={textColor} underline="none">
                {t("order.text")}
              </Link>
              <Link href="#/partners/index" sx={textColor} underline="none">
                {t("partners.text")}
              </Link>
              <Link href="#/contactUs/index" sx={textColor} underline="none">
                {t("mainMenu.contactUs")}
              </Link>
              <Link href="#/help/index" sx={textColor} underline="none">
                {t("mainMenu.help")}
              </Link>
            </BoxBarContainer>
            <div></div>
            <BeginButton
              variant="contained"
              sx={{ mr: 2 }}
              href="http://m.toposfy.com/"
            >
              {t("home.beginBtn")}
            </BeginButton>
          </Box>
        </Box>
      </BoxContainer>
    </Box>
  );
}

export default HeaderMenu;
