import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Title = styled(Box)(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: "rgba(255, 255, 255, 1)",
  marginBottom: "12px",
}));

export const Info = styled(Box)(() => ({
  fontSize: 12,
  fontWeight: 400,
  color: "rgba(200, 202, 205, 1)",
  marginBottom: "12px",
}));

export const ItemLeftBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginRight: '1rem'
  },
  [theme.breakpoints.up('lg')]: {
    marginRight: '4rem'
  },
}));

export const ItemRightBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginLeft: '1rem'
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '4rem'
  },
}));
