/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Box, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Logo1 from '../../assets/product/1.png';
import Logo2 from '../../assets/product/2.png';
import Logo3 from '../../assets/product/3.png';
import Logo4 from '../../assets/product/4.png';
import Logo5 from '../../assets/product/5.png';
import Logo6 from '../../assets/product/6.png';

const Title = styled("div")(() => ({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: "36px",
  color: "rgba(49, 55, 61, 1)",
  marginBottom: 24,
  marginTop: 32,
}));

const CardBox = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-around",
  [theme.breakpoints.down("sm")]: {
    flexWrap: 'wrap',
  },
}));

const PCard = styled(Card)(({theme}) => ({
  width: 320,
  height: 124,
  marginBottom: 36,
  boxShadow: "2px 2px 8px  rgba(82, 90, 102, 0.25)",
  "&:not(:last-child)": {
    marginRight: 92,
  },
  [theme.breakpoints.down("lg")]: {
    width: 300,
    "&:not(:last-child)": {
      marginRight: 18,
    },
  },
  [theme.breakpoints.down("md")]: {
    width: 240,
    "&:not(:last-child)": {
      marginRight: 9,
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: 320,
    marginBottom: 8,
    "&:not(:last-child)": {
      marginRight: 0,
    },
  },
  display: "flex",
  alignItems: "center",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0.625rem 0.875rem -0.3125rem #b6babf",
  },
}));

const PTitle = styled("div")(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  marginBottom: "8px",
}));

const PInfo = styled("div")(() => ({
  fontSize: 12,
  fontWeight: 400,
  color: "rgba(115, 118, 122, 1)",
}));

function PCardItem({ color, title, info }) {
  return (
    <PCard>
      <Box sx={{ margin: "30px 16px", display: "flex" }}>
        <Box
          sx={{
            width: 60,
            height: 60,
            minWidth: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 1.5,
            mr: 1.5,
          }}
        >
          <img src={color} alt={title} />
        </Box>
        <Box>
          <PTitle>{title}</PTitle>
          <PInfo>{info}</PInfo>
        </Box>
      </Box>
    </PCard>
  );
}

function Product() {
  let { t } = useTranslation();
  let datas1 = [
    { title: t("home.pt1"), info: t("home.pi1"), color: Logo1 },
    { title: t("home.pt2"), info: t("home.pi2"), color: Logo2 },
    { title: t("home.pt3"), info: t("home.pi3"), color: Logo3 },
  ];
  let datas2 = [
    { title: t("home.pt4"), info: t("home.pi4"), color: Logo4 },
    { title: t("home.pt5"), info: t("home.pi5"), color: Logo5 },
    { title: t("home.pt6"), info: t("home.pi6"), color: Logo6 },
  ];
  return (
    <Box
      sx={{
        minheight: 408,
        bgcolor: "rgb(244, 244, 245)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Title>{t("home.product")}</Title>
      <CardBox>
        {datas1.map((v, index) => {
          return <PCardItem {...v} key={index} />;
        })}
      </CardBox>
      <CardBox>
        {datas2.map((v, index) => {
          return <PCardItem {...v} key={index} />;
        })}
      </CardBox>
    </Box>
  );
}

export default Product;
